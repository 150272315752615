/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './ChartEvents.module.scss'
import Button from '../../Lib/Button/Button'
import CheckBox from '../../Lib/CheckBox/CheckBox'

export default function EventDropDown (props) {
  const propsSelectedValueList = props?.selectedValue?.split(',')
  const [data, setData] = useState(
    props?.data?.map(obj => {
      return {...obj, checked: propsSelectedValueList.includes(obj.text)}
    }
    ))
  const [selectedValue, setSelectedValue] = useState(
    props.selectedValue
  )
  let liIndex = -1
  useEffect(() => {
    if (props.isResetEvent) {
      for (let dataRow of data) {
        dataRow.checked = false
      }
      setSelectedValue([])
      props.setResetEvent(false)
    }
  }, [props.isResetEvent])

  function handleDropdown (keyCode, selectedItemIndex) {
    switch (keyCode) {
      case 40: // down arrow
        handleDownArrow(selectedItemIndex)
        break
      case 38: // up arrow
        handleUpArrow(selectedItemIndex)
        break
      case 13: // enter key
      case 32: // space key
        updateDataState(selectedItemIndex)
        break
    }
  }

  function handleChangeEvent (e) {
    e?.stopPropagation()
    if (e?.type === 'keydown') {
      e.preventDefault()
      const keyCode = e.which
      if (e.key === 'Tab' || keyCode === 9) {
        if (e.shiftKey) {
          const element = e.target.closest('div[id="Popover_Parent_Container"]').previousElementSibling
          if (element.tagName === 'BUTTON') {
            element.focus()
          }
        } else {
          document.querySelector('[data-custom-event-select="base-button-wrapper"]').firstElementChild.focus()
        }
        return
      }
      const selectedItemIndex = parseInt(e.target.querySelector('input')?.id.split('_')[1])
      if (liIndex === -1) {
        liIndex = selectedItemIndex
      }
     handleDropdown(keyCode, selectedItemIndex)
    } else {
      updateDataState(parseInt(e.target.id.split('_')[1]))
    }
  }

  function updateDataState (selectedItemIndex) {
    const currentDataState = data.map(obj => {
      if (obj.id === selectedItemIndex) {
        return {...obj, checked: !obj.checked}
      }
      return obj
    })
    setData(currentDataState)
    const selectedValues = getSelectedData(currentDataState)
    setSelectedValue(selectedValues)
  }

  function handleDownArrow (index) {
    index++
    if (index > data.length - 1) {
      index = 0
    }
    let element = document.getElementById(data[index].id)
    element.focus()
    if (index === 0) {
      element.scrollTop = 0
    }
  }

  function handleUpArrow (index) {
    index--
    if (index < 0) {
      index = data.length - 1
    }
    let element = document.getElementById(data[index].id)
    element.focus()
    if (index === 0) {
      element.scrollTop = 0
    }
  }

  function getSelectedData (dataTable) {
    let selectedList = []
    for (let dataRow of dataTable) {
      if (dataRow.checked) {
        selectedList.push(dataRow.text)
      }
    }
    return selectedList
  }

  // Apply button click handler
  function handleApply (e) {
    e.preventDefault()
    if (props.changeHandler) {
      props.changeHandler(selectedValue, e)
    }
    props.selectedDropDown({'hideChartOptionMenu': new Date().toString(), 'Events': data})
  }

  // Reset button click handler
  function handleReset (e) {
    e.preventDefault()
    setSelectedValue([])
    props.setResetEvent(false)
    if (props.changeHandler) {
      props.changeHandler([], e)
    }
    const currentDataState = (data.map(obj => {
      return {...obj, checked: false}
    }))
    setData(currentDataState)
    props.selectedDropDown({'hideChartOptionMenu': new Date().toString(), 'Events': currentDataState})
  }

  // Returns html of dropdown with selection
  function BindDropDownValues () {
    if (data && data.length > 0) {
      return data.map((item, index) => (
        <li
          tabIndex={0}
          id={item.id}
          data-testid={item.id}
          key={index}
          title={item.text}
          onKeyDown={() => handleChangeEvent(event)}
        >
          <CheckBox id={`chk_${item.id}`} index={-1} label={item.text} isChecked={item.checked} onCheck={handleChangeEvent} />
        </li>
      ))
    } else {
      return null
    }
  }
  const isButtonDisabled = () => {
    return data?.reduce((result, current) => {
      return current.checked === true || result
    }, false)
  }
  return (
    <React.Fragment>
      <div className={styles.eventContainer} data-custom-event-container='custom-event-select-container'>
        <div className={styles.basicDropDownValueContainer}>
          <ul className={styles.ul}>
            {BindDropDownValues()}
          </ul>
          <div data-custom-event-select='base-button-wrapper' className={`${styles['button-container']} ${styles['buttons']}`}>
            <Button buttonDataTestId='Apply' clickHandler={handleApply} className={`${isButtonDisabled() ? styles.apply : styles.disabledApply}`} labelText='Apply' />
            <Button buttonDataTestId='Reset' clickHandler={handleReset} className={`${isButtonDisabled() ? styles.reset : styles.disabledReset}`} labelText='Reset' />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

EventDropDown.propTypes = {
  data: PropTypes.array,
  changeHandler: PropTypes.func,
  selectedValue: PropTypes.string,
  selectedDropDown: PropTypes.func,
  isResetEvent: PropTypes.bool,
  setResetEvent: PropTypes.func
}
