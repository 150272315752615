'use strict'

import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import timePeriods from '../../../data/ChartTimePeriods.json'
import DataRequest from '../../../services/DataRequest'
import { CHART_EVENTS, CHART_STYLES, DATE_FORMAT, URLS, CHART_TIME_FRAMES } from '../../../utils/appConstants'
import { diffInDays, getPeriod } from '../../../utils/formatter'
import ChartEvents from '../../Charts/ChartEvents/ChartEvents'
import ChartTimePeriods from '../../Charts/ChartTimePeriods/ChartTimePeriods'
import Indicator from '../../Charts/Indicator/Indicator'
import CustomSelectMenu from '../../Lib/CustomSelectMenu/CustomSelectMenu'
import ImageButton from '../../Lib/ImageButton/ImageButton'
import LinkButton from '../../Lib/LinkButton/LinkButton'
import ChartType from '../ChartType/ChartType'
import styles from './ChartTools.module.scss'
import { emitF2Event } from '../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../utils/f2Constants'
import moment from 'moment'

export default function BasicChartTools ({primarySymbol, numberOfDays, showToolsButton, isAdvanceChart, toolsClickHandler, resetTools, chart, resetChart, onInputsSelection, enableChartPeriodChangeEvent}) {
  const [hideChartOptionMenu, setHideChartOptionMenu] = useState(null)
  const [hideChartTimeOptionMenu, setHideChartTimeOptionMenu] = useState(null)
  const [hideChartIndicatorMenu, setHideChartIndicatorMenu] = useState(false)
  const [toggleResetTools, setResetTools] = useState(false)
  const [isResetEvent, setIsResetEvent] = useState(false)
  const [isResetIndicator, setIsResetIndicator] = useState(false)
  const [resetDefaultValue, setResetDefaultValue] = useState(null)
  const [timePreFixLabel, setTimePreFixLabel] = useState(CHART_TIME_FRAMES[numberOfDays])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [days, setDays] = useState(numberOfDays)

  useEffect(() => {
    setIsResetEvent(true)
  }, [primarySymbol])

  const handleChartTimeSelection = (data) => {
    let days
    let beginDate = data?.startDate
    let endDate = data?.endDate

    if (data.isCustom) {
      beginDate = beginDate && (moment(beginDate).startOf('day')).toDate()
      endDate = endDate && (moment(endDate).startOf('day')).toDate()
      if (!beginDate || !endDate) {
        return false
      }
      setStartDate(beginDate)
      setEndDate(data.endDate)
      const intradayLimit = new Date()
      intradayLimit.setDate(intradayLimit.getDate() - 30)
      const numDays = diffInDays(beginDate, endDate, DATE_FORMAT.MONTH_DAY_YEAR_DATE)
      const { interval, period } = determineIntervalAndPeriod(beginDate, intradayLimit, numDays);
      chart.setDataInterval(interval)
      chart.setDataPeriod(period)
      chart.setDateRange(beginDate, endDate)
      setDays(days)
      setTimePreFixLabel("Custom")
    } else {
      if (data.currentDate) {
        setHideChartTimeOptionMenu(data.currentDate)
      }
      let isSet = false
      let timePreFix = '1 Years'
      timePeriods.forEach(item => {
        !isSet && item.value.forEach(childItem => {
          if (childItem.value.days === data.days) {
            isSet = true
            timePreFix = item.text
          }
        })
      })
      setTimePreFixLabel(timePreFix)

      days = data.days
      chart.setDays(data.days)
      setDays(data.days)
      chart.setDataPeriod(data.dataPeriod)
      chart.setDataInterval(data.dataInterval)
    }
    if (enableChartPeriodChangeEvent) {
      let fromDate
      let toDate
      if (data.isCustom) {
        fromDate = beginDate
        toDate = endDate
      } else {
        let date = new Date(data.currentDate)
        date.setDate(date.getDate() - data.days)
        toDate = new Date(data.currentDate)
        fromDate = date
      }
      emitF2Event(EVENT_NAMES.CHART_PERIOD_CHANGED_EVENT, {
        venueXid: primarySymbol,
        startDate: fromDate,
        endDate: toDate
      })
    }
    onInputsSelection({
      days,
      events: chart.panels[0].events
    })

    chart.loadData()
  }

  const determineIntervalAndPeriod = (beginDate, intradayLimit, numDays) => {
    let interval = 1;
    let period = 'Minute';

    if (beginDate > intradayLimit) {
      if (numDays < 5) {
        // No changes needed for 5 or fewer days
      } else if (numDays < 10) {
        interval = 5;
      } else if (numDays < 15) {
        interval = 15;
      } else {
        period = 'Hour';
      }
    } else {
      if (numDays < 365) {
        period = 'Day';
      } else if (numDays < 365 * 10) {
        period = 'Week';
      } else {
        period = 'Month';
      }
    }
    return { interval, period };
  };

  const resetSettings = () => {
    setIsResetIndicator(true)
    setIsResetEvent(true)
    setResetTools(!toggleResetTools)
    setTimePreFixLabel(CHART_TIME_FRAMES[numberOfDays])
    setResetDefaultValue(CHART_STYLES[0].Text)
    showToolsButton && resetTools()
    resetChart()
  }

  const setResetIndicator = (data) => {
    setIsResetIndicator(data)
  }

  const setResetEvent = (data) => {
    setIsResetEvent(data)
  }

  const setRevertDefaultValue = (data) => {
    setResetDefaultValue(data)
  }

  const handleChartEventSelection = (data) => {
    if (data.hideChartOptionMenu) {
      setHideChartOptionMenu(data.hideChartOptionMenu)
    }
    let addingEvent = false
    data.Events.forEach(event => {
      let id = event.value.toLowerCase()
      const existingEvent = chart.panels[0].events.find(e => e.params.id === id)

      if (event.checked && !existingEvent) {
        if (id === 'customearnings') {
          primarySymbol > 0 && addCustomEarningsEvents()
        } else {
          chart.panels[0].addEvent(id)
        }
        addingEvent = true
      } else if (!event.checked && existingEvent) {
        chart.panels[0].removeEvent(existingEvent)
      }
    })
    const isRemoveCustomEvent = !data.Events.find(a => a.value==="CustomEarnings").checked
    if (addingEvent || isRemoveCustomEvent) {
      isRemoveCustomEvent && chart.panels[0].removeEvent('custom')
      chart.loadData()

      onInputsSelection({
        days,
        events: data.Events
      })
    }
  }

  const addCustomEarningsEvents = () => {
    function setEarningData (response) {
      if (!response.isError && response.isDataRequestComplete) {
        chart.panels[0].addEvent('custom', {
          name: 'Earning Event',
          dataset: mapCustomEarningEvents(response.data.historical)
        })
      }
    }
    DataRequest.execute(
      `${URLS.PLATFORM.EQUITIES_MORNINGSTAR_ESTIMATES}/trends/${primarySymbol}`,
      {
        params: {
          periodicity: 'quarterly',
          numberOfEstimatePeriods: 1,
          includeHistory: true,
          numberOfHistoricalPeriods: getPeriod(days, true)
        }
      },
      setEarningData,
      null
    )
  }

  useEffect(() => {
    if (chart) {
      const existingEvent = chart.panels[0].events.find(e => e.params.id === 'custom')
      if (existingEvent) {
        primarySymbol > 0 && addCustomEarningsEvents()
      }
    }
  }, [days])

  const mapCustomEarningEvents = (data) => {
    return data.map(event => {
      return {
        ...event,
        date: event.periodEndDate
      }
    })
  }

  const handleChartTypeSelection = (e) => {
    const value = e.getAttribute('data-value')

    const item = CHART_STYLES.find(style => style.Text === value)

    chart.setPriceMarkerType((item?.value) || 'line')

    const priceIndicator = chart.panels[0].indicators[0]

    if (item.value === 'fill') {
      if (priceIndicator) {
        priceIndicator.setStyle('fillColor', 'rgba(0,68,128, 0.25)')

        priceIndicator.setStyle('fillColorStop', 'rgba(0,68,128, 0.25)')
      }
    } else if (item.value === 'candlestick' && priceIndicator['params']) {
      priceIndicator['params'].candleFillType = 'filled'
    }

    chart.render()
  }
  return (
    <React.Fragment>
      <div id='basicChartTools' className={styles.chartTools}>
        <div className={styles.basicToolItems}>
          <CustomSelectMenu preFixLabel={'Indicators'} popOverStyle={styles.chartIndicator} isVisible={hideChartIndicatorMenu}
            popOverBody={<Indicator showDropDownTypeMenu isAdvance={isAdvanceChart} isResetIndicator={isResetIndicator} setResetIndicator={setResetIndicator} toggleArrow={setHideChartIndicatorMenu} chart={chart} />} />

          <ChartEvents label={'Events'} onSelection={handleChartEventSelection} eventData={CHART_EVENTS} isResetEvent={isResetEvent} setResetEvent={setResetEvent} isVisible={hideChartOptionMenu} popOverStyle={styles.chartEvents} />

        </div>
        <div className={styles.basicToolItems}>
          <CustomSelectMenu preFixLabel={timePreFixLabel} isVisible={hideChartTimeOptionMenu}
            popOverBody={<ChartTimePeriods startDate={startDate} endDate={endDate} onSelection={handleChartTimeSelection} />} />

          <div className={`${styles.chartTypeContainer} ${styles.basicToolItems}`}>
            <ChartType onSelect={handleChartTypeSelection} resetDefaultValue={resetDefaultValue} setRevertDefaultValue={setRevertDefaultValue} />
          </div>
        </div>
        {
          showToolsButton &&
          <div className={styles.toolsContainer}>
            <ImageButton postFixLabel={'Tools'} icon={'pencil'} iconType={'fal'}
              clickHandler={toolsClickHandler} resetTools={toggleResetTools} />
          </div>
        }
        <div className={styles.linkContainer}>
          <LinkButton dataTestId={'Reset'} label={'Reset'} onClick={resetSettings} />
        </div>
      </div>
    </React.Fragment>
  )
}

BasicChartTools.propTypes = {
  primarySymbol: PropTypes.number,
  numberOfDays: PropTypes.number,
  showToolsButton: PropTypes.bool,
  isAdvanceChart: PropTypes.bool,
  toolsClickHandler: PropTypes.func,
  resetTools: PropTypes.func,
  chart: PropTypes.object,
  resetChart: PropTypes.func,
  onInputsSelection: PropTypes.func,
  enableChartPeriodChangeEvent: PropTypes.bool
}
