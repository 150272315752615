'use strict'

import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import CustomSelectMenu from '../../../Lib/CustomSelectMenu/CustomSelectMenu'
import Button from '../../../Lib/Button/Button'
import ModalPopUp from '../../../Lib/ModalPopUp/ModalPopUp'
import ExitAlert from '../../../Lib/ExitAlert/ExitAlert'
import {closeModalPopup, hideScroll} from '../../../../utils/utilities'
import styles from './SaveChart.module.scss'
import CompanyDetailsHeader from '../../../Stocks/CompanyDescription/CompanyDetailsHeader/CompanyDetailsHeader'
import SaveChartPopupBody from './SaveChartPopupBody'
import DataRequest from '../../../../services/DataRequest'
import { URLS } from '../../../../utils/appConstants'
import SavedChartsDropdown from './SavedChartsDropdown/SavedChartsDropdown'

export default function SaveChart ({currentChart, resetChart, resetIndicators, resetSaveChart}) {
  const [modalOpen, setModalState] = useState(false)
  const [closeAlertScreen, setCloseAlertScreen] = useState(false)
  const [chartNameEdit, setChartName] = useState('')
  const [hideSaveChartDropMenu, setHideSaveChartDropMenu] = useState(null)
  const [selectChartLabel, setSelecetdChartLabel] = useState('')
  const [actionedChartName, setActionedChartName] = useState('')
  const [chartAction, setChartAction] = useState(null)
  const [savedChartList, setSavedChartList] = useState([])
  const childRef = useRef()

  useEffect(() => {
    resetSettings()
  }, [resetSaveChart])

  const resetSettings = () => {
    setSelecetdChartLabel('My Saved Charts')
    setChartName('')
    setActionedChartName('')
    setChartAction(null)
  }

  useEffect(() => {
    getSavedChart()
  }, [])

  const getSavedChart = async () => {
    let callback = (response) => {
      if (response.data && response.data.value) {
        try {
          const parseData = JSON.parse(response.data.value)
          setSavedChartList(parseData)
          const defaultChart = parseData.find(chart => chart.isDefault === true)
          if (defaultChart) {
            loadSavedChart(defaultChart.id)
            setSelecetdChartLabel(defaultChart.chartName)
          }
        } catch (error) {
          console.log('error occured while parsing')
        }
      }
    }

    DataRequest.execute(
      URLS.PLATFORM.USER_MGMT_PREFERENCES + '/CHART_SETTINGS_SAVE', null, callback
    )
  }

  const loadSavedChart = async (id) => {
    resetIndicators([])
    currentChart.loadSaved(id, () => {})
  }

  const saveChartClickHandler = () => {
    if (!modalOpen) {
      setModalState(true)
      hideScroll(true)
      resetSettings()
    }
  }

  const closePopup = (e) => {
    if (closeModalPopup(e)) {
      setModalState(false)
      hideScroll(false)
      setChartName('')
    }
  }

  const cancelChart = (e) => {
    setModalState(false)
    hideScroll(false)
    setChartName('')
  }

  const validateNameAlreadyExist = (newChartName) => {
    return savedChartList.find(x => x.chartName === newChartName)
  }

  const saveNewChart = async (chartName) => {
    let chartObj = {}
    chartObj.chartName = chartName
    chartObj.isDefault = false
    let res = await currentChart.save(false, chartName)

    if (res.id) {
      chartObj.id = res.id
      savedChartList.push(chartObj)
      saveChartServer(savedChartList)
    }
  }

  const renameChart = (chartName, actionedChartName) => {
    const updatedChartStting = savedChartList.map(chartSetting => {
      if (chartSetting.chartName === actionedChartName) {
        chartSetting.chartName = chartName
      }
      return chartSetting
    })
    saveChartServer(updatedChartStting)
    if (selectChartLabel === actionedChartName) {
      setSelecetdChartLabel(chartName)
    }
  }

  const duplicateChart = (chartName, actionedChartName) => {
    const existingChartSetting = savedChartList.find(chartSetting => chartSetting.chartName === actionedChartName)
    if (existingChartSetting) {
      savedChartList.push({chartName: chartName, id: existingChartSetting.id, isDefault: existingChartSetting.isDefault})
      saveChartServer(savedChartList)
    }
  }

  const saveChart = async (chartName) => {
    if (validateNameAlreadyExist(chartName)) {
      childRef.current.setNameErrorMsg()
      return
    }

    setModalState(false)
    hideScroll(false)
    if (chartAction == null) {
      await saveNewChart(chartName)
    } else if (chartAction === 'Rename') {
      renameChart(chartName, actionedChartName)
    } else if (chartAction === 'Duplicate') {
      duplicateChart(chartName, actionedChartName)
    }

    setSavedChartList([...savedChartList])
    setChartAction(null)
  }

  const saveChartServer = (chartSet) => {
    let callback = (response) => {
      if (response.isDataRequestComplete && !response.isError) {
      }
    }

    DataRequest.execute(
      URLS.PLATFORM.USER_MGMT_PREFERENCES + '/CHART_SETTINGS_SAVE',
      {
        method: 'POST',
        postData: JSON.stringify(chartSet)
      },
      callback
    )
  }

  const setDefault = async (chartName) => {
    let defautChartSetting = savedChartList.find(x => x.chartName === chartName)
    if (defautChartSetting) {
      resetIndicators([])
      await currentChart.loadSaved(defautChartSetting.id, () => {})
    }
    const updatedChartSetting = savedChartList.map(item => {
      if (item.chartName === chartName) {
        item.isDefault = true
      } else {
        item.isDefault = false
      }
      return item
    })
    saveChartServer(updatedChartSetting)
    setSelecetdChartLabel(chartName)
  }
  function onConfirmation (e) {
    setCloseAlertScreen(false)
    if (e === 'Yes') {
      deleteChart(chartNameEdit)
    }
  }
  const deleteChart = (chartName) => {
    let chartArr = []
    savedChartList.forEach(item => {
      if (item.chartName !== chartName) {
        chartArr.push(item)
      }
    })
    saveChartServer(chartArr)
    setSavedChartList([...chartArr])
    resetChart()
  }

  const saveChartAction = (chartName, actionType) => {
    setChartAction(actionType)
    switch (actionType) {
      case 'Delete': {
        setChartName(chartName)
        setCloseAlertScreen(true)
        break
      }
      case 'Duplicate': {
        setChartName(chartName + '_')
        setActionedChartName(chartName)
        setModalState(true)
        break
      }
      case 'Rename': {
        setChartName(chartName)
        setActionedChartName(chartName)
        setModalState(true)
        break
      }
      case 'Default': {
        setDefault(chartName)
        break
      }
      case 'ChartItemClick': {
        setSelecetdChartLabel(chartName)
        let filterChart = savedChartList.filter(x => x.chartName === chartName)
        if (filterChart.length === 1) {
          loadSavedChart(filterChart[0].id)
        }
        break
      }
      default: {
        break
      }
    }
    setHideSaveChartDropMenu(new Date().toString())
  }

  return (
    <div className={styles.saveChartContainer}>
      <CustomSelectMenu
        preFixLabel={selectChartLabel}
        popOverBody={<SavedChartsDropdown
          data={savedChartList}
          clickHandler={saveChartAction}
        />}
        isVisible={hideSaveChartDropMenu} />
      <Button buttonDataTestId='saveButton' clickHandler={saveChartClickHandler} className={styles.saveChartButton} labelText='Save Chart' />
      {modalOpen &&
        <ModalPopUp stylesContent={{'height': 'auto'}} stylesPopUp={{'minHeight': 'auto', width: '280px'}} onClose={closePopup} customHeader addEventListener >
          <CompanyDetailsHeader companyName={'Save Chart Settings'} onClose={closePopup} />
          <SaveChartPopupBody saveChartHandler={saveChart} chartName={chartNameEdit} cancelChartHandler={cancelChart} ref={childRef} />
        </ModalPopUp>}
      {closeAlertScreen && <div className={styles.saveChartPopupScreenAlert}>
        <ModalPopUp hideClose>
          <ExitAlert confirmation={onConfirmation} yesLabelName={'Confirm'} noLabelName={'Cancel'} message={'Are you sure you want to delete?'} />
        </ModalPopUp>
      </div>}
    </div>
  )
}
SaveChart.propTypes = {
  currentChart: PropTypes.object,
  resetChart: PropTypes.func,
  resetIndicators: PropTypes.func,
  resetSaveChart: PropTypes.string

}
