import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import DataRequest from '../../../services/DataRequest'
import moment from 'moment'
import { URLS } from '../../../utils/appConstants'
import { formatNumber } from '../../../utils/formatter'
import EarningEventInfo from './EarningEventInfo/EarningEventInfo'
import DividendEventInfo from './DividendEventInfo/DividendEventInfo'
import SplitEventInfo from './SplitEventInfo/SplitEventInfo'
import styles from '../Charts.module.scss'

function ChartEventInfoContainer ({primarySymbol, chart, reset, chartParams, insidePopOver}) {
  const EMPTY = '--'
  const dividendInitailState = () => {
    return { date: EMPTY, currentDividend: EMPTY, exDate: EMPTY, payDate: EMPTY, announcementDate: EMPTY, annualYield: EMPTY }
  }

  const [days] = useState(chart?.params?.days)
  const [chartHover, setChartHover] = useState(false)
  const [chartPopoverPos, setChartHoverPos] = useState({ top: '', left: '', width: '' })
  const [chartEvent, setChartEvent] = useState({ isEarning: false, isDividend: false, isSplit: false })
  const [chartSplitEvent, setChartSplitEvent] = useState({ ratio: '', date: '', announcementDate: '' })
  const [chartEarningsEvent, setChartEarningsEvent] = useState({ date: '', actualEPS: '', estimatedEPS: '', surprise: '' })
  const [chartDividendEvent, setChartDividendEvent] = useState(dividendInitailState())

  const getChartDividendData = () => {
    let endDate = moment(new Date()).format('YYYY-MM-DD')
    let startDate = moment(new Date()).subtract(chartParams.days || days, 'days').format('YYYY-MM-DD')
    DataRequest.execute(
      `${URLS.PLATFORM.CORPORATEACTIONSDIVIDENDS}/${primarySymbol}/historical-events`,
      {
        params: {
          exDateStart: startDate,
          exDateEnd: endDate,
          convertToUSD: true,
          sortDirection: 'desc',
          numberOfResults: 1000
        }
      },
      setCorporateData,
      null
    )
    function setCorporateData (response) {
      if (!response.isError && response.isDataRequestComplete) {
        window.corporateEvents = response.data.dividendEvents
      }
    }
  }

  const getChartSplitsData = () => {
    let endDate = moment(new Date()).format('YYYY-MM-DD')
    let startDate = moment(new Date()).subtract(chartParams.days || days, 'days').format('YYYY-MM-DD')
    DataRequest.execute(
      `${URLS.PLATFORM.CORPORATEACTIONSSPLITS}/${primarySymbol}/historical-events`,
      {
        params: {
          startDate,
          endDate,
          include1for1Splits: true
        }
      },
      setSplitEvents,
      null
    )
    function setSplitEvents (response) {
      if (!response.isError && response.isDataRequestComplete) {
        window.splitEvents = response.data.splitEvents
      }
    }
  }

  const calculateLeftAdjust = (diffRightPosition) => {
    if (diffRightPosition < 300) {
      return diffRightPosition
    }
    if (diffRightPosition < 180) {
      return diffRightPosition + 100
    }
    if (diffRightPosition < 100) {
      return diffRightPosition + 200
    } else if (diffRightPosition < 300 && diffRightPosition > 350) {
      return 150
    }
    return 0
  }

  const handleDividends = (c) => {
    const chartDividend = window.corporateEvents.find(x => x.exDate === moment(new Date(c.event.date)).format('YYYY-MM-DD'));
    if (chartDividend) {
      setChartDividendEvent({
        date: new Date(chartDividend.announcementDate).toLocaleDateString(),
        currentDividend: chartDividend.amount,
        exDate: new Date(chartDividend.exDate).toLocaleDateString(),
        payDate: new Date(chartDividend.paymentDate).toLocaleDateString(),
        announcementDate: new Date(chartDividend.announcementDate).toLocaleDateString(),
        annualYield: '--'
      });
    } else {
      setChartDividendEvent(dividendInitailState());
    }
    setChartEvent({ isEarning: false, isDividend: true, isSplit: false });
  };

  const handleCustomEarnings = (data) => {
    setChartEarningsEvent({
      date: data.announceDate,
      actualEPS: formatNumber(data.actual),
      quarter: data.fiscalQuarter,
      year: data.fiscalYear,
      estimatedEPS: data.adjustedEpsEstimate ? formatNumber(data.adjustedEpsEstimate.mean) : '--',
      surprise: data.earningsSurprise ? formatNumber(data.earningsSurprise.amount) : '--'
    });
    setChartEvent({ isEarning: true, isDividend: false, isSplit: false });
  };

  const mouseEnterChart = (eventType, c) => {
    let diffRightPosition = chart.size.width - c.x
    let leftAdjust = calculateLeftAdjust(diffRightPosition);
    let topPosition = insidePopOver && '100px'
    if (eventType === 'dividends') {
      handleDividends(c);
      setChartHoverPos({ left: c.x - leftAdjust, top: topPosition || '470px', width: '282px' });
    } else if (eventType === 'custom') {
      handleCustomEarnings(c.event);
      setChartHoverPos({ left: c.x - leftAdjust, top: topPosition || '530px', width: '282px' });
    } else if (eventType === 'splits') {
      setChartSplitEvent({ ratio: c.event.splits, date: c.event.date.toLocaleDateString() })
      setChartEvent({ isEarning: false, isDividend: false, isSplit: true })
      setChartHoverPos({ left: c.x - leftAdjust, top: topPosition || '470px', width: '225px' })
    }
    setChartHover(true)
  }

  const mouseLeaveChart = (c) => {
    setChartHover(false)
  }

  useEffect(() => {
    if (primarySymbol > 0) {
      getChartDividendData()
      getChartSplitsData()
    }
  }, [days, reset, chartParams])

  const createEventElement = (eventType, c, i) => {
    const id = `icon-${i}-${eventType}`;
    let el = document.getElementById(id);

    if (!el) {
      el = document.createElement('div');
      el.className = 'event-icon';
      el.id = id;
      el.setAttribute('data-event-icon', eventType);

      if (document.contains(document.getElementById(el.id))) {
        document.getElementById(el.id).remove();
      }

      if (eventType === 'splits') {
        el.innerText = 'S'
        el.style.backgroundColor = '#00B3A1'
      }
      if (eventType === 'custom') {
        el.innerText = 'E'
        el.style.backgroundColor = '#008338'
      }
      if (eventType === 'dividends') {
        el.innerText = 'D'
        el.style.backgroundColor = '#7D324C'
      }
      el.className = styles.chartEvent
      el.setAttribute('tabindex', 0)
      el.onmouseover = () => {
        mouseEnterChart(eventType, c)
      }
      el.onmouseout = () => {
        mouseLeaveChart(c)
      }
      el.ontouchstart = () => {
        mouseEnterChart(eventType, c)
      }
      el.ontouchend = () => {
        mouseLeaveChart(c)
      }
      chart.rootMouse.node().appendChild(el)
    }

    return el;
  };

  useEffect(() => {
    chart.eventEmitter.on('EVENT_RENDER', event => {
      let eventType = event.id
      event.coords.forEach((c, i) => {
        let el = createEventElement(eventType, c, i);
        const canvas = document.getElementsByClassName('modcharts-panel-root')
        if (canvas?.length) {
          el.style.top = `${(canvas[0].style.height.split('p')[0] - 55)}px`
        } else {
          el.style.top = `${442}px`
        }
        el.style.left = `${c.x - 9}px`
        el.style.visibility = 'visible'
      })
    })
  }, [reset])

  return (<div data-testid='ChartEventInfoContainer' className={styles.chartEventInfoContainer}>{chartHover && <div className={styles.chartPopover} style={{ top: chartPopoverPos.top, left: chartPopoverPos.left, width: chartPopoverPos.width }}>
    {chartEvent.isEarning && <EarningEventInfo earningEventInfoData={chartEarningsEvent} />}
    {chartEvent.isDividend && <DividendEventInfo dividendEventInfoData={chartDividendEvent} />}
    {chartEvent.isSplit && <SplitEventInfo splitEventInfoData={chartSplitEvent} />}
  </div>}
  </div>
  )
}

ChartEventInfoContainer.propTypes = {
  primarySymbol: PropTypes.number,
  chart: PropTypes.object,
  chartParams: PropTypes.object,
  reset: PropTypes.bool,
  insidePopOver: PropTypes.bool
}

export default ChartEventInfoContainer
