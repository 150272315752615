'use strict'

import React, { useState } from 'react'
import styles from './TimeMeasurement.module.scss'
import ModalPopUp from '../../../Lib/ModalPopUp/ModalPopUp'
import timePeriods from '../../../../data/ChartTimePeriods.json'
import ChartsMobileHeader from '../../../Lib/ChartsMobileHeader/ChartsMobileHeader'
import ChartsTimeMobileFooter from '../ChartsTimeMobileFooter/ChartsTimeMobileFooter'
import RadioButton from '../../../Lib/RadioButton/RadioButton'
import PropTypes from 'prop-types'

export default function TimeMeasurement ({closeTimeMeasurement, activeTimeRange, activeTimeMeasurement}) {
  const [selectedTimeMeasurement, setSelectedTimeMeasurement] = useState(activeTimeMeasurement)
  const timeMeasurementData = timePeriods.filter(x => x.text === activeTimeRange)[0]

  const timeMeasurementChangeHandler = (e) => {
    setSelectedTimeMeasurement(e.target.name)
  }

  return (<ModalPopUp customHeader customBody>
    <ChartsMobileHeader headerText='Time Measurement' closeHandler={() => { closeTimeMeasurement('') }} headerDataTestId='timeMeasurementCloseTestId' />
    <div>
      {timeMeasurementData.value.map((measurement, index) => {
        return <div key={'timeMeasurement_' + index} className={styles.timeMeasurementInput}>
          <RadioButton key={index} tabIndex={0} label={measurement.text} isChecked={selectedTimeMeasurement === measurement.text} onChange={timeMeasurementChangeHandler} />
        </div>
      })}
      <ChartsTimeMobileFooter applyHandler={() => { closeTimeMeasurement(selectedTimeMeasurement) }} resetHandler={() => { setSelectedTimeMeasurement(timeMeasurementData.value[0].text) }} />
    </div>
  </ModalPopUp>)
}
TimeMeasurement.propTypes = {
  activeTimeRange: PropTypes.string,
  activeTimeMeasurement: PropTypes.string,
  closeTimeMeasurement: PropTypes.func
}
