import React, { useEffect, useState, useCallback } from 'react'
import { ModchartsInteractive, locales } from '@markit/modcharts/dist/modcharts.umd.js'
import '../../Lib/ModChartsCss/ModCharts.scss'
import { STOCKS_PERFORMANCE } from '../../../utils/appConstants'
import DataRequest from '../../../services/DataRequest'
import styles from './PerformanceChartSnapshot.module.scss'
import PropTypes from 'prop-types'
import Loader from '../../Lib/common/Loader'
import DataUnavailable from '../../Lib/DataUnavailable/DataUnavailable'

const PerformanceChart = (props) => {
  const [chartLoading, setChartLoading] = useState(true)
  const [performanceChart, setPerformanceChart] = useState(null)
  const [timePeriod, setTimePeriod] = useState(props.chartInputs['timePeriod'])
  const [chartType, setChartType] = useState(props.chartInputs.chartType)
  const [isChartEmpty, setIsChartEmpty] = useState(false)
  const locale = locales.en_US
  const wsodIssueId = `${props?.chartInputs?.wsodIssue}`
  locale.periods = ['AM', 'PM']
  locale.days = []

  const processPerformanceChart = () => {
    if (performanceChart && timePeriod !== props.chartInputs.timePeriod) {
      performanceChart.setDays(props.chartInputs['timePeriod'])
      performanceChart.setDataPeriod(props.chartInputs.dataPeriod)
      performanceChart.setDataInterval(props.chartInputs.dataInterval)
      performanceChart.loadData()
    }

    if (performanceChart?.data && chartType !== props.chartInputs.chartType) {
      let indicatorList = performanceChart?.getIndicatorsById('price')
      if (indicatorList?.length > 1) {
        performanceChart.panels[0].removeIndicator(indicatorList[1])
      }
      let newChartType
      if (props.chartInputs.chartType === STOCKS_PERFORMANCE.chartTypes.LINECHART) {
        newChartType = 'line'
      } else if (props.chartInputs.chartType === STOCKS_PERFORMANCE.chartTypes.Fill) {
        newChartType = 'fill'
      } else if (props.chartInputs.chartType === STOCKS_PERFORMANCE.chartTypes.CANDLESTICKCHART) {
        newChartType = 'candlestick'
      }
      performanceChart.setPriceMarkerType(newChartType)
      if (props.chartInputs.chartType === STOCKS_PERFORMANCE.chartTypes.Fill) {
        performanceChart.panels[0].addIndicator('price', {
          id: 'price',
          markerType: 'lineWithShapes',
          style: {
            color: STOCKS_PERFORMANCE.INDEXES.darkColor,
            shape: { type: 'circle', color: STOCKS_PERFORMANCE.INDEXES.darkColor, fillColor: STOCKS_PERFORMANCE.INDEXES.darkColor, length: 4 },
            width: 1
          },
          symbol: wsodIssueId
        })
      }
      performanceChart.loadData()
    }
  }

  processPerformanceChart();

  useEffect(() => {
    setIsChartEmpty(false)
    setTimePeriod(props.chartInputs.timePeriod)
    setChartType(props.chartInputs.chartType)
    performanceChart?.loadData()
  }, [props.chartInputs.timePeriod, props.chartInputs.chartType])

  useEffect(() => {
    if (!performanceChart && document.getElementById('performanceChartDomElement')) {
      initNewChart()
    }
  }, [])

  const handleChartLoad = (chartObject) => {
    if (chartObject) {
      chartObject.setZoomEnabled(false)
      setPerformanceChart(chartObject)
    }
    setChartLoading(false)
  }

  const handleLoadStop = (chartObject) => {
    if (chartObject && chartObject.status === 0 && (chartObject.data && Object.keys(chartObject.data) && Object.keys(chartObject.data).length === 0)) {
      setIsChartEmpty(true)
      setChartLoading(false)
    }
  }
  const bindChartEvents = (chartObject) => {
    chartObject.eventEmitter.on('DATA_LOAD_STOP', () => {
      handleLoadStop(chartObject)
    })
    chartObject.eventEmitter.on('DATA_LOAD_COMPLETE', () => {
      handleChartLoad(chartObject)
    })
    chartObject.eventEmitter.on('DATA_LOAD_ERROR', () => {
      chartObject.showMessageChartNotAvailable(``)
      setIsChartEmpty(true)
    })
  }

  const initNewChart = useCallback(() => {
    const chart = new ModchartsInteractive('#performanceChartDomElement')
    const authToken = DataRequest.getAccessToken()
    chart.setAuthToken(authToken)
    const chartConfig = {
      params: {
        locale,
        apiPath: window.MD.MOD_CHART_URL,
        symbol: wsodIssueId,
        days: timePeriod,
        dataInterval: props.chartInputs.dataInterval,
        dataPeriod: props.chartInputs.dataPeriod,
        showMarketCloseLabels: props.chartInputs.timePeriod === STOCKS_PERFORMANCE.dropDownDaysValues['1 Day'] ? 'all' : '',
        style: {
          panel: {
            border: {
              color: {
                top: STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.BORDERBOTTOMCOLOR,
                bottom: STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.BORDERBOTTOMCOLOR,
                default: 'transparent'
              }
            },
            grid: {
              color: 'transparent',
              horizontal: {
                color: STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.GRIDHORIZONTALCOLOR,
                lineDash: ''
              },
              vertical: {
                alt: { color: 'transparent' }
              }
            },
            xAxis: {
              label: {
                padding: {
                  top: 12,
                  left: 0
                },
                textAlign: 'center',
                lineHeight: STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
              },
              font: {
                color: STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTCOLOR,
                family: STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTFAMILY,
                size: STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
              }
            },
            yAxis: {
              label: {
                padding: {
                  left: 12
                }
              },
              font: {
                color: STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTCOLOR,
                family: STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTFAMILY,
                size: STOCKS_PERFORMANCE.PERFORMANCECHART.STYLE.LABELFONTSIZE
              }
            },
            margin: {
              bottom: 30
            }
          }
        }
      },
      panels: perfChartPanel
    }
    chart.load(chartConfig, handleChartLoad)
    bindChartEvents(chart)
  })
  const topIndicatorsList = {
    'fill': {
      id: 'price',
      markerType: 'fill',
      style: {
        fillColor: STOCKS_PERFORMANCE.INDEXES.lightColor,
        fillColorStop: STOCKS_PERFORMANCE.INDEXES.lightColor,
        shape: { type: 'circle', color: STOCKS_PERFORMANCE.INDEXES.lightColor, fillColor: STOCKS_PERFORMANCE.INDEXES.lightColor },
        width: 1
      },
      symbol: wsodIssueId
    },
    'lineWithShapes': {
      id: 'price',
      markerType: 'lineWithShapes',
      style: {
        color: STOCKS_PERFORMANCE.INDEXES.darkColor,
        shape: { type: 'circle', color: STOCKS_PERFORMANCE.INDEXES.darkColor, fillColor: STOCKS_PERFORMANCE.INDEXES.darkColor, length: 4 },
        width: 1
      },
      symbol: wsodIssueId
    },
    'line': {
      id: 'price',
      markerType: 'line',
      style: {
        color: STOCKS_PERFORMANCE.INDEXES.darkColor,
        shape: { type: 'circle', color: STOCKS_PERFORMANCE.INDEXES.darkColor, fillColor: STOCKS_PERFORMANCE.INDEXES.darkColor, length: 4 },
        width: 1
      },
      symbol: wsodIssueId
    },
    'candlestick': {
      id: 'price',
      markerType: 'candlestick',
      style: {
        color: STOCKS_PERFORMANCE.INDEXES.darkColor,
        shape: { type: 'circle', color: STOCKS_PERFORMANCE.INDEXES.darkColor, fillColor: STOCKS_PERFORMANCE.INDEXES.darkColor, length: 4 },
        width: 1
      },
      symbol: wsodIssueId
    }
  }
  const chartVsIndicators = {
    'chart-line': [topIndicatorsList['line']],
    'chart-candlestick': [topIndicatorsList['candlestick']],
    'chart-area': [topIndicatorsList['fill'], topIndicatorsList['lineWithShapes']]
  }
  const getPanel = () => {
    return [{
      indicators: chartVsIndicators[chartType]
    }, {
      indicators: [{
        id: 'volume',
        style: {
          color: STOCKS_PERFORMANCE.INDEXES.darkColor,
          yAxis: {
            padding: {
              bottom: 25,
              top: 20
            }
          }
        }
      }]
    }]
  }

  const perfChartPanel = getPanel()
  return (
    <div className={styles.performanceChartContainer} >
      {chartLoading && <div className={`${styles.modalPopUpContentContainer} ${styles.loaderContainer}`}>
        <Loader ready={!chartLoading}
          spinnerSize={'2x'}
          msg={'Loading'}
        />
      </div>
      }
      {!chartLoading && isChartEmpty ? <DataUnavailable /> : <div className={styles.topDivider} />}
      <div id={'performanceChartDomElement'} />
    </div>
  )
}

PerformanceChart.propTypes = {
  chartInputs: PropTypes.object
}

export default PerformanceChart
