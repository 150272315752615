import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './AddComparisonCard.module.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { keyEvents } from '../../Helpers/accessibilityUtilities'

function ComparisonCard (props) {
  const isBlankcardToShow = (props.cardCount !== 0 && props.cardCount > 0 && props.cardCount < 10)
  var handleOnClick = () => {
    props.addCompare()
  }
  useEffect(() => {
    if (props.cardCount === 0 && document.querySelectorAll('[name=zeroAddComparisionCard]') && document.querySelectorAll('[name=zeroAddComparisionCard]').length) {
      document.querySelectorAll('[name=zeroAddComparisionCard]')[0].style.paddingRight = '9px'
    }
  })
  const getheight = () => {
    let scrolltop = document.documentElement.scrollTop
    if (props.cardtype === 'fund') {
      return scrolltop > 0 ? '97px' : '277px'
    }
    if (props.cardtype === 'equity') {
      return scrolltop > 0 ? '131px' : '300px'
    } else { return scrolltop > 0 ? '83px' : '233px' }
  }

  return (
    <div className={styles.lastelement} style={{paddingRight: '20px'}}>
      <div id='addComparisionOuterDiv' className={styles.comparisonContainer} style={props.valueStyling}>
        <div style={{height: getheight()}} id='addComparison' className={styles.itemSection} role='button' tabIndex='0' onKeyUp={(e) => keyEvents(e, null, false, 'textmsg', handleOnClick, true)} onClick={() => handleOnClick()}>
          <button id={props.index ? ('addComparisonbtn' + props.index) : 'addComparisonbtn'} data-testid='addComparisonbtn' aria-label={props.data} className={styles.compareImg} style={props.iconStyling}>
            <FontAwesomeIcon icon={props.iconName} />
          </button>
          <div className={styles.compareData} role='presentation' onKeyUp={(e) => keyEvents(e, null, false, 'textmsg', handleOnClick, true)} onClick={() => handleOnClick()}>{props.data}</div>
        </div>
      </div>
      {isBlankcardToShow
        ? <div style={{height: getheight()}} id='blankBorder' className={styles.blankBorder} />
        : ''
      }
    </div>
  )
}
ComparisonCard.propTypes = {
  data: PropTypes.string,
  iconName: PropTypes.array,
  valueStyling: PropTypes.object,
  iconStyling: PropTypes.object,
  addCompare: PropTypes.func,
  cardCount: PropTypes.number,
  index: PropTypes.number,
  cardtype: PropTypes.string
}
export default React.memo(ComparisonCard)
