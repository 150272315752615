import React, {useState, useEffect} from 'react'
import Modal from '../Modal/components/Modal'
import ModalBody from '../Modal/components/ModalBody'
import ModalHeader from '../Modal/components/ModalHeader'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from '../../Shared/Modal/Modal.module.scss'
import Search from '../Search/Search'
import ModalFooter from '../Modal/components/ModalFooter'
import Table from '../Table/Table'
import MaxSelectErrorModel from '../../Shared/MaxSelectErrorModel/MaxSelectErrorModel'
import { COUNTRY_US, URLS } from '../../../../../../utils/appConstants'
import DataRequest from '../../../../../../services/DataRequest'
import { PRODUCT_TYPE, NonTradedReit } from '../../utils/appConstants'
import {removeDuplicateModelsData} from '../../Helpers/CommonFunctions'
export default function AddCompareModal (props) {
  const [errorMsgTxt, setErrorMsgTxt] = useState(props.errorMsg)
  let [mockdata, setMockData] = useState([])
  let [tableData, setTableData] = useState([])
  // const [showCompareMessage, setCompareMessage] = useState(true)
  let [noresultsSearch, setnoresultsSearch] = useState(false)
  useEffect(() => {
    if (props.searchType === 'ai' && props.tableWidth) { addCompareHeaders[props.searchType].headers[0].fixedWidth = props.tableWidth }
  }, [props.tableWidth])
  useEffect(() => {
    setErrorMsgTxt(props.errorMsg)
  }, [props.errorMsg])
  const getSearchData = (seachValue, productType, additonalCriteria) => {
    const callback = (response) => {
      if (!response.isError) {
        let responsedata =
          response &&
          response.data &&
          response.data.results &&
          response.data.results.length > 0 &&
          response.data.results
        if (responsedata) {
          responsedata.forEach(element => {
            element.symbol = element.Symbol
            element.venueXid = element.VenueXID
            element.xid = element.VenueXID
            element.name = element.Name
            element.Description = element.Name
            element.Type = element.Classification
            element.Category = element.CategoryName ? element.CategoryName : '--'
            element.searchType = getClassification(element.Classification)
            element.Price = element.price
          })
          setnoresultsSearch(false)
          if (productType === 'Model') {
            let modelResults = removeDuplicateModelsData(responsedata, response.data)
            setMockData([...modelResults])
          } else {
            setMockData([...responsedata])
          }
          return responsedata
        } else {
          setNoResultsSearch(true)
        }
      }
      return []
    }
    DataRequest.execute(
      `${URLS.CUSTOM.SEARCH}`,
      {
        params: {
          searchText: seachValue,
          productType: productType,
          additonalCriteria: additonalCriteria,
          countryOfExchange: COUNTRY_US
        }
      },
      callback,
      null
    )
  }
  function getClassification (classification) {
    switch (classification) {
      case 'Mutual Fund':
        return 'MF'
      case 'Alternative':
        return 'AI'
      case 'Stock':
        return 'EQ'
      case 'Model':
        return 'MOD'
      default:
        return 'ETF'
    }
  }

  function searchTextChanged (value) {
    if (value.length === 0) return
    setMockData(['loader'])
    switch (props.searchType) {
      case 'fund':
        getSearchData(value, PRODUCT_TYPE.MF_ETF)
        break
      case 'ai':
        getSearchData(value, PRODUCT_TYPE.ALTERATIVE, NonTradedReit)
        break
      case 'equity':
        getSearchData(value, PRODUCT_TYPE.STOCK)
        break
    }
  }

  function setNoResultsSearch (value) {
    setnoresultsSearch(value)
    setMockData([])
    // setCompareMessage(true)
  }

  function searchTableResult (item) {
    let variableName = props.searchType ? props.searchType + '_search-items' : 'search-items'
    let items = JSON.parse(sessionStorage.getItem(variableName) || '[]')
    if (!items.some(result => result.Name === item.Name)) {
      items.unshift(item)
      sessionStorage.setItem(variableName, JSON.stringify(items))
    } else {
      items = items.filter(result => result.Name !== item.Name)
      items.unshift(item)
      sessionStorage.setItem(variableName, JSON.stringify(items))
    }
    if (!tableData.some(result => result.Name === item.Name)) {
      if (props.searchType === 'equity') {
        getEquitySector(item)
      } else {
        setTableData([...tableData, item])
      }
    }
  }

  function getEquitySector (item) {
    const getEquitySectorCallback = (response) => {
      if (!response.isError && response.data) {
        let data = response.data
        if (data.classification) {
          item.Sector = data.classification.morningstarIndustryName
          setTableData([...tableData, item])
        } else {
          item.Sector = '--'
          setTableData([...tableData, item])
        }
      } else {
        item.Sector = '--'
        setTableData([...tableData, item])
      }
    }
    DataRequest.execute(`${URLS.PLATFORM.MORNINGSTAR_EQUITIES_CLASSFICATION}/${item.VenueXID}`,
      {},
      getEquitySectorCallback,
      null
    )
  }

  function removeComapreRow (e) {
    const removeItem = e.currentTarget.parentElement.parentElement.firstElementChild.innerText
    const updatedTable = tableData.filter(result => result.Name.trim() !== removeItem)
    setTableData([...updatedTable])
  }

  function compareBtn () {
    props.compareBtnHandler(tableData)
    props.close()
  }

  function modelComparisonErrorPopup () {
    document.getElementById('maxErrorModel').style.display = ''
    document.getElementById('maxSelectError').focus()
    document.getElementById('addCompareModal').style.pointerEvents = 'none'
  }

  function errorModelClose () {
    document.getElementById('search_text').focus()
    document.getElementById('maxErrorModel').style.display = 'none'
    document.getElementById('addCompareModal').style.pointerEvents = 'revert'
  }
  let tableHeaders = !props.searchType ? addCompareHeaders['model'].headers : addCompareHeaders[props.searchType].headers
  return (
    <div>
      <Modal close={props.close} id='addCompareModal' className={styles.addCompareModal}>
        <div className={styles.headerModalAdjust}>
          <ModalHeader modalName={'addCompareModal'}>
            <div className={styles.header}>
              <div className={styles.openModalheaderText} aria-label={props.header}>{props.header}</div>
            </div>
            <div className={styles.closeSection}>
              <button className={styles.btnPopUpClose} aria-label='Close' onClick={props.close}>
                <FontAwesomeIcon icon={['fal', 'times']} className={styles.btnPopUpClose} data-icon-style='fa-compare-close' id='closeButtonFontAwsome' />
              </button>
            </div>
          </ModalHeader>
        </div>
        <ModalBody>
          <div className={`${styles.errroMsg} ${errorMsgTxt === '' ? styles.errorMsgAdjust : ''}`} aria-label={errorMsgTxt}>{errorMsgTxt}</div>
          <Search setSearchText={setMockData} data={mockdata} searchTableResult={searchTableResult} optionalTextmsg={props.optionalTextmsg} noresultsSearch={noresultsSearch} searchTextChanged={searchTextChanged} searchTextLabel={props.searchTextLabel || 'Search by description'} setNoResultsSearch={setNoResultsSearch} view={'Compare'} modelComparisonErrorPopup={modelComparisonErrorPopup} rowsAdded={tableData.length} cardCount={props.cardCount} searchType={props.searchType} />
          {props.searchType === 'ai' && <div className={styles.reitText}><FontAwesomeIcon icon={['fas', 'info-circle']} />Only Non-Traded REITs are available to compare.</div>}
          {tableData && tableData.length > 0
            ? <React.Fragment><div className={styles.tableTitle} style={props.searchType === 'ai' ? {padding: '15px 0 10px 20px'} : null} id='SelectedModels'>
              <div aria-hidden='true'>{ props.tableCaption || 'Selected Models'}</div>
              <p className='sr-only' role='heading' aria-level='2'>Selected Models Table Section</p>
            </div><div className={styles.compareResultTable}>
              <Table removeComapreRow={removeComapreRow} _data={tableData} moduleTypeName={props.moduleTypeName} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'SelectModelsTable'} zIndexHandlingReqd={props.zIndexHandlingReqd} />
            </div></React.Fragment>
            : <div className={styles.lblCompare}> <FontAwesomeIcon icon={['fal', 'search']} data-icon-style='fa-input-compare-search' /> <p className={styles.beginCompare}>Search to begin comparing</p></div>}
        </ModalBody>
        <ModalFooter>
          <div className={styles.footer} >
            <button id='compareBtn' tabIndex={tableData.length < 1 ? '-1' : '0'} className={`${styles.comparBtn} ${tableData.length < 1 ? styles['disabled'] : styles['enabled']}`} onClick={compareBtn} >
              Compare
            </button>
            <button id='cancelBtn' tabIndex='0' className={styles.cancelBtn} onClick={props.close} >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <MaxSelectErrorModel errorModelClose={errorModelClose} />
    </div>
  )
}

AddCompareModal.propTypes = {
  close: PropTypes.func,
  errorMsg: PropTypes.string,
  header: PropTypes.string,
  compareBtnHandler: PropTypes.func,
  cardCount: PropTypes.number,
  searchType: PropTypes.string,
  searchTextLabel: PropTypes.string,
  tableCaption: PropTypes.string,
  tableWidth: PropTypes.number,
  zIndexHandlingReqd: PropTypes.bool,
  optionalTextmsg: PropTypes.string,
  moduleTypeName: PropTypes.string
}

const addCompareHeaders = {
  ai: {
    'headers': [
      {
        'name': 'Non-Traded REIT',
        'noWrap': 0,
        'lines': 1,
        'control': '',
        'value': 'Name',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'fixed': true,
        'staticColWidth': 600,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': false,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 725,
        'color': '#000000'
      },
      {
        'name': '',
        'noWrap': 0,
        'lines': 1,
        'control': 'close-button',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 0,
        'fixed': true,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 50,
        'color': '#0572D6'
      }
    ]
  },
  fund: {
    'headers': [
      {
        'name': 'Description',
        'noWrap': 0,
        'lines': 3,
        'control': '',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 338,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 338,
        'staticTabletColWidth': 338,
        'fixedWidth': 388,
        'color': '#000000'
      },
      {
        'name': 'Type',
        'noWrap': 0,
        'lines': 1,
        'control': '',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 0,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 178,
        'color': '#000000'
      },
      {
        'name': 'Category',
        'noWrap': 0,
        'lines': 1,
        'control': '',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 0,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 108,
        'color': '#000000'
      },
      {
        'name': '',
        'noWrap': 0,
        'lines': 1,
        'control': 'close-button',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 0,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 50,
        'color': '#000000'
      }
    ]
  },
  model: {
    'headers': [
      {
        'name': 'Name',
        'noWrap': 0,
        'lines': 3,
        'control': '',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 338,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 338,
        'staticTabletColWidth': 338,
        'fixedWidth': 388,
        'color': '#000000'
      },
      {
        'name': 'Type',
        'noWrap': 0,
        'lines': 1,
        'control': '',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 0,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 178,
        'color': '#000000'
      },
      {
        'name': 'Category',
        'noWrap': 0,
        'lines': 1,
        'control': '',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 0,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 108,
        'color': '#000000'
      },
      {
        'name': '',
        'noWrap': 0,
        'lines': 1,
        'control': 'close-button',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 0,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 50,
        'color': '#000000'
      }
    ]
  },
  equity: {
    'headers': [
      {
        'name': 'Description',
        'noWrap': 0,
        'lines': 3,
        'control': '',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 338,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 338,
        'staticTabletColWidth': 338,
        'fixedWidth': 338,
        'color': '#0572D6'
      },
      {
        'name': 'Price',
        'noWrap': 0,
        'lines': 1,
        'control': '',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 0,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 178,
        'color': '#0572D6'
      },
      {
        'name': 'Sector',
        'noWrap': 0,
        'lines': 1,
        'control': '',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 0,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 108,
        'color': '#0572D6'
      },
      {
        'name': '',
        'noWrap': 0,
        'lines': 1,
        'control': 'close-button',
        'textType': '',
        'textAlign': 'left',
        'separator': true,
        'hideInMobile': false,
        'isSortable': false,
        'staticColWidth': 0,
        'isToggledInMobile': false,
        'showCollapseIconInMobile': false,
        'showEllipsis': true,
        'leftPosWithToggle': 0,
        'leftPosWithoutToggle': 0,
        'staticMobileColWidth': 0,
        'staticTabletColWidth': 0,
        'fixedWidth': 50,
        'color': '#0572D6'
      }
    ]
  }
}
