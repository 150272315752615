'use strict'

import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import Popover from '../Popover/Popover'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './CustomSelectMenu.module.scss'
import { EVENT_TYPE_LABEL } from '../../../utils/appConstants'

const CustomSelectMenu = ({preFixLabel, postFixLabel, icon, iconType, popOverBody, isVisible, popOverStyle, name}) => {
  const id = `CustomSelectMenu_${(preFixLabel || postFixLabel)}`
  const [toggle, setToggle] = useState(false)
  const ref = useRef(null)
  const togglePopover = () => {
    setToggle(!toggle)
  }

  useEffect(() => {
    togglePopover()
  }, [isVisible])

  return (
    <div data-custom-select='custom-select-menu'>
      <button
        data-testid={`button_${id}`}
        aria-label={preFixLabel || postFixLabel}
        type='button'
        ref={ref}
        onClick={togglePopover}
        tabIndex='0'
        name={name}
        className={styles.customSelectContainer}>
        {preFixLabel &&
        <span className={styles.preFixLabel}>{preFixLabel}</span>
        }
        {
          toggle ? <FontAwesomeIcon icon={[(iconType || 'fas'), (icon || 'caret-down')]} color='black' className={styles.icon} />
            : <FontAwesomeIcon icon={[(iconType || 'fas'), (icon || 'caret-up')]} color='black' className={styles.icon} />
        }
        {postFixLabel &&
        <span className={styles.postFixLabel}>{postFixLabel}</span>
        }
      </button>
      { !toggle &&
        <div id='Popover_Parent_Container' className={`${popOverStyle} ${styles.popoverContainer}`}>
          <Popover
            id={id}
            closePopover={togglePopover}
            body={popOverBody}
            referenceElement={ref.current}
            showPointingArrow={false}
            placement={preFixLabel === EVENT_TYPE_LABEL ? 'bottom-end' : 'bottom'}
          />
        </div>
      }
    </div>
  )
}

CustomSelectMenu.propTypes = {
  preFixLabel: PropTypes.string,
  postFixLabel: PropTypes.string,
  icon: PropTypes.string,
  iconType: PropTypes.string,
  popOverBody: PropTypes.node,
  isVisible: PropTypes.string,
  popOverStyle: PropTypes.string,
  name: PropTypes.string
}

export default CustomSelectMenu
