import React from 'react'
import PropTypes from 'prop-types'
import styles from './EarningEventInfo.module.scss'
import {PRICECOLOR} from '../../../../utils/appConstants'

export default function EarningEventInfo ({earningEventInfoData}) {
  return (
    <div className={styles.chartEventContainer}>
      <div className={styles.eventHeader}>
        <span className={styles.eventName}>Earnings Announcement</span>
        <span className={styles.eventDate}>{earningEventInfoData.date}</span>
      </div>
      <div className={styles.eventDescription}>
        <div className={styles.eventCol1}>{`Actual EPS ${earningEventInfoData.quarter} ${earningEventInfoData.year}`}</div>
        <div className={styles.eventCol2}>{earningEventInfoData.actualEPS}</div>
      </div>
      <div className={styles.eventDescription}>
        <div className={styles.eventCol1}>Estimated EPS</div>
        <div className={styles.eventCol2}>{earningEventInfoData.estimatedEPS}</div>
      </div>
      <div className={styles.eventDescription}>
        <div className={styles.eventCol1}>Surprise</div>
        <div className={styles.eventCol2}>
          {earningEventInfoData.surprise
            ? <span style={{color: earningEventInfoData.surprise > 0 ? PRICECOLOR.GREEN : PRICECOLOR.RED}}>{earningEventInfoData.surprise}</span>
            : '--'
          }
        </div>
      </div>
    </div>
  )
}

EarningEventInfo.propTypes = {
  earningEventInfoData: PropTypes.object
}
