import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { enterKeyEvent } from '../../../utils/utilities'
import styles from './FilterTag.module.scss'

export default function FilterTag ({label, onClose}) {
  return (
    <div className={styles.filterTagContainer}>
      <div className={styles.header}>
        {label}
      </div>
      <div className={styles.closeButton} role='button' data-testid='closeButton' tabIndex='0' onClick={onClose} onKeyDown={(e) => enterKeyEvent(e, onClose)}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </div>
    </div>
  )
}
FilterTag.propTypes = {
  label: PropTypes.string,
  onClose: PropTypes.func
}
