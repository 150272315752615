export const CHARTCOLORS = [
  '#004480',
  '#0095FF',
  '#D97E07',
  '#00B3A1',
  '#AA01AA',
  '#FF573F',
  '#9085FF',
  '#BF360C',
  '#00AC00',
  '#093055'
]
