'use strict'

import React from 'react'
import PrimaryButton from '../../../Lib/Button/PrimaryButton'
import SecondryButton from '../../../Lib/Button/SecondryButton'
import styles from './ChartsTimeMobileFooter.module.scss'
import PropTypes from 'prop-types'

export default function ChartsTimeMobileFooter ({applyHandler, resetHandler}) {
  return (<div className={styles.chartsMobileFooter}>
    <PrimaryButton tabIndex='0' buttonText='Apply' clickHandler={applyHandler} dataTestId='ChartsTimeMobileApplyBtnTestId' />
    <SecondryButton tabIndex='0' buttonText='Reset' clickHandler={resetHandler} dataTestId='ChartsTimeMobileResetBtnTestId' />
  </div>)
}
ChartsTimeMobileFooter.propTypes = {
  applyHandler: PropTypes.func,
  resetHandler: PropTypes.func
}
