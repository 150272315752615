import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './ESGScoreBox.module.scss'
import { deviceType, getDeviceType, handleWindowResize } from '../../../../../../../utils/utilities'
import Divider from '../../../../../../Lib/Divider/Divider'
import { sustainabilityRating } from '../../../utils/appConstants'

function ESGScoreBox ({data}) {
  const [device, setDevice] = useState(getDeviceType())

  useEffect(() => {
    handleWindowResize((response) => { setDevice(response) })
  }, [])

  return (
    <div className={styles.scoreRatingContainer} id='scoreRatingContainer'>
      <div className={styles.ratingContainer}>
        <div className={`${styles.ratingType}`} id='sustainabilityRatingHeading' >Sustainability Rating
          <span className={`${styles.popup}`}>{sustainabilityRating}
          </span>
          </div>
        <div className={styles.ratingCount}>
          {
            [...Array(5)].map((el, idx) =>
              <FontAwesomeIcon key={idx} icon={['fas', 'globe']} color={data?.sustainbilityRating > idx ? '#004480' : '#999999'} className={`${styles.icon}`} />)
          }
        </div>
      </div>
      {
        device === deviceType.Mobile && <Divider />
      }
      <div className={styles.separator} />
      <div className={styles.scoreContainer} id='scoreContainer'>
        <div className={styles.scoreType}>
          <div className={`${styles.label}`} id='Environment' >Environment</div>
          <div className={styles.value}>{data.environment}</div>
        </div>
        <div className={styles.scoreType}>
          <div className={`${styles.label}`} id='Social' >Social</div>
          <div className={styles.value}>{data.social}</div>
        </div>
        <div className={styles.scoreType}>
          <div className={`${styles.label}`} id='Governance' >Governance</div>
          <div className={styles.value}>{data.governance}</div>
        </div>
      </div>
    </div>
  )
}

ESGScoreBox.propTypes = {
  data: PropTypes.object
}
export default ESGScoreBox
