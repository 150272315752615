import React from 'react'
import PropTypes from 'prop-types'
import styles from './DividendEventInfo.module.scss'

export default function DividendEventInfo ({dividendEventInfoData}) {
  return (
    <div className={styles.chartEventContainer}>
      <div className={styles.eventHeader}>
        <span className={styles.eventName}>Dividend Announcement</span>
        <span className={styles.eventDate}>{dividendEventInfoData.date}</span>
      </div>
      <div className={styles.eventDescription}>
        <div className={styles.eventCol1}>Current Dividend</div>
        <div className={styles.eventCol2}>{dividendEventInfoData.currentDividend}</div>
      </div>
      <div className={styles.eventDescription}>
        <div className={styles.eventCol1}>Ex-Date</div>
        <div className={styles.eventCol2}>{dividendEventInfoData.exDate}</div>
      </div>
      <div className={styles.eventDescription}>
        <div className={styles.eventCol1}>Pay-Date</div>
        <div className={styles.eventCol2}>{dividendEventInfoData.payDate}</div>
      </div>
      <div className={styles.eventDescription}>
        <div className={styles.eventCol1}>Announcement Date</div>
        <div className={styles.eventCol2}>{dividendEventInfoData.announcementDate}</div>
      </div>
      <div className={styles.eventDescription}>
        <div className={styles.eventCol1}>Last Annual Dividend Yield(TTM)</div>
        <div className={styles.eventCol2}>{dividendEventInfoData.annualYield}</div>
      </div>
    </div>
  )
}

DividendEventInfo.propTypes = {
  dividendEventInfoData: PropTypes.object
}
