'use strict'

import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './ChartsMobileHeader.module.scss'
import PropTypes from 'prop-types'

export default function ChartsMobileHeader ({headerText, closeHandler, headerDataTestId}) {
  return (
    <div id='modalPopupHeader' className={styles.chartsModalHeader}>
      <div className={styles.chartsHeaderText}>{headerText}</div>
      <button className={styles.chartsPopUpBtnClose} alt='Close' id='closeButton' data-testid={headerDataTestId} onClick={closeHandler}>
        <FontAwesomeIcon icon={['fal', 'times']} className={styles.chartsPopUpClose} id='closeButtonFontAwsome' />
      </button>
    </div>
  )
}
ChartsMobileHeader.propTypes = {
  headerText: PropTypes.string,
  closeHandler: PropTypes.func,
  headerDataTestId: PropTypes.string
}
