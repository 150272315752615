import React from "react";
import PropTypes from "prop-types";
import styles from './TableRow.module.scss'

export default function TableRow ({ label, value, valueStyling, keyStyling, rowStyling, hideBorder}) {
  return (
      <div className={`${hideBorder ? styles.noBorder: ''} ${rowStyling} ${styles.tableRow}`}>
        <div className={styles.tableRowCol1} style={keyStyling} id={label?.replace(/[^a-zA-Z]/g, '')} >{label}</div>
        <div className={styles.tableRowCol2} style={valueStyling}>{value}</div>
      </div>
  );
};

TableRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  valueStyling: PropTypes.object,
  keyStyling: PropTypes.object,
  rowStyling: PropTypes.object,
  hideBorder: PropTypes.bool
};
