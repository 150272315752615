import React, {useEffect} from 'react'
import styles from './IndicatorCancelPopup.module.scss'
import Button from '../../Lib/Button/Button'
import PropTypes from 'prop-types'
import { CONFIRMATION_MESSAGES } from '../../../utils/appConstants'

function IndicatorCancelPopup (props) {
  useEffect(() => {
    let elementStyle = document.querySelector('div[name=IndicatorConfigurationScreen] > div')
    if (elementStyle) {
      elementStyle.style.backgroundColor = 'transparent'
    }
    let elementStyle2 = document.querySelector('div[name=IndicatorCancelPopup] > div')
    if (elementStyle2) {
      elementStyle2.style.backgroundColor = '#FFFFFF'
    }
  }, [])
  const popupExtraSettingReset = () => {
    let elementStyle = document.querySelector('div[name=IndicatorConfigurationScreen] > div')
    if (elementStyle) {
      elementStyle.style.backgroundColor = '#FFFFFF'
    }
    let elementStyle2 = document.querySelector('div[name=IndicatorCancelPopup] > div')
    if (elementStyle2) {
      elementStyle2.style.backgroundColor = '#FFFFFF'
    }
  }
  const handleCancel = e => {
    popupExtraSettingReset()
    props.conformation(CONFIRMATION_MESSAGES.Cancel)
  }
  const handleYes = e => {
    popupExtraSettingReset()
    props.conformation(CONFIRMATION_MESSAGES.Yes)
  }
  return (
    <div className={styles.IndicatorCancelPopupContainer}>
      <div className={styles.header}>Are you sure?</div>
      <div className={styles.content}>Your indicators will not be added to the page. Are you sure you want to cancel?</div>
      <div className={`${styles['button-container']} ${styles['buttons']}`}>
        <Button
          clickHandler={handleCancel}
          className={`${styles['cancel']}`}
          labelText='Cancel'
          tabIndex='0'
          buttonDataTestId='cancelButton'
        />
        <Button
          clickHandler={handleYes}
          className={`${styles['yes']}`}
          labelText='Yes'
          tabIndex='0'
          buttonDataTestId='yesButton'
        />
      </div>
    </div>
  )
}

IndicatorCancelPopup.propTypes = {
  conformation: PropTypes.func
}
export default IndicatorCancelPopup
