import React, { useState, useEffect } from 'react'
import styles from './ImageButton.module.scss'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const ImageButton = (props) => {
  const [isSelected, setIsSelected] = useState(false)

  const id = `ImageButton_${(props.preFixLabel || props.postFixLabel)}`

  useEffect(() => {
    setIsSelected(false)
  }, [props.resetTools])

  function hanleClickEvent () {
    setIsSelected(!isSelected)
    props.clickHandler(isSelected)
  }

  return <button
    data-testid={`button_${id}`}
    aria-label={props.preFixLabel || props.postFixLabel}
    type='button'
    onClick={hanleClickEvent}
    tabIndex='0'
    className={`${styles.imgButton} ${isSelected ? styles.selected : ''}`}>
    {
      props.preFixLabel &&
      <span className={styles.label}>{props.preFixLabel}</span>
    }
    {
      <FontAwesomeIcon icon={[(props.iconType || 'fas'), (props.icon || 'caret-down')]}
        color='black' className={styles.icon} />
    }
    {
      props.postFixLabel &&
      <span className={styles.label}>{props.postFixLabel}</span>
    }
  </button>
}

ImageButton.propTypes = {
  clickHandler: PropTypes.func,
  preFixLabel: PropTypes.string,
  postFixLabel: PropTypes.string,
  icon: PropTypes.string,
  iconType: PropTypes.string,
  resetTools: PropTypes.bool
}

export default ImageButton
