// These are defined in parent and if changes/modifications are required then please update in the parent
import {URLS as PARENT_URLS, DATE_FORMAT as PARENT_DATE_FORMAT} from '../../../../../utils/appConstants'
export {EventType, CalendarEventType, ChartTimeFrames, CURRENCY_CONVERSION_CONSTANTS, MostActiveData,
  rankingType, rankingSet, KEY_ACCESS_TOKEN, Exchange, ENVIRONMENTS_DOMAINS, DEFAULT_NEWS_INPUTS,
  DEFAULT_PRE_SCREEN_INPUTS, DEFAULT_PRE_SCREEN_FUNDSCREENER_INPUTS, CHART_STYLES, TREASURY_BILLS,
  CURRENCY, MARKET_EVENTS_CALENDER, COLOR_TABLE_CELL, PRICECOLOR, MARKET_EVENTS_CURRENCY_CONVERSION,
  TYPE_OF_VALUE, STOCK_VALUATION, CONFIRMATION_MESSAGES} from '../../../../../utils/appConstants'

export const URLS = {
  PLATFORM: {
    ...PARENT_URLS.PLATFORM,
    SECTOR_HIERARCHY: '/equities-morningstar-gecs/v1/hierarchy',
    TOP_HOLDINGS: 'managedfunds-morningstar-topholdings/v1/topHoldings',
    TOP_HOLDING_BAR: 'managedfunds-morningstar-portfoliostats/v1/details',
    MORNINGSTAR_MANAGEDFUNDS_BENCHMARK: 'managedfunds-morningstar-benchmarkinformation/v1/benchmarks',
    MORNINGSTAR_MANAGEDFUNDS_SHARECLASS: 'managedfunds-morningstar-shareclass/v1/share-class',
    MORNINGSTAR_MANAGEDFUNDS_PROSPECTUS: 'managedfunds-morningstar-prospectusfees/v1/prospectus-fees',
    MORNINGSTAR_MANAGEDFUNDS_YIELD: 'managedfunds-morningstar-yield/v1/details',
    MORNINGSTAR_MANAGEDFUNDS_FUNDATTRIBUTE: 'managedfunds-morningstar-fundattributes/v1/details',
    MORNINGSTAR_MANAGEDFUNDS_RISKMEASURE: 'managedfunds-morningstar-riskmeasures/v1/risk-measures',
    MORNINGSTAR_MANAGEDFUNDS_FUND_MANAGEMENT: 'managedfunds-morningstar-fundmanagement/v1/managers',
    MORNINGSTAR_MANAGEDFUNDS_STRATEGY: 'managedfunds-morningstar-strategy/v1/details',
    MORNINGSTAR_MANAGEDFUNDS_COMPANY_INFORMATION: 'managedfunds-morningstar-companyinformation/v1/company-information',
    MORNINGSTAR_MANAGEDFUNDS_RISK_MEASURE: 'managedfunds-morningstar-riskmeasures/v1/risk-measures',
    MORNINGSTAR_MANAGEDFUNDS_PEERS: 'managedfunds-morningstar-peers/v1/details',
    MORNINGSTAR_OWNERSHIP_DETAILS: '/ownership-morningstar/v1/fundDetails',
    DISTINCT_VALUES_SCREEN: 'screener/v1/screens',
    VIEWSHARECLASSES: 'managedfunds-morningstar-shareclassexplorer/v1/relatedShareClasses',
    MANAGEDFUNDS_MORNINGSTAR_PORTFOLIO_STATS: 'managedfunds-morningstar-portfoliostats/v1/details',
    MANAGEDFUNDS_MORNINGSTAR_OVERALL_ANALYST_RATING: 'managedfunds-morningstar-esgfundratings/v1/ESGFundRatings',
    MANAGEDFUNDS_MORNINGSTAR_PURCHASE_DETAILS: 'managedfunds-morningstar-purchasedetails/v1/purchase-details',
    MANAGEDFUNDS_MORNINGSTAR_ANNUAL_FEES: 'managedfunds-morningstar-annualfees/v1/details',
    MANAGEDFUNDS_MORNINGSTAR_PERFORMANCE: 'managedfunds-morningstar-performance/v1/trailing-month-end',
    MANAGEDFUNDS_MORNINGSTAR_QUARTER_PERFORMANCE: 'managedfunds-morningstar-performance-quarterend/v1/quarterend-trailing-performance',
    MANAGEDFUNDS_MORNINGSTAR_GROWTHOF10K: 'managedfunds-morningstar-growthof10k/v2/growth-of-10k',
    MANAGEDFUNDS_MORNINGSTAR_ESG_CARBON_METRICS: 'managedfunds-morningstar-esgcarbonmetrics/v1/ESGCarbonMetrics',
    MANAGEDFUNDS_MORNINGSTAR_ESG_CARBON_RISK: 'managedfunds-morningstar-esgcarbonmetrics/v2/carbonRisk',
    MANAGEDFUNDS_MORNINGSTAR_ESG_CARBON_INVOLVEMENT: 'managedfunds-morningstar-esgcarbonmetrics/v2/carbonInvolvement'
  },
  CUSTOM: {
    ...PARENT_URLS.CUSTOM,
    GET_DM_REPORT: 'pershing-netxwealth-api/v1/document',
    MF_PERSHING_MFDS_DATA: 'pershing-netxwealth-api/v1/getPershingMfdsDataPoints',
    COST_ANALYZER: 'pershing-netxwealth-api/v1/costAnalyzer',
    MORNINGSTAR_COMMENTARY: 'pershing-netxwealth-api/v1/newsCommentary',
    MORNINGSTAR_COMMENTARY_DETAIL: 'pershing-netxwealth-api/v1/newsCommentaryDetail',
    GROWTH_OF_10K_FUND: 'pershing-netxwealth-api/v1/getGrowthOf10K',
    SUSTAINABLE_SCORE: 'pershing-netxwealth-api/v1/getSustainabilityScore',
    SAVE_OBJECT_STORE: 'pershing-netxwealth-api/v1/saveString',
    GET_OBJECT_STORE: 'pershing-netxwealth-api/v1/getAllString',
    REMOVE_OBJECT_STORE: 'pershing-netxwealth-api/v1/removeString',
    MMF_PRODUCT_TYPE: 'pershing-netxwealth-api/v1/getMMFAttributeData',
    THOUGHT_LEADESRHIP_ITEM: 'thoughtleadershipitem',
    RESOURCE_ITEM: 'resourceitem',
    CAIS_ITEM: 'cais'
  }
}

export const SCREENER_TYPES = {
  EQUITY_SCREENER: {
    ARIA_LABEL: 'Equity screener Filters'
  },
  FUND_SCREENER: {
    ARIA_LABEL: 'Fund screener Filters'
  },
  AI_SCREENER: {
    ARIA_LABEL: 'AI screener Filters'
  }
}

export const PRODUCT_TYPE = {
  MF: 'Mutual Fund',
  ETF: 'ETF',
  ALTERATIVE: 'Alternative',
  STOCK: 'Stock',
  ALL: 'All',
  MF_ETF: 'Mutual Fund or ETF'
}

export const AssetClassTypes = {
  RealEstate: 'Real Estate',
  Securities: 'Securities',
  Cash: 'Cash',
  Other: 'Other'
}
export const FundTabsConstant = {
  KeyMeasures: 'Key Measures',
  Operational: 'Operational',
  Profile: 'Profile',
  AssetAllocation: 'Asset Allocation',
  Income: 'Income',
  Performance: 'Performance',
  PortfolioCharacteristics: 'Portfolio Characteristics',
  Ratings: 'Ratings',
  Risk: 'Risk',
  TechnicalIndicators: 'Technical Indicators',
  ESGRating: 'ESG Rating',
  ESGCarbonMetrics: 'ESG Carbon Metrics'
}

export const AiTabsConstant = {
  Profile: 'Profile'
}

export const DEFAULT_PRE_SCREEN_EQUITYSCREENER_INPUTS = {
  argsOperator: 'AND',
  arguments: [],
  offset: 0,
  limit: 20,
  sortArguments: [
    {
      direction: 'A',
      field: 'PEREQWSODIssue'
    }
  ],
  resultFields: []
}
export const PRESCREENER_EQUITYSCREENER =
[
  {
    name: 'Value Stocks',
    description: 'Value Stocks are those which are trading at a discount to their true value.',
    value:
    {
      'argsOperator': 'AND',
      'savedFilters': [
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQPERatio',
          'Level': 'L2',
          'selectedLabel': 'Price/Earnings (TTM)',
          'selectedVal': '0-15',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': ''
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQPriceBookMRQ',
          'Level': 'L2',
          'selectedLabel': 'Price/Book (MRQ)',
          'selectedVal': '0-1',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': ''
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQPriceSalesTTM',
          'Level': 'L2',
          'selectedLabel': 'Price/Sales (TTM)',
          'selectedVal': '0-2',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': ''
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQDivYield',
          'Level': 'L2',
          'selectedLabel': 'Dividend Yield (%)',
          'selectedVal': '4-6',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': [
            '4-6'
          ]
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQDivYield',
          'Level': 'L2',
          'selectedLabel': 'Dividend Yield (%)',
          'selectedVal': '>6',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': [
            '4-6',
            '>4'
          ]
        }
      ],
      'arguments': [
        {
          'arguments':
           [
             {
               'field': 'PEREQPERatio',
               'valueOperator': 'AND',
               'conditions': [
                 {
                   'operator': 'GreaterThanEqualTo',
                   'values': ['0']
                 },
                 {
                   'operator': 'LessThanEqualTo',
                   'values': ['15']
                 }
               ]
             }
           ],
          'argsOperator': 'OR',
          'field': '',
          'conditions': []
        },
        {
          'field': 'PEREQPriceBookMRQ',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['0']
            },
            {
              'operator': 'LessThanEqualTo',
              'values': ['1']
            }
          ]
        },
        {
          'field': 'PEREQPriceSalesTTM',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['0']
            },
            {
              'operator': 'LessThanEqualTo',
              'values': ['2']
            }
          ]
        },
        {
          'field': 'PEREQDivYield',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['4']
            }
          ]
        }
      ],
      'offset': 0,
      'limit': 20,
      'sortArguments': [
        {
          'direction': 'D',
          'field': 'PEREQCompanyName'
        }
      ],
      'noCache': false
    }
  },
  {
    name: 'Small Cap Value',
    description: 'Value Stocks are those which are trading at a discount to their true value.',
    value:
    {
      'argsOperator': 'AND',
      'savedFilters': [
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQPERatio',
          'Level': 'L2',
          'selectedLabel': 'Price/Earnings (TTM)',
          'selectedVal': '0-15',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': ''
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQPriceBookMRQ',
          'Level': 'L2',
          'selectedLabel': 'Price/Book (MRQ)',
          'selectedVal': '0-1',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': ''
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQPriceSalesTTM',
          'Level': 'L2',
          'selectedLabel': 'Price/Sales (TTM)',
          'selectedVal': '0-2',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': ''
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQDivYield',
          'Level': 'L2',
          'selectedLabel': 'Dividend Yield (%)',
          'selectedVal': '>4',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': [
            '>4'
          ]
        },
        {
          'rootFilterId': 'basics-select',
          'ParentField': 'PEREQMarketCap',
          'Level': 'L2',
          'selectedLabel': '',
          'selectedVal': 'Small',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': [
            'Small'
          ]
        }
      ],
      'arguments': [
        {
          'arguments':
           [
             {
               'field': 'PEREQPERatio',
               'valueOperator': 'AND',
               'conditions': [
                 {
                   'operator': 'GreaterThanEqualTo',
                   'values': ['0']
                 },
                 {
                   'operator': 'LessThanEqualTo',
                   'values': ['15']
                 }
               ]
             }
           ],
          'argsOperator': 'OR',
          'field': '',
          'conditions': []
        },
        {
          'field': 'PEREQPriceBookMRQ',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['0']
            },
            {
              'operator': 'LessThanEqualTo',
              'values': ['1']
            }
          ]
        },
        {
          'field': 'PEREQPriceSalesTTM',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['0']
            },
            {
              'operator': 'LessThanEqualTo',
              'values': ['2']
            }
          ]
        },
        {
          'field': 'PEREQDivYield',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['4']
            }
          ]
        },
        {
          'field': 'PEREQMarketCap',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['1000000000']
            },
            {
              'operator': 'LessThanEqualTo',
              'values': ['10000000000']
            }
          ]
        }
      ],
      'offset': 0,
      'limit': 20,
      'sortArguments': [
        {
          'direction': 'D',
          'field': 'PEREQCompanyName'
        }
      ],
      'noCache': false
    }
  },
  {
    name: 'Growth Stocks',
    description: 'Growth Stocks are usually younger companies with products that are gaining interest by consumers, resulting in increasing revenue growth.',
    value:
    {
      'argsOperator': 'AND',
      'savedFilters': [
        {
          'rootFilterId': 'performance-select',
          'ParentField': 'PEREQPctChgVs52wkHi',
          'Level': 'L2',
          'selectedLabel': '',
          'selectedVal': '(-200)-5',
          'control': 'slider',
          'isDynamicData': 'false'
        },
        {
          'rootFilterId': 'growth-select',
          'ParentField': 'PEREQEPSGrowth3Y',
          'Level': 'L3',
          'selectedLabel': '',
          'selectedVal': '>15',
          'control': 'rangeSelectorCheckbox',
          'selectedValue': [
            '>15'
          ]
        },
        {
          'rootFilterId': 'growth-select',
          'ParentField': 'PEREQRevGrowthAnnual',
          'Level': 'L3',
          'selectedLabel': '',
          'selectedVal': '>15',
          'control': 'rangeSelectorCheckbox',
          'selectedValue': [
            '>15'
          ]
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQDivYield',
          'Level': 'L2',
          'selectedLabel': 'Dividend Yield (%)',
          'selectedVal': '<2',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': [
            '<2'
          ]
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQPriceSalesTTM',
          'Level': 'L2',
          'selectedLabel': 'Price/Sales (TTM)',
          'selectedVal': '>5',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': [
            '>2'
          ]
        }
      ],
      'arguments': [
        {
          'arguments':
           [
             {
               'field': 'PEREQPctChgVs52wkHi',
               'valueOperator': 'AND',
               'conditions': [
                 {
                   'operator': 'LessThanEqualTo',
                   'values': ['5']
                 }
               ]
             }
           ],
          'argsOperator': 'OR',
          'field': '',
          'conditions': []
        },
        {
          'field': 'PEREQEPSGrowth3Y',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['15']
            }
          ]
        },
        {
          'field': 'PEREQRevGrowthAnnual',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['15']
            }
          ]
        },
        {
          'field': 'PEREQDivYield',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'LessThanEqualTo',
              'values': ['2']
            }
          ]
        },
        {
          'field': 'PEREQPriceSalesTTM',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['2']
            }
          ]
        }
      ],
      'offset': 0,
      'limit': 20,
      'sortArguments': [
        {
          'direction': 'D',
          'field': 'PEREQCompanyName'
        }
      ],
      'noCache': false
    }
  },
  {
    name: 'Growth at a Reasonable Price',
    description: 'Growth Stocks are usually younger companies with products that are gaining interest by consumers, resulting in increasing revenue growth.',
    value:
    {
      'argsOperator': 'AND',
      'savedFilters': [
        {
          'rootFilterId': 'performance-select',
          'ParentField': 'PEREQPctChgVs52wkHi',
          'Level': 'L2',
          'selectedLabel': '',
          'selectedVal': '(-200)-5',
          'control': 'slider',
          'isDynamicData': 'false',
          'selectedValue': ''
        },
        {
          'rootFilterId': 'growth-select',
          'ParentField': 'PEREQEPSGrowth3Y',
          'Level': 'L3',
          'selectedLabel': '',
          'selectedVal': '>15',
          'control': 'rangeSelectorCheckbox',
          'selectedValue': [
            '>15'
          ]
        },
        {
          'rootFilterId': 'growth-select',
          'ParentField': 'PEREQRevGrowthAnnual',
          'Level': 'L3',
          'selectedLabel': '',
          'selectedVal': '>15',
          'control': 'rangeSelectorCheckbox',
          'selectedValue': [
            '>15'
          ]
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQDivYield',
          'Level': 'L2',
          'selectedLabel': 'Dividend Yield (%)',
          'selectedVal': '<2',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': [
            '<2'
          ]
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQPriceCashFlowTTM',
          'Level': 'L2',
          'selectedLabel': 'Price/Cash Flow (TTM)',
          'selectedVal': '(-100000)-20',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': ''
        },
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQPriceCashFlowTTM',
          'Level': 'L2',
          'selectedLabel': 'Price/Cash Flow (TTM)',
          'selectedVal': '0-20',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': ''
        }
      ],
      'arguments': [
        {
          'arguments':
           [
             {
               'field': 'PEREQPctChgVs52wkHi',
               'valueOperator': 'AND',
               'conditions': [
                 {
                   'operator': 'LessThanEqualTo',
                   'values': ['5']
                 }
               ]
             }
           ],
          'argsOperator': 'OR',
          'field': '',
          'conditions': []
        },
        {
          'field': 'PEREQEPSGrowth3Y',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['15']
            }
          ]
        },
        {
          'field': 'PEREQRevGrowthAnnual',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['15']
            }
          ]
        },
        {
          'field': 'PEREQDivYield',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'LessThanEqualTo',
              'values': ['2']
            }
          ]
        },
        {
          'field': 'PEREQPriceCashFlowTTM',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'LessThanEqualTo',
              'values': ['20']
            }
          ]
        }
      ],
      'offset': 0,
      'limit': 20,
      'sortArguments': [
        {
          'direction': 'D',
          'field': 'PEREQCompanyName'
        }
      ],
      'noCache': false
    }
  },
  {
    name: 'Large Cap Growth',
    description: 'Growth stocks are typically companies with products that are gaining interest by consumers, resulting in increasing revenue growth.',
    value:
    {
      'argsOperator': 'AND',
      'savedFilters': [
        {
          'rootFilterId': 'valuation-select',
          'ParentField': 'PEREQPERatio',
          'Level': 'L2',
          'selectedLabel': 'Price/Earnings (TTM)',
          'selectedVal': '>25',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': [
            '>25'
          ]
        },
        {
          'rootFilterId': 'growth-select',
          'ParentField': 'PEREQRevGrowthAnnual',
          'Level': 'L3',
          'selectedLabel': '',
          'selectedVal': '>20',
          'control': 'rangeSelectorCheckbox',
          'selectedValue': [
            '>20'
          ]
        },
        {
          'rootFilterId': 'growth-select',
          'ParentField': 'PEREQEPSGrowth1Y',
          'Level': 'L3',
          'selectedLabel': '',
          'selectedVal': '>0',
          'control': 'rangeSelectorCheckbox',
          'selectedValue': [
            '>0'
          ]
        },
        {
          'rootFilterId': 'basics-select',
          'ParentField': 'PEREQMarketCap',
          'Level': 'L2',
          'selectedLabel': '',
          'selectedVal': 'Mega',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': [
            'Mega'
          ]
        },
        {
          'rootFilterId': 'basics-select',
          'ParentField': 'PEREQMarketCap',
          'Level': 'L2',
          'selectedLabel': '',
          'selectedVal': 'Large',
          'control': 'rangeSelectorCheckbox',
          'isDynamicData': 'false',
          'selectedValue': [
            'Mega',
            'Large'
          ]
        }
      ],
      'arguments': [
        {
          'arguments':
           [
             {
               'field': 'PEREQPERatio',
               'valueOperator': 'AND',
               'conditions': [
                 {
                   'operator': 'GreaterThanEqualTo',
                   'values': ['25']
                 }
               ]
             }
           ],
          'argsOperator': 'OR',
          'field': '',
          'conditions': []
        },
        {
          'field': 'PEREQRevGrowthAnnual',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['20']
            }
          ]
        },
        {
          'field': 'PEREQEPSGrowth1Y',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['0']
            }
          ]
        },
        {
          'field': 'PEREQMarketCap',
          'valueOperator': 'AND',
          'conditions': [
            {
              'operator': 'GreaterThanEqualTo',
              'values': ['30000000000']
            }
          ]
        }
      ],
      'offset': 0,
      'limit': 20,
      'sortArguments': [
        {
          'direction': 'D',
          'field': 'PEREQCompanyName'
        }
      ],
      'noCache': false
    }
  }
]
export const PRESCREENER_EQUITYSCREENER1 =
[
  {
    name: 'Value Stocks',
    'argsOperator': 'AND',
    'arguments': [
      {
        'arguments': [
          {
            'field': 'PEREQLastClosePrice',
            'valueOperator': 'AND',
            'conditions': [
              {
                'operator':
                'GreaterThanEqualTo',
                'values': ['5']
              },
              {
                'operator': 'LessThanEqualTo',
                'values': ['100']
              }
            ]
          }
        ],
        'argsOperator': 'OR',
        'field': '',
        'conditions': []},
      {
        'field': 'PEREQStyleBox',
        'conditions': [
          {'operator': 'Like',
            'values': ['1']
          }
        ]
      },
      {'field': 'PEREQExchange',
        'conditions': [{'operator': 'Like',
          'values': ['NAQ, NMQ, NSQ']}]},
      {
        'field': 'PEREQWSODADRIssue',
        'conditions': [
          {
            'operator': 'Like',
            'values': ['Yes']}]},
      {
        'field': 'PEREQResearchTeamRating',
        'conditions': [
          {'operator': 'Like',
            'values': ['Hold']
          }
        ]
      },
      {
        'field': 'PEREQHasOptionsFlag',
        'conditions': [
          {
            'operator': 'Like', 'values': ['Yes']
          }
        ]
      },
      {
        'field': 'PEREQMStarSectorDescription',
        'conditions': [
          {
            'operator': 'Like',
            'values': ['Communication Services']}]},
      {
        'field': 'PEREQMStarIndustryDescription',
        'conditions': [
          {
            'operator': 'Like',
            'values': ['Advertising Agencies']
          }
        ]
      }
    ],
    'offset': 0,
    'limit': 20,
    'sortArguments': [
      {
        'direction': 'A',
        'field': 'PERAICusip'
      }
    ],
    'noCache': false
  }
]

export const EquityScreenerBasicsArr = [
  'PEREQCompanyName',
  'PEREQSymbol',
  'PEREQMarketCap',
  'PEREQLastClosePrice',
  'PEREQMStarSectorDescription',
  'PEREQSectorCode',
  'PEREQMStarIndustryCode',
  'PEREQMStarIndustryDescription',
  'PEREQStyleBox',
  'PEREQExchange',
  'PEREQWSODADRIssue',
  'PEREQResearchTeamRating',
  'PEREQHasOptionsFlag'
]

export const EquityScreenerValuationArr = [
  'PEREQCompanyName',
  'PEREQSymbol',
  'PEREQPERatio',
  'PEREQNoOfDaysOut',
  'PEREQPriceBookMRQ',
  'PEREQPriceSalesTTM',
  'PEREQPriceCashFlowTTM',
  'PEREQDivYield',
  'PEREQDivGrowth',
  'PEREQDivPayout'
]

export const EquityScreenerFundamentalsArr = [
  'PEREQCompanyName',
  'PEREQSymbol',
  'PEREQGrossMarginTTM',
  'PEREQOperatingProfitMarginTTM',
  'PEREQProfitMarginTTM',
  'PEREQROATTM',
  'PEREQROA5YAvg',
  'PEREQROETTM',
  'PEREQROE5YAvg',
  'PEREQDebtTotalCapTTM'
]

export const EquityScreenerGrowthArr = [
  'PEREQSymbol',
  'PEREQCompanyName',
  'PEREQEPSGrowthLast5Qtr',
  'PEREQEPSGrowth3Y',
  'PEREQEPSGrowth5Y',
  'PEREQPctGroFY1MeanOverFY0EPS',
  'PEREQRevGrowthAnnual',
  'PEREQRevGrowth3Y',
  'PEREQRevGrowth5Y',
  'PEREQFCFGrowth3Y'
]

export const EquityScreenerPerformanceArr = [
  'PEREQSymbol',
  'PEREQCompanyName',
  'PEREQPrice1dPctChg',
  'PEREQPrice1dPctChgPtile',
  'PEREQPrice1wPctChg',
  'PEREQPrice5dPctChgPtile',
  'PEREQPrice4wPctChg',
  'PEREQPrice4wPctChgPtile',
  'PEREQPrice13wPctChg',
  'PEREQPrice13wPctChgPtile',
  'PEREQPrice26wPctChg',
  'PEREQPrice26wPctChgPtile',
  'PEREQPrice52wPctChg',
  'PEREQPrice52wPctChgPtile',
  'PEREQPricePerfvsSP5004wk',
  'PEREQPctChgVs52wkHi',
  'PEREQPctChgVs52wkLo',
  'PEREQDayDiff52wHigh',
  'PEREQDayDiff52wLow'

]

export const EquityScreenerTechnicalsArr = [
  'PEREQSymbol',
  'PEREQCompanyName',
  'PEREQBeta',
  'PEREQStdDev',
  'PEREQLastVolume',
  'PEREQVolume90dAvg',
  'PEREQDollarVolume'
]

export const EquityScreenerResultColumnArr = [
  'PEREQCompanyName',
  'PEREQSymbol',
  'PEREQMarketCap',
  'PEREQLastClosePrice',
  'PEREQMStarSectorDescription',
  'PEREQSectorCode',
  'PEREQMStarIndustryCode',
  'PEREQMStarIndustryDescription',
  'PEREQStyleBox',
  'PEREQExchange',
  'PEREQWSODADRIssue',
  'PEREQResearchTeamRating',
  'PEREQHasOptionsFlag',
  'PEREQLastTradeDate',
  'PEREQPERatio',
  'PEREQNoOfDaysOut',
  'PEREQUpcomingAnnouncementDate',
  'PEREQPriceBookMRQ',
  'PEREQPriceSalesTTM',
  'PEREQPriceCashFlowTTM',
  'PEREQDivYield',
  'PEREQDivGrowth',
  'PEREQDivCoverage',
  'PEREQDivPayout',
  'PEREQEPSTTM',
  'PEREQEPSGrowthMRQvsPrevMRQ',
  'PEREQEPSGrowthLast5Qtr',
  'PEREQEPSGrowth1Y',
  'PEREQEPSGrowth3Y',
  'PEREQEPSGrowth5Y',
  'PEREQPctGroFY1MeanOverFY0EPS',
  'PEREQEPSEstimate3To5Yrs',
  'PEREQRevGrowthMRQvsPrevMRQ',
  'PEREQRevGrowthAnnual',
  'PEREQRevGrowth3Y',
  'PEREQRevGrowth5Y',
  'PEREQFCFGrowth1Y',
  'PEREQFCFGrowth3Y',
  'PEREQFCFGrowth5Y',
  'PEREQGrossMarginTTM',
  'PEREQOperatingProfitMarginTTM',
  'PEREQProfitMarginTTM',
  'PEREQROATTM',
  'PEREQROA5YAvg',
  'PEREQROETTM',
  'PEREQROE5YAvg',
  'PEREQDebtTotalCapTTM',
  'PEREQPrice1dPctChg',
  'PEREQPrice1wPctChg',
  'PEREQPrice4wPctChg',
  'PEREQPrice13wPctChg',
  'PEREQPrice26wPctChg',
  'PEREQPrice52wPctChg',
  'PEREQPrice1dPctChgPtile',
  'PEREQPrice5dPctChgPtile',
  'PEREQPrice4wPctChgPtile',
  'PEREQPrice13wPctChgPtile',
  'PEREQPrice26wPctChgPtile',
  'PEREQPrice52wPctChgPtile',
  'PEREQPricePerfvsSP5004wk',
  'PEREQPricePerfvsSP50013wk',
  'PEREQPricePerfvsSP50052wk',
  'PEREQPctChgVs52wkHi',
  'PEREQPctChgVs52wkLo',
  'PEREQDayDiff52wHigh',
  'PEREQDayDiff52wLow',
  'PEREQPrice52wHigh',
  'PEREQPrice52wLow',
  'PEREQBeta',
  'PEREQStdDev',
  'PEREQLastVolume',
  'PEREQVolume90dAvg',
  'PEREQDollarVolume'
]

export const EquityScreenerTableFieldDictionary = {
  'Company': 'PEREQCompanyName',
  'Symbol': 'PEREQSymbol',
  'Market Cap': 'PEREQMarketCap',
  'Last Close Price': 'PEREQLastClosePrice',
  'Sector': 'PEREQMStarSectorDescription',
  'Industry': 'PEREQMStarIndustryDescription',
  'Equity Style Box': 'PEREQStyleBox',
  'Listing Exchange': 'PEREQExchange',
  'ADR Listing': 'PEREQWSODADRIssue',
  'Markit Consensus Rating': 'PEREQResearchTeamRating',
  'Options Available': 'PEREQHasOptionsFlag',
  'P/E Ratio (TTM)': 'PEREQPERatio',
  '# Days to Next Earnings': 'PEREQNoOfDaysOut',
  'Price/Book (MRQ)': 'PEREQPriceBookMRQ',
  'Price/Sales (TTM)': 'PEREQPriceSalesTTM',
  'Price/CF (TTM)': 'PEREQPriceCashFlowTTM',
  'Dividend Yield (%)': 'PEREQDivYield',
  'Dividend Growth 1 Year (%)': 'PEREQDivGrowth',
  'Dividend Payout (%)': 'PEREQDivPayout',
  'Gross Margin (TTM)': 'PEREQGrossMarginTTM',
  'Operating Margin (TTM)': 'PEREQOperatingProfitMarginTTM',
  'Net Profit Margin (TTM)': 'PEREQProfitMarginTTM',
  'ROA (TTM)': 'PEREQROATTM',
  'ROA (5 Year Average)': 'PEREQROA5YAvg',
  'ROE (TTM)': 'PEREQROETTM',
  'ROE (5 Year Average)': 'PEREQROE5YAvg',
  'Debt to Total Capital (MRQ)': 'PEREQDebtTotalCapTTM',
  'EPS Growth (QoPQ)': 'PEREQEPSGrowthMRQvsPrevMRQ',
  'EPS Growth - MRQ 1 Yr': 'PEREQEPSGrowthLast5Qtr',
  'Est EPS Growth - Next FY': 'PEREQPctGroFY1MeanOverFY0EPS',
  'EPS LT Growth Rate - Estimate': 'PEREQEPSEstimate3To5Yrs',
  'Revenue Growth (QoPQ)': 'PEREQRevGrowthMRQvsPrevMRQ',
  'Price Change - 1 Day': 'PEREQPrice1dPctChg',
  'Price Change - 5 Day': 'PEREQPrice1wPctChg',
  'Price Change - 4 Week (%)': 'PEREQPrice4wPctChg',
  'Price Change - 13 Week (%)': 'PEREQPrice13wPctChg',
  'Price Change - 52 Week': 'PEREQPrice52wPctChg',
  'Price Change vs S&P 500 - 4 Week': 'PEREQPricePerfvsSP5004wk',
  '% Below 52 Wk High': 'PEREQPctChgVs52wkHi',
  '% Above 52 Wk Low': 'PEREQPctChgVs52wkLo',
  'Days Since 52 Week High': 'PEREQDayDiff52wHigh',
  'Days Since 52 Week Low': 'PEREQDayDiff52wLow',
  'Beta vs S&P 500 - 3 Yr': 'PEREQBeta',
  'Std Deviation - 3 Yr': 'PEREQStdDev',
  'Last Volume': 'PEREQLastVolume',
  'Volume - 90 Day Avg': 'PEREQVolume90dAvg',
  'Dollar Volume - Last Trade Day': 'PEREQDollarVolume'
}

export const ModelPreScreenInput = [
  'PERMMarketPlace',
  'PERMopenToAll',
  'PERMIBD',
  'PERMStrategy',
  'PERMPrgramPrductMgrCodeStrtegy'
]

export const FundScreenerKeyMeasuresArr = [
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFMorningstarCategoryName',
  'SPCFMorningstarOverallRating',
  'SPCFProspectusNetExpenseRatio',
  'SPCFFundType',
  'SPCFNoTransactonFeeEligibilityIndicator',
  'SPCFIsFVETF',
  'SPCFDateAdded',
  'SPCFFundNetAssets',
  'SPCFTrailingReturnY3',
  'SPCFTaxCostRatioY3',
  'SPCFIndicator12b1',
  'SPCFMStarOverrideCUSIP',
  'SPCFISIN',
  'SPCFMStarOverrideFundFamilyName',
  'SPCFOffshoreFlex',
  'SPCFActual12B1Fee',
  'SPCFTradeStatus'
]
export const FundScreenerOperationalArr = [
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFMStarOverrideFundShareClass',
  'SPCFFundservIndicator',
  'SPCFOffShoreIndicator',
  'SPCFOffShoreProcessingIndicator',
  'SPCFMStarOverrideLoadType',
  'SPCFMStarOverrideClosedToNewInvestors',
  'SPCFClosedToSellsIndicator',
  'SPCFTenderIndicator',
  'SPCFDealerAgreementRequiredForLoadTrades',
  'SPCFDealerAgreementRequiredForLoadTransfers',
  'SPCFDealerAgreementRequiredForNo_LoadTrades',
  'SPCFDealerAgreementRequiredForNo_LoadTransfers',
  'SPCFBNYMellon',
  'SPCFOffshoreFlex',
  'SPCFSURCHARGE'
]
export const FundScreenerProfileArr = [
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFFundAssetClassGroup',
  'SPCFLeveragedFundFlag',
  'SPCFLeveragedFundFactor',
  'SPCFProspectusPrimaryBenchmarkName',
  'SPCFGrossExpenseRatio',
  'SPCFSalesLoadDeferred',
  'SPCFSalesLoadMaxFrontLoad',
  'SPCFInceptionDate',
  'SPCFManagerTenure',
  'SPCFMStarOverrideMinimumInitialInvestment',
  'SPCFOffshoreFlex'
]
export const FundScreenerAssetAllocationArr = [
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFWeightingTop10Holdings',
  'SPCFYearlyTurnoverRatio',
  'SPCFTurnoverRatioDate',
  'SPCFEquityStyleBoxText',
  'SPCFFixedIncomeStyleBoxText',
  'SPCFTotalBondNet',
  'SPCFTotalStockNet',
  'SPCFPortfolioDate',
  'SPCFOffshoreFlex'
]
export const FundScreenerIncomeArr = [
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFMStarOverrideDividendFrequency',
  'SPCFTrailing1YrYield',
  'SPCFSECYield',
  'SPCFOffshoreFlex'
]
export const FundScreenerPerformanceArr = [
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFNAVChgPct',
  'SPCFNAVChgAmt',
  'SPCFTrailingMarketReturnYTD',
  'SPCFTrailingMarketReturnY1',
  'SPCFTrailingMarketReturnY3',
  'SPCFTrailingMarketReturnY5',
  'SPCFTrailingMarketReturnY10',
  'SPCFTrailingReturnYTD',
  'SPCFTrailingReturnY1',
  'SPCFTrailingReturnY3',
  'SPCFTrailingReturnY5',
  'SPCFTrailingReturnY10',
  'SPCFTaxCostRatioY3',
  'SPCFTaxCostRatioY5',
  'SPCFPerformanceAsOfDate',
  'SPCFOffshoreFlex'
]
export const FundScreenerPortfolioCharacteristicsArr = [
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFNumberofHoldingsNet',
  'SPCFAverageMarketCap',
  'SPCFEarningsYieldLongInv',
  'SPCFAverageCreditQualityLongLetter',
  'SPCFAvgCoupon',
  'SPCFYieldToMaturityLong',
  'SPCFPortfolioDate',
  'SPCFOffshoreFlex'
]
export const FundScreenerRatingsArr = [
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFMedalistRating',
  'SPCFMorningstarOverallRating',
  'SPCFMorningstar3YrRating',
  'SPCFMorningstar5YrRating',
  'SPCFMorningstar10YrRating',
  'SPCFReturnScore',
  'SPCFRiskScore',
  'SPCFMedalistRatingDate',
  'SPCFMStarRatingAsOfDate',
  'SPCFOffshoreFlex'
]
export const FundScreenerEsgCarbonMetricsArr = [
  'SPCFWSODIssue',
  'SPCFOffshoreFlex',
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFMStarOverrideCUSIP',
  'SPCFCarbonRiskClassification',
  'SPCFLowCarbonDesignation',
  'SPCFCarbonRiskScore',
  'SPCFCarbonRiskScorePercentRankinCategory',
  'SPCFAverage12MonthFossilFuelExposure',
  'SPCFPctAUMNeg2MedCarbonRisk',
  'SPCFPctAUMHigh2SevCarbonRisk',
  'SPCFCarbonSolutionsInvolvement',
  'SPCFCarbonSolutionsCategoryAverage',
  'SPCFCarbonIntensity',
  'SPCFCarbonIntensityCategoryAverage',
  'SPCFPercentAUMCoveredCarbon',
  'SPCFScope1Emissions',
  'SPCFScope2Emissions'
]
export const FundScreenerRiskArr = [
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFSharpeRatioY3',
  'SPCFSharpeRatioY5',
  'SPCFStandardDeviationY3',
  'SPCFStandardDeviationY5',
  'SPCFPrimaryIndexAlphaY3',
  'SPCFPrimaryIndexAlphaY5',
  'SPCFPrimaryIndexBetaY3',
  'SPCFPrimaryIndexBetaY5',
  'SPCFPrimaryIndexRSquaredY3',
  'SPCFPrimaryIndexRSquaredY5',
  'SPCFCaptureRatio3YearUp',
  'SPCFCaptureRatio3YearDown',
  'SPCFCaptureRatio5YearUp',
  'SPCFCaptureRatio5YearDown',
  'SPCFMorningstarPrimaryBenchmarkName',
  'SPCFPerformanceAsOfDate',
  'SPCFOffshoreFlex'
]
export const FundScreenerTechIndicatorArr = [
  'SPCFOnBalanceVolume',
  'SPCFMStarOverrideFundName',
  'SPCFVolume',
  'SPCFAvg10DayVolume',
  'SPCFVolume90dAvg',
  'SPCFPctChgVs52wkLo',
  'SPCFPctChgVs52wkHi',
  'SPCFPremiumDiscountM1Average',
  'SPCFPremiumDiscountY1Average',
  'SPCFPremiumDiscountY1High',
  'SPCFPremiumDiscountY1Low',
  'SPCFPremiumDiscountAsOfDate',
  'SPCFticker',
  'SPCFOffshoreFlex'
]
export const FundScreenerResultColumnArr = [
  'SPCFfundname',
  'SPCFMStarOverrideFundName',
  'SPCFticker',
  'SPCFMorningstarCategoryName',
  'SPCFMorningstarOverallRating',
  'SPCFProspectusNetExpenseRatio',
  'SPCFFundType',
  'SPCFNoTransactonFeeEligibilityIndicator',
  'SPCFIsFVETF',
  'SPCFDateAdded',
  'SPCFFundNetAssets',
  'SPCFTrailingReturnY3',
  'SPCFTaxCostRatioY3',
  'SPCFIndicator12b1',
  'SPCFMStarOverrideCUSIP',
  'SPCFISIN',
  'SPCFMStarOverrideFundFamilyName',
  'SPCFMStarOverrideFundShareClass',
  'SPCFFundservIndicator',
  'SPCFOffShoreIndicator',
  'SPCFOffShoreProcessingIndicator',
  'SPCFMStarOverrideLoadType',
  'SPCFMStarOverrideClosedToNewInvestors',
  'SPCFClosedToSellsIndicator',
  'SPCFTenderIndicator',
  'SPCFDealerAgreementRequiredForLoadTrades',
  'SPCFDealerAgreementRequiredForLoadTransfers',
  'SPCFDealerAgreementRequiredForNo_LoadTrades',
  'SPCFDealerAgreementRequiredForNo_LoadTransfers',
  'SPCFBNYMellon',
  'SPCFSURCHARGE',
  'SPCFFundAssetClassGroup',
  'SPCFLeveragedFundFlag',
  'SPCFLeveragedFundFactor',
  'SPCFProspectusPrimaryBenchmarkName',
  'SPCFGrossExpenseRatio',
  'SPCFSalesLoadDeferred',
  'SPCFSalesLoadMaxFrontLoad',
  'SPCFInceptionDate',
  'SPCFManagerTenure',
  'SPCFMStarOverrideMinimumInitialInvestment',
  'SPCFWeightingTop10Holdings',
  'SPCFYearlyTurnoverRatio',
  'SPCFTurnoverRatioDate',
  'SPCFEquityStyleBoxText',
  'SPCFFixedIncomeStyleBoxText',
  'SPCFTotalBondNet',
  'SPCFTotalStockNet',
  'SPCFPortfolioDate',
  'SPCFMStarOverrideDividendFrequency',
  'SPCFTrailing1YrYield',
  'SPCFSECYield',
  'SPCFNAVChgPct',
  'SPCFNAVChgAmt',
  'SPCFTrailingMarketReturnYTD',
  'SPCFTrailingMarketReturnY1',
  'SPCFTrailingMarketReturnY3',
  'SPCFTrailingMarketReturnY5',
  'SPCFTrailingMarketReturnY10',
  'SPCFTrailingReturnYTD',
  'SPCFTrailingReturnY1',
  'SPCFTrailingReturnY5',
  'SPCFTrailingReturnY10',
  'SPCFTaxCostRatioY5',
  'SPCFPerformanceAsOfDate',
  'SPCFNumberofHoldingsNet',
  'SPCFAverageMarketCap',
  'SPCFEarningsYieldLongInv',
  'SPCFAverageCreditQualityLongLetter',
  'SPCFAvgCoupon',
  'SPCFYieldToMaturityLong',
  'SPCFMedalistRating',
  'SPCFMorningstar3YrRating',
  'SPCFMorningstar5YrRating',
  'SPCFMorningstar10YrRating',
  'SPCFReturnScore',
  'SPCFRiskScore',
  'SPCFMedalistRatingDate',
  'SPCFMStarRatingAsOfDate',
  'SPCFSharpeRatioY3',
  'SPCFSharpeRatioY5',
  'SPCFStandardDeviationY3',
  'SPCFStandardDeviationY5',
  'SPCFPrimaryIndexAlphaY3',
  'SPCFPrimaryIndexAlphaY5',
  'SPCFPrimaryIndexBetaY3',
  'SPCFPrimaryIndexBetaY5',
  'SPCFPrimaryIndexRSquaredY3',
  'SPCFPrimaryIndexRSquaredY5',
  'SPCFCaptureRatio3YearUp',
  'SPCFCaptureRatio3YearDown',
  'SPCFCaptureRatio5YearUp',
  'SPCFCaptureRatio5YearDown',
  'SPCFMorningstarPrimaryBenchmarkName',
  'SPCFOnBalanceVolume',
  'SPCFVolume',
  'SPCFAvg10DayVolume',
  'SPCFVolume90dAvg',
  'SPCFPctChgVs52wkLo',
  'SPCFPctChgVs52wkHi',
  'SPCFPremiumDiscountM1Average',
  'SPCFPremiumDiscountY1Average',
  'SPCFPremiumDiscountY1High',
  'SPCFPremiumDiscountY1Low',
  'SPCFPremiumDiscountAsOfDate',
  'SPCFBollingerPeriod20StdDev2Squeeze',
  'SPCFOffshoreFlex',
  'SPCFActual12B1Fee',
  'SPCFTradeStatus',
  'SPCFSustainableInvestment',
  'SPCFSustainabilityRating',
  'SPCFPortCorpSustRating',
  'SPCFPortSovgSustRating',
  'SPCFFundHistAvgSustScore',
  'SPCFHistSovgSustScore',
  'SPCFPortfolioEnvironmentalScore',
  'SPCFPortfolioGovernanceScore',
  'SPCFPortfolioSocialScore',
  'SPCFPortSustRatingCorpContibPct',
  'SPCFPortSustRatingSovgContribPct',
  'SPCFCorpESGNeg2Med',
  'SPCFCorpESGHigh2Sev',
  'SPCFSovESGNeg2Med',
  'SPCFSovESGHigh2Sev',
  'SPCFPctAumMod2NoControversies',
  'SPCFPctAumSev2SigControversies',
  'SPCFCarbonRiskClassification',
  'SPCFLowCarbonDesignation',
  'SPCFCarbonRiskScore',
  'SPCFCarbonRiskScorePercentRankinCategory',
  'SPCFAverage12MonthFossilFuelExposure',
  'SPCFPctAUMNeg2MedCarbonRisk',
  'SPCFPctAUMHigh2SevCarbonRisk',
  'SPCFCarbonSolutionsInvolvement',
  'SPCFCarbonSolutionsCategoryAverage',
  'SPCFCarbonIntensity',
  'SPCFCarbonIntensityCategoryAverage',
  'SPCFPercentAUMCoveredCarbon',
  'SPCFScope1Emissions',
  'SPCFScope2Emissions'
]
export const FundScreenerEsgRatingArr = [
  'SPCFWSODIssue',
  'SPCFOffshoreFlex',
  'SPCFticker',
  'SPCFMStarOverrideFundName',
  'SPCFMStarOverrideCUSIP',
  'SPCFSustainableInvestment',
  'SPCFSustainabilityRating',
  'SPCFPortCorpSustRating',
  'SPCFPortSovgSustRating',
  'SPCFFundHistAvgSustScore',
  'SPCFHistSovgSustScore',
  'SPCFPortfolioEnvironmentalScore',
  'SPCFPortfolioGovernanceScore',
  'SPCFPortfolioSocialScore',
  'SPCFPortSustRatingCorpContibPct',
  'SPCFPortSustRatingSovgContribPct',
  'SPCFCorpESGNeg2Med',
  'SPCFCorpESGHigh2Sev',
  'SPCFSovESGNeg2Med',
  'SPCFSovESGHigh2Sev',
  'SPCFPctAumMod2NoControversies',
  'SPCFPctAumSev2SigControversies'
]
export const FundShareClassSnapshotResultArr = [
  'SPCFMStarOverrideFundName',
  'SPCFMStarOverrideFundShareClass',
  'SPCFFundservIndicator',
  'SPCFFundNetAssets',
  'SPCFProspectusNetExpenseRatio',
  'SPCFGrossExpenseRatio',
  'SPCFticker',
  'SPCFMStarOverrideCUSIP',
  'SPCFLoadType',
  'SPCFWSODIssue',
  'SPCFTenderIndicator'
]
export const FundShareClassScreenerResultArr = [
  'SPCFfundname',
  'SPCFticker',
  'SPCFMorningstarCategoryName',
  'SPCFMorningstarOverallRating',
  'SPCFProspectusNetExpenseRatio',
  'SPCFFundType',
  'SPCFNoTransactonFeeEligibilityIndicator',
  'SPCFIsFVETF',
  'SPCFDateAdded',
  'SPCFFundNetAssets',
  'SPCFTrailingReturnY3',
  'SPCFTaxCostRatioY3',
  'SPCFIndicator12b1',
  'SPCFMStarOverrideCUSIP',
  'SPCFMStarOverrideFundFamilyName',
  'SPCFMStarOverrideFundShareClass',
  'SPCFFundservIndicator',
  'SPCFOffShoreIndicator',
  'SPCFOffShoreProcessingIndicator',
  'SPCFMStarOverrideLoadType',
  'SPCFMStarOverrideClosedToNewInvestors',
  'SPCFClosedToSellsIndicator',
  'SPCFTenderIndicator',
  'SPCFBNYMellon',
  'SPCFSURCHARGE',
  'SPCFFundAssetClassGroup',
  'SPCFLeveragedFundFlag',
  'SPCFLeveragedFundFactor',
  'SPCFProspectusPrimaryBenchmarkName',
  'SPCFGrossExpenseRatio',
  'SPCFSalesLoadDeferred',
  'SPCFSalesLoadMaxFrontLoad',
  'SPCFInceptionDate',
  'SPCFManagerTenure',
  'SPCFMStarOverrideMinimumInitialInvestment',
  'SPCFWeightingTop10Holdings',
  'SPCFYearlyTurnoverRatio',
  'SPCFTurnoverRatioDate',
  'SPCFEquityStyleBoxText',
  'SPCFFixedIncomeStyleBoxText',
  'SPCFTotalBondNet',
  'SPCFTotalStockNet',
  'SPCFPortfolioDate',
  'SPCFMStarOverrideDividendFrequency',
  'SPCFTrailing1YrYield',
  'SPCFSECYield',
  'SPCFNAVChgPct',
  'SPCFNAVChgAmt',
  'SPCFTrailingReturnYTD',
  'SPCFTrailingReturnY1',
  'SPCFTrailingReturnY5',
  'SPCFTrailingReturnY10',
  'SPCFTaxCostRatioY5',
  'SPCFPerformanceAsOfDate',
  'SPCFNumberofHoldingsNet',
  'SPCFAverageMarketCap',
  'SPCFEarningsYieldLongInv',
  'SPCFAverageCreditQualityLong',
  'SPCFAvgCoupon',
  'SPCFMinimumInitialPurchaseAmount',
  'SPCFClosedToBuysIndicator',
  'SPCFManagementFee',
  'SPCFActual12B1Fee',
  'SPCFSURCHARGE',
  'SPCFLoadType',
  'SPCFSalesLoadMaxFrontLoad',
  'SPCFMStarOverrideFundName'
]

export const DEFAULT_FUND_CARD_FUNDSCREENER_INPUTS = {
  argsOperator: 'AND',
  arguments: [],
  offset: 0,
  limit: 4,
  sortArguments: [
    {
      direction: 'D',
      field: 'SPCFTrailingReturnY5'
    }
  ],
  resultFields: FundScreenerResultColumnArr
}

export const AISnapshotResultColumnArr = [
  'NTRName',
  'PershingIdentifier',
  'CUSIP',
  'Fundraising',
  'MultipleShareClasses',
  'NumberofShareClasses',
  'ShareClass',
  'SellingCommissionPct',
  'DealerManagerFeePct',
  'DistributionandStockholderServicingFeePct',
  'TotalAssetsInMillion',
  'RealEstateAssetsInMillion',
  'CashInMillion',
  'SecuritiesInMillion',
  'OtherInMillion',
  'RealEstateAssetsPctofTA',
  'CashPctofTA',
  'SecuritiesPctofTA',
  'OtherPctofTA',
  'CashtoTotalAssetsRatioPct',
  'AssetType',
  'NumberofInvestments',
  'RealEstateAssetSize',
  'RealEstateAssetSizeIdentifier',
  'PercentLeasedPct',
  'WeightedAvgAnlLeaseExpirationInYears',
  'LifeStage',
  'InvestmentStyle',
  'InitialOfferingDate',
  'NumberofMonthsFundraising',
  'PerpetualOffering',
  'AnticipatedOfferingCloseDate',
  'OfferingPriceperShareCurrentQtr',
  'ReinvestmentPriceperShareCurrentQtr',
  'HistoricalNAV5QtrsPrior',
  'HistoricalNAV4QtrsPrior',
  'HistoricalNAV3QtrsPrior',
  'HistoricalNAV2QtrsPrior',
  'HistoricalNAV1QtrPrior',
  'HistoricalNAVCurrentQtr',
  'HistoricalNAV5QtrsPriorPrd',
  'HistoricalNAV4QtrsPriorPrd',
  'HistoricalNAV3QtrsPriorPrd',
  'HistoricalNAV2QtrsPriorPrd',
  'HistoricalNAV1QtrPriorPrd',
  'HistoricalNAVCurrentQtrPrd',
  'GrossDollarsRaisedInceptionInMillion',
  'GrossDollarsRaisedCurrentQtrInMillion',
  'AnlDistributionYield5QtrsPriorPct',
  'AnlDistributionYield4QtrsPriorPct',
  'AnlDistributionYield3QtrsPriorPct',
  'AnlDistributionYield2QtrsPriorPct',
  'AnlDistributionYield1QtrPriorPct',
  'AnlDistributionYieldCurrentQtrPct',
  'AnlDistributionYield5QtrsPriorPctPrd',
  'AnlDistributionYield4QtrsPriorPctPrd',
  'AnlDistributionYield3QtrsPriorPctPrd',
  'AnlDistributionYield2QtrsPriorPctPrd',
  'AnlDistributionYield1QtrPriorPctPrd',
  'AnlDistributionYieldCurrentQtrPctPrd',
  'FFOPayoutRatio4YearsPriorPct',
  'FFOPayoutRatio3YearsPriorPct',
  'FFOPayoutRatio2YearsPriorPct',
  'FFOPayoutRatio1YearPriorPct',
  'FFOPayoutRatioYTDPct',
  'FFOPayoutRatioCurrentQtrPct',
  'FFOPayoutRatio4YearsPriorPctPrd',
  'FFOPayoutRatio3YearsPriorPctPrd',
  'FFOPayoutRatio2YearsPriorPctPrd',
  'FFOPayoutRatio1YearPriorPctPrd',
  'FFOPayoutRatioYTDPctPrd',
  'FFOPayoutRatioCurrentQtrPctPrd',
  'MFFOPayoutRatio4YearsPriorPct',
  'MFFOPayoutRatio3YearsPriorPct',
  'MFFOPayoutRatio2YearsPriorPct',
  'MFFOPayoutRatio1YearPriorPct',
  'MFFOPayoutRatioYTDPct',
  'MFFOPayoutRatioCurrentQtrPct',
  'MFFOPayoutRatio4YearsPriorPctPrd',
  'MFFOPayoutRatio3YearsPriorPctPrd',
  'MFFOPayoutRatio2YearsPriorPctPrd',
  'MFFOPayoutRatio1YearPriorPctPrd',
  'MFFOPayoutRatioYTDPctPrd',
  'MFFOPayoutRatioCurrentQtrPctPrd',
  'SharesRedeemedasPctofWASO5QtrsPriorPct',
  'SharesRedeemedasPctofWASO4QtrsPriorPct',
  'SharesRedeemedasPctofWASO3QtrsPriorPct',
  'SharesRedeemedasPctofWASO2QtrsPriorPct',
  'SharesRedeemedasPctofWASO1QtrPriorPct',
  'SharesRedeemedasPctofWASOCurrentQtrPct',
  'SharesRedeemedasPctofWASO5QtrsPriorPctPrd',
  'SharesRedeemedasPctofWASO4QtrsPriorPctPrd',
  'SharesRedeemedasPctofWASO3QtrsPriorPctPrd',
  'SharesRedeemedasPctofWASO2QtrsPriorPctPrd',
  'SharesRedeemedasPctofWASO1QtrPriorPctPrd',
  'SharesRedeemedasPctofWASOCurrentQtrPctPrd',
  'DebttoTotalAssetsRatioCurrentQtrPct',
  'TotalDebtOtsdCurrentQtrInMillion',
  'TotalFixedRateDebtOtsdCurrentQtrInMillion',
  'TotalVariableRateDebtOtsdCurrentQtrInMillion',
  'TotalFixedRateDebtOtsdPctofTotalDebtCurrentQtr',
  'TotalVariableRateDebtOtsdPctofTotalDebtCurrentQtr',
  'AverageWeightedInterestRateCurrentQtrPct',
  'DebtRepaymentDueasPctofTotalDebtCurrentYearPct',
  'DebtRepaymentDueasPctofTotalDebt2YearsPct',
  'DebtRepaymentDueasPctofTotalDebt3YearsPct',
  'DebtRepaymentDueasPctofTotalDebt4YearsPct',
  'DebtRepaymentDueasPctofTotalDebt5YearsPct',
  'DebtRepaymentDueasPctofTotalDebt5PlusYearsPct',
  'DebtRepaymentDueasPctofTotalDebtCurrentYearPctPrd',
  'DebtRepaymentDueasPctofTotalDebt2YearsPctPrd',
  'DebtRepaymentDueasPctofTotalDebt3YearsPctPrd',
  'DebtRepaymentDueasPctofTotalDebt4YearsPctPrd',
  'DebtRepaymentDueasPctofTotalDebt5YearsPctPrd',
  'DebtRepaymentDueasPctofTotalDebt5PlusYearsPctPrd',
  'InterestCoverageRatio5YearsPriorPct',
  'InterestCoverageRatio4YearsPriorPct',
  'InterestCoverageRatio3YearsPriorPct',
  'InterestCoverageRatio2YearsPriorPct',
  'InterestCoverageRatio1YearPriorPct',
  'InterestCoverageRatioYTD',
  'InterestCoverageRatio5YearsPriorPctPrd',
  'InterestCoverageRatio4YearsPriorPctPrd',
  'InterestCoverageRatio3YearsPriorPctPrd',
  'InterestCoverageRatio2YearsPriorPctPrd',
  'InterestCoverageRatio1YearPriorPctPrd',
  'InterestCoverageRatioYTDPrd',
  'AnalystSummary',
  'OperatingPerformance',
  'FinancingOutlook',
  'CumulativeMFFOPayout',
  'OperatingPerformanceLanguage',
  'FinancingOutlookLanguage',
  'CumulativeMFFOPayoutLanguage',
  'Website',
  'Phone',
  'Name',
  'Address1',
  'Address2',
  'City',
  'State',
  'Zip',
  'FileName',
  'Sponsor',
  'Quarter',
  'Year',
  'TaskId',
  'InsertDate',
  'InsertJobID',
  'UpdateDate',
  'UpdateJobID'
]

export const FundCompareResultColumnArr = [
  'SPCFticker',
  'SPCFFundName',
  'SPCFMorningstarCategoryName',
  'SPCFMorningstarOverallRating',
  'SPCFProspectusNetExpenseRatio',
  'SPCFFundType',
  'SPCFNoTransactonFeeEligibilityIndicator',
  'SPCFIsFVETF',
  'SPCFFundNetAssets',
  'SPCFFundShareClassNetAssets',
  'SPCFIndicator12b1',
  'SPCFNoTransactonFeeEligibilityIndicator',
  'SPCFMStarOverrideCUSIP',
  'SPCFISIN',
  'SPCFMStarOverrideFundFamilyName',
  'SPCFDateAdded',
  'SPCFMStarOverrideFundShareClass',
  'SPCFFundservIndicator',
  'SPCFOffShoreIndicator',
  'SPCFOffShoreProcessingIndicator',
  'SPCFMStarOverrideLoadType',
  'SPCFClosedToSellsIndicator',
  'SPCFTenderIndicator',
  'SPCFClosedToBuysIndicator',
  'SPCFDividendReinvestindicator',
  'SPCFCapitalGainReinvestIndicator',
  'SPCFDealerAgreementRequiredForLoadTrades',
  'SPCFDealerAgreementRequiredForLoadTransfers',
  'SPCFDealerAgreementRequiredForNo_LoadTrades',
  'SPCFDealerAgreementRequiredForNo_LoadTransfers',
  'SPCFBNYMellon',
  'SPCFSURCHARGE',
  'SPCFMStarOverrideClosedToNewInvestors',
  'SPCFFundAssetClassGroup',
  'SPCFLeveragedFundFlag',
  'SPCFLeveragedFundFactor',
  'SPCFProspectusPrimaryBenchmarkName',
  'SPCFGrossExpenseRatio',
  'SPCFSalesLoadDeferred',
  'SPCFSalesLoadMaxFrontLoad',
  'SPCFManagementFee',
  'SPCFInceptionDate',
  'SPCFManager',
  'SPCFManagerTenure',
  'SPCFMStarOverrideMinimumInitialInvestment',
  'SPCFMStarOverrideSubsequentMinimumInvestment',
  'SPCFMinInvIRA',
  'SPCFMStarOverrideLastClosePrice',
  'SPCFMstarOverrideLastClosePriceDate',
  'SPCFMStarOverrideLastNAV',
  'SPCFMstarOverrideLastNAVDate',
  'SPCFCurrency',
  'SPCFWeightingTop10Holdings',
  'SPCFYearlyTurnoverRatio',
  'SPCFTurnoverRatioDate',
  'SPCFEquityStyleBoxText',
  'SPCFFixedIncomeStyleBoxText',
  'SPCFTotalBondNet',
  'SPCFUSBondNet',
  'SPCFNonUSBondNet',
  'SPCFTotalStockNet',
  'SPCFUSStockNet',
  'SPCFNonUSStockNet',
  'SPCFCashNet',
  'SPCFConvertibleNet',
  'SPCFOtherNet',
  'SPCFRegionUnitedStates',
  'SPCFRegionCanada',
  'SPCFRegionEurope',
  'SPCFRegionAsia',
  'SPCFRegionOther',
  'SPCFStockMarketGiantCapNet',
  'SPCFStockMarketLargeCapNet',
  'SPCFStockMarketMicroCapNet',
  'SPCFStockMarketMidCapNet',
  'SPCFStockMarketSmallCapNet',
  'SPCFStockSectorBasicMaterials',
  'SPCFStockSectorCommunicationServices',
  'SPCFStockSectorConsumerCyclical',
  'SPCFStockSectorConsumerDefensive',
  'SPCFStockSectorEnergy',
  'SPCFStockSectorFinancialServices',
  'SPCFStockSectorHealthcare',
  'SPCFStockSectorIndustrials',
  'SPCFStockSectorRealEstate',
  'SPCFStockSectorTechnology',
  'SPCFStockSectorUtilities',
  'SPCFBondSectorCashAllocationNet',
  'SPCFBondSectorCorporateAllocationNet',
  'SPCFBondSectorDerivativeAllocationNet',
  'SPCFBondSectorGovernmentAllocationNet',
  'SPCFBondSectorMunicipalAllocationNet',
  'SPCFBondSectorSecuritizedAllocationNet',
  'SPCFCreditQualityAAA',
  'SPCFCreditQualityAA',
  'SPCFCreditQualityA',
  'SPCFCreditQualityBBB',
  'SPCFCreditQualityBB',
  'SPCFCreditQualityB',
  'SPCFCreditQualityBelowB',
  'SPCFCreditQualityNotRated',
  'SPCFPortfolioDate',
  'SPCFMStarOverrideDividendFrequency',
  'SPCFTrailing1YrYield',
  'SPCFSECYield',
  'SPCFNAVChgPct',
  'SPCFNAVChgAmt',
  'SPCFCalendarYearPostivePerformance',
  'SPCFCalendarYearNegativePerformance',
  'SPCFTrailingReturnM1',
  'SPCFTrailingReturnM3',
  'SPCFTrailingReturnYTD',
  'SPCFTrailingReturnY1',
  'SPCFTrailingReturnY3',
  'SPCFTrailingReturnY5',
  'SPCFTrailingReturnY10',
  'SPCFTrailingReturnSinceInception',
  'SPCFTrailingMarketReturnM1',
  'SPCFTrailingMarketReturnM3',
  'SPCFTrailingMarketReturnYTD',
  'SPCFTrailingMarketReturnY1',
  'SPCFTrailingMarketReturnY3',
  'SPCFTrailingMarketReturnY5',
  'SPCFTrailingMarketReturnY10',
  'SPCFTrailingMarketReturnSinceInception',
  'SPCFTaxCostRatioY3',
  'SPCFTaxCostRatioY5',
  'SPCFPerformanceAsOfDate',
  'SPCFInceptionDate',
  'SPCFNumberofHoldingsNet',
  'SPCFNumberOfBondHoldings',
  'SPCFNumberOfStockHoldings',
  'SPCFAverageMarketCap',
  'SPCFEarningsYieldLongInv',
  'SPCFBookValueYieldLongInv',
  'SPCFROALong',
  'SPCFROELong',
  'SPCFAverageCreditQualityLong',
  'SPCFCreditQualityDate',
  'SPCFAvgCoupon',
  'SPCFAvgEffDuration',
  'SPCFAvgEffMaturity',
  'SPCFYieldToMaturityLong',
  'SPCFPortfolioDate',
  'SPCFMedalistRating',
  'SPCFMorningstarOverallRating',
  'SPCFMorningstar3YrRating',
  'SPCFMorningstar5YrRating',
  'SPCFMorningstar10YrRating',
  'SPCFReturnScore',
  'SPCFRiskScore',
  'SPCFMedalistRatingDate',
  'SPCFMStarRatingAsOfDate',
  'SPCFSharpeRatioY3',
  'SPCFStandardDeviationY3',
  'SPCFPrimaryIndexAlphaY3',
  'SPCFPrimaryIndexBetaY3',
  'SPCFPrimaryIndexRSquaredY3',
  'SPCFCaptureRatio3YearUp',
  'SPCFCaptureRatio3YearDown',
  'SPCFSharpeRatioY5',
  'SPCFStandardDeviationY5',
  'SPCFPrimaryIndexAlphaY5',
  'SPCFPrimaryIndexBetaY5',
  'SPCFPrimaryIndexRSquaredY5',
  'SPCFCaptureRatio5YearUp',
  'SPCFCaptureRatio5YearDown',
  'SPCFMorningstarPrimaryBenchmarkName',
  'SPCFPerformanceAsOfDate',
  'SPCFOnBalanceVolume',
  'SPCFVolume',
  'SPCFAvg10DayVolume',
  'SPCFVolume90dAvg',
  'SPCFPctChgVs52wkLo',
  'SPCFPctChgVs52wkHi',
  'SPCFPremiumDiscountM1Average',
  'SPCFPremiumDiscountY1Average',
  'SPCFPremiumDiscountY1High',
  'SPCFPremiumDiscountY1Low',
  'SPCFPremiumDiscountAsOfDate',
  'SPCFOffshoreFlex',
  'SPCFAverageCreditQualityLongLetter',
  'SPCFTradeStatus',
  'SPCFSustainableinvestment',
  'SPCFSustainabilityRating',
  'SPCFPortCorpSustRating',
  'SPCFFundHistAvgSustScore',
  'SPCFPortSovgSustRating',
  'SPCFHistSovgSustScore',
  'SPCFPortfolioEnvironmentalScore',
  'SPCFPortfolioGovernanceScore',
  'SPCFPortfolioSocialScore',
  'SPCFEnvironmentalGlobalCatAvg',
  'SPCFGovernanceGlobalCatAvg',
  'SPCFSocialGlobalCatAvg',
  'SPCFPortSustRatingCorpContibPct',
  'SPCFPortSustRatingSovgContribPct',
  'SPCFPctAumNegligibleESGRiskScores',
  'SPCFPctAumLowESGRiskScores',
  'SPCFPctAumMediumESGRiskScores',
  'SPCFPctAumHighESGRiskScores',
  'SPCFPctAumSevereESGRiskScores',
  'SPCFCorpESGNeg2Med',
  'SPCFCorpESGHigh2Sev',
  'SPCFSovgPctPortCoveredNegligibleESGRiskScore',
  'SPCFSovgPctPortCoveredLowESGRiskScore',
  'SPCFSovgPctPortCoveredMediumESGRiskScore',
  'SPCFSovgPctPortCoveredHighESGRiskScore',
  'SPCFSovgPctPortCoveredSevereESGRiskScore',
  'SPCFSovESGNeg2Med',
  'SPCFSovESGHigh2Sev',
  'SPCFPctAumNoControversies',
  'SPCFPctAumLowControversies',
  'SPCFPctAumModerateControversies',
  'SPCFPctAumSignificantControversies',
  'SPCFPctAumHighControversies',
  'SPCFPctAumSevereControversies',
  'SPCFPctAumMod2NoControversies',
  'SPCFPctAumSev2SigControversies',
  'SPCFPortfolioSustScoreContribPctEnvRisk',
  'SPCFPortfolioSustScoreContribPctSocialRisk',
  'SPCFPortfolioSustScoreContribPctGovRisk',
  'SPCFPortfolioSustScoreContribPctUnallocatedESGRisk',
  'SPCFMorningstarGlobalCategoryName',
  'SPCFPercentofAUMCoveredESG',
  'SPCFSustainabilityRatingDate',
  'SPCFCarbonRiskClassification',
  'SPCFLowCarbonDesignation',
  'SPCFCarbonRiskScore',
  'SPCFCarbonRiskScorePercentRankinCategory',
  'SPCFAverage12MonthFossilFuelExposure',
  'SPCFPercentofAUMwithNegligibleCarbonRisk',
  'SPCFPercentofAUMwithLowCarbonRisk',
  'SPCFPercentofAUMwithMediumCarbonRisk',
  'SPCFPercentofAUMwithSevereCarbonRisk',
  'SPCFPercentofAUMwithHighCarbonRisk',
  'SPCFPctAUMNeg2MedCarbonRisk',
  'SPCFPctAUMHigh2SevCarbonRisk',
  'SPCFCarbonSolutionsInvolvement',
  'SPCFCarbonSolutionsCategoryAverage',
  'SPCFCarbonIntensity',
  'SPCFCarbonIntensityCategoryAverage',
  'SPCFPercentAUMCoveredCarbon',
  'SPCFScope1Emissions',
  'SPCFScope2Emissions'
]

export const FundCompareTableFieldDictionary =
  {
    'Morningstar Category Name': 'SPCFMorningstarCategoryName',
    'Morningstar Rating - Overall': 'SPCFMorningstarOverallRating',
    'Net Expense Ratio': 'SPCFProspectusNetExpenseRatio',
    'Product Type': 'SPCFFundType',
    'FundVest (Focus/Institutional)': 'SPCFNoTransactonFeeEligibilityIndicator',
    'FundVest ETF': 'SPCFIsFVETF',
    'Net Assets': 'SPCFFundNetAssets',
    'Share Class Net Assets': 'SPCFFundShareClassNetAssets',
    '12b-1 Fund': 'SPCFIndicator12b1',
    'Offshore Flex': 'SPCFOffshoreFlex',
    'Pershing Eligible (FundCenter) Mutual Funds': 'SPCFTradeStatus',
    'CUSIP': 'SPCFMStarOverrideCUSIP',
    'ISIN': 'SPCFISIN',
    'Fund Family': 'SPCFMStarOverrideFundFamilyName',
    'FundVest ETF Added Date': 'SPCFDateAdded',
    'Share Class': 'SPCFMStarOverrideFundShareClass',
    'FundServ': 'SPCFFundservIndicator',
    'Offshore Fund': 'SPCFOffShoreIndicator',
    'Offshore Trading Platform': 'SPCFOffShoreProcessingIndicator',
    'Load Type': 'SPCFMStarOverrideLoadType',
    'Closed to Sells': 'SPCFClosedToSellsIndicator',
    'Tender/Interval Fund': 'SPCFTenderIndicator',
    'Closed to Buy': 'SPCFClosedToBuysIndicator',
    'Distribution Reinvestment Available': 'SPCFDividendReinvestindicator',
    'Capital Gains Reinvestment Available': 'SPCFCapitalGainReinvestIndicator',
    'Dealer Agreement Required - Load Trades': 'SPCFDealerAgreementRequiredForLoadTrades',
    'Dealer Agreement Required - Load Transfers': 'SPCFDealerAgreementRequiredForLoadTransfers',
    'Dealer Agreement Required - No-Load Trades': 'SPCFDealerAgreementRequiredForNo_LoadTrades',
    'Dealer Agreement Required - No-Load Transfers': 'SPCFDealerAgreementRequiredForNo_LoadTransfers',
    'Bank Custody': 'SPCFBNYMellon',
    'Surcharge': 'SPCFSURCHARGE',
    'Closed To New Investors': 'SPCFMStarOverrideClosedToNewInvestors',
    'Broad Asset Class': 'SPCFFundAssetClassGroup',
    'Leveraged Fund': 'SPCFLeveragedFundFlag',
    'Benchmark Leverage Factor': 'SPCFLeveragedFundFactor',
    'Primary Underlying Benchmark': 'SPCFProspectusPrimaryBenchmarkName',
    'Gross Expense Ratio': 'SPCFGrossExpenseRatio',
    'Load Amount (Deferred)': 'SPCFSalesLoadDeferred',
    'Load Amount (Front-end)': 'SPCFSalesLoadMaxFrontLoad',
    'Max Management Fee': 'SPCFManagementFee',
    'Inception Date': 'SPCFInceptionDate',
    'Primary Portfolio Manager': 'SPCFManager',
    'Manager Tenure': 'SPCFManagerTenure',
    'Minimum Initial Investment': 'SPCFMStarOverrideMinimumInitialInvestment',
    'Minimum Susequent Investment': 'SPCFMStarOverrideSubsequentMinimumInvestment',
    'Minimum IRA Investment': 'SPCFMinInvIRA',
    'Last Close Price': 'SPCFMStarOverrideLastClosePrice',
    'Last Close Date': 'SPCFMstarOverrideLastClosePriceDate',
    'Last NAV': 'SPCFMStarOverrideLastNAV',
    'Last NAV Date': 'SPCFMstarOverrideLastNAVDate',
    'Last NAV Currency': 'SPCFCurrency',
    'Top 10 Weight in Portfolio': 'SPCFWeightingTop10Holdings',
    'Annual Turnover Ratio': 'SPCFYearlyTurnoverRatio',
    'Annual Turnover Ratio Date': 'SPCFTurnoverRatioDate',
    'Morningstar Equity Style Box': 'SPCFEquityStyleBoxText',
    'Morningstar Bond Style Box': 'SPCFFixedIncomeStyleBoxText',
    'Allocation - Total Bonds (Net)': 'SPCFTotalBondNet',
    'Allocation - US Bonds (Net)': 'SPCFUSBondNet',
    'Allocation - Non-US Bonds (Net)': 'SPCFNonUSBondNet',
    'Allocation - Total Stock (Net)': 'SPCFTotalStockNet',
    'Allocation - US Stocks (Net)': 'SPCFUSStockNet',
    'Allocation - Non-US Stocks (Net)': 'SPCFNonUSStockNet',
    'Allocation - Cash (Net)': 'SPCFCashNet',
    'Allocation - Convertible (Net)': 'SPCFConvertibleNet',
    'Allocation - Other (Net)': 'SPCFOtherNet',
    'Region Allocation - United States': 'SPCFRegionUnitedStates',
    'Region Allocation - Canada': 'SPCFRegionCanada',
    'Region Allocation - Europe': 'SPCFRegionEurope',
    'Region Allocation - Asia': 'SPCFRegionAsia',
    'Region Allocation - Other': 'SPCFRegionOther',
    'Stock Market Cap - Giant (Net)': 'SPCFStockMarketGiantCapNet',
    'Stock Market Cap - Large (Net)': 'SPCFStockMarketLargeCapNet',
    'Stock Market Cap - Micro (Net)': 'SPCFStockMarketMicroCapNet',
    'Stock Market Cap - Mid (Net)': 'SPCFStockMarketMidCapNet',
    'Stock Market Cap - Small (Net)': 'SPCFStockMarketSmallCapNet',
    'Stock Sector Allocation - Basic Materials (Net)': 'SPCFStockSectorBasicMaterials',
    'Stock Sector Allocation - Communication Services (Net)': 'SPCFStockSectorCommunicationServices',
    'Stock Sector Allocation - Cyclical (Net)': 'SPCFStockSectorConsumerCyclical',
    'Stock Sector Allocation - Consumer Defensive (Net)': 'SPCFStockSectorConsumerDefensive',
    'Stock Sector Allocation - Energy (Net)': 'SPCFStockSectorEnergy',
    'Stock Sector Allocation - Financial Services (Net)': 'SPCFStockSectorFinancialServices',
    'Stock Sector Allocation - Healthcare (Net)': 'SPCFStockSectorHealthcare',
    'Stock Sector Allocation - Industrials (Net)': 'SPCFStockSectorIndustrials',
    'Stock Sector Allocation - Real Estate (Net)': 'SPCFStockSectorRealEstate',
    'Stock Sector Allocation - Technology (Net)': 'SPCFStockSectorTechnology',
    'Stock Sector Allocation - Utilities (Net)': 'SPCFStockSectorUtilities',
    'Bond Sector Allocation - Cash & Equiv (Net)': 'SPCFBondSectorCashAllocationNet',
    'Bond Sector Allocation - Corporate (Net)': 'SPCFBondSectorCorporateAllocationNet',
    'Bond Sector Allocation - Derivative (Net)': 'SPCFBondSectorDerivativeAllocationNet',
    'Bond Sector Allocation - Government (Net)': 'SPCFBondSectorGovernmentAllocationNet',
    'Bond Sector Allocation - Municipal (Net)': 'SPCFBondSectorMunicipalAllocationNet',
    'Bond Sector Allocation - Securitized (Net)': 'SPCFBondSectorSecuritizedAllocationNet',
    'Credit Quality Allocation - AAA (Net)': 'SPCFCreditQualityAAA',
    'Credit Quality Allocation - AA (Net)': 'SPCFCreditQualityAA',
    'Credit Quality Allocation - A (Net)': 'SPCFCreditQualityA',
    'Credit Quality Allocation - BBB (Net)': 'SPCFCreditQualityBBB',
    'Credit Quality Allocation - BB (Net)': 'SPCFCreditQualityBB',
    'Credit Quality Allocation - B (Net)': 'SPCFCreditQualityB',
    'Credit Quality Allocation - Below B (Net)': 'SPCFCreditQualityBelowB',
    'Credit Quality Allocation - Not Rated (Net)': 'SPCFCreditQualityNotRated',
    'Portfolio Date': 'SPCFPortfolioDate',
    'Distribution Frequency': 'SPCFMStarOverrideDividendFrequency',
    'Trailing 1 Year Yield': 'SPCFTrailing1YrYield',
    'SEC Yield': 'SPCFSECYield',
    'NAV % Change Last': 'SPCFNAVChgPct',
    'NAV Change': 'SPCFNAVChgAmt',
    'Number of Positive Calendar Year Performance': 'SPCFCalendarYearPostivePerformance',
    'Number of Negative Calendar Year Performance': 'SPCFCalendarYearNegativePerformance',
    '1 Month Return': 'SPCFTrailingReturnM1',
    '3 Month Return': 'SPCFTrailingReturnM3',
    'YTD Return': 'SPCFTrailingReturnYTD',
    '1 Year Return': 'SPCFTrailingReturnY1',
    '3 Year Annualized Return': 'SPCFTrailingReturnY3',
    '5 Year Annualized Return': 'SPCFTrailingReturnY5',
    '10 Year Annualized Return': 'SPCFTrailingReturnY10',
    'Since Inception Annualized Return': 'SPCFTrailingReturnSinceInception',
    '1 Month Return ': 'SPCFTrailingMarketReturnM1',
    '3 Month Return ': 'SPCFTrailingMarketReturnM3',
    'YTD Return ': 'SPCFTrailingMarketReturnYTD',
    '1 Year Return ': 'SPCFTrailingMarketReturnY1',
    '3 Year Annualized Return ': 'SPCFTrailingMarketReturnY3',
    '5 Year Annualized Return ': 'SPCFTrailingMarketReturnY5',
    '10 Year Annualized Return ': 'SPCFTrailingMarketReturnY10',
    'Since Inception Annualized Return ': 'SPCFTrailingMarketReturnSinceInception',
    'Tax Cost Ratio 3 Year': 'SPCFTaxCostRatioY3',
    'Tax Cost Ratio 5 Year': 'SPCFTaxCostRatioY5',
    'Performance As Of Date': 'SPCFPerformanceAsOfDate',
    'Number of Holdings - Total': 'SPCFNumberofHoldingsNet',
    'Number of Holdings - Bonds': 'SPCFNumberOfBondHoldings',
    'Number of Holdings - Stocks': 'SPCFNumberOfStockHoldings',
    'Average Market Cap': 'SPCFAverageMarketCap',
    'Average Price to Earnings Value': 'SPCFEarningsYieldLongInv',
    'Average Price to Book Value': 'SPCFBookValueYieldLongInv',
    'Average ROA': 'SPCFROALong',
    'Average ROE': 'SPCFROELong',
    'Average Credit Quality': 'SPCFAverageCreditQualityLongLetter',
    'Average Credity Quality As Of Date': 'SPCFCreditQualityDate',
    'Average Coupon': 'SPCFAvgCoupon',
    'Average Effective Duration': 'SPCFAvgEffDuration',
    'Average Effective Maturity': 'SPCFAvgEffMaturity',
    'Average Yield To Maturity': 'SPCFYieldToMaturityLong',
    'Morningstar Medalist Rating': 'SPCFMedalistRating',
    'Morningstar Rating - Overall ': 'SPCFMorningstarOverallRating',
    'Morningstar Rating - 3 Year': 'SPCFMorningstar3YrRating',
    'Morningstar Rating - 5 Year': 'SPCFMorningstar5YrRating',
    'Morningstar Rating - 10 Year': 'SPCFMorningstar10YrRating',
    'Morningstar Performance Score - Overall': 'SPCFReturnScore',
    'Morningstar Risk Score - Overall': 'SPCFRiskScore',
    'Medalist Rating Date': 'SPCFMedalistRatingDate',
    'Morningstar Rating As of Date': 'SPCFMStarRatingAsOfDate',
    'Sharpe Ratio - 3 Year': 'SPCFSharpeRatioY3',
    'Standard Deviation - 3 Year': 'SPCFStandardDeviationY3',
    'Alpha *': 'SPCFPrimaryIndexAlphaY3',
    'Beta *': 'SPCFPrimaryIndexBetaY3',
    'Rsquared *': 'SPCFPrimaryIndexRSquaredY3',
    'Capture Ratio Up *': 'SPCFCaptureRatio3YearUp',
    'Capture Ratio Down *': 'SPCFCaptureRatio3YearDown',
    'Sharpe Ratio - 5 Year': 'SPCFSharpeRatioY5',
    'Standard Deviation - 5 Year': 'SPCFStandardDeviationY5',
    'Alpha*': 'SPCFPrimaryIndexAlphaY5',
    'Beta*': 'SPCFPrimaryIndexBetaY5',
    'Rsquared*': 'SPCFPrimaryIndexRSquaredY5',
    'Capture Ratio Up*': 'SPCFCaptureRatio5YearUp',
    'Capture Ratio Down*': 'SPCFCaptureRatio5YearDown',
    '* Morningstar Primary Benchmark': 'SPCFMorningstarPrimaryBenchmarkName',
    'On Balance Volume Indicator': 'SPCFOnBalanceVolume',
    'Volume - Last Trade Day': 'SPCFVolume',
    'Volume - 10 Day Average': 'SPCFAvg10DayVolume',
    'Volume - 90 Day Average': 'SPCFVolume90dAvg',
    'Percent Above 52 Week Low': 'SPCFPctChgVs52wkLo',
    'Percent Below 52 Week High': 'SPCFPctChgVs52wkHi',
    'Premium/Discount 1 Month Average': 'SPCFPremiumDiscountM1Average',
    'Premium/Discount 1 Year Average': 'SPCFPremiumDiscountY1Average',
    'Premium/Discount 1 Year High': 'SPCFPremiumDiscountY1High',
    'Premium/Discount 1 Year Low': 'SPCFPremiumDiscountY1Low',
    'Premium/Discount As Of Date': 'SPCFPremiumDiscountAsOfDate',
    'Sustainable Investment': 'SPCFSustainableInvestment',
    'Morningstar Sustainability Rating': 'SPCFSustainabilityRating',
    'Portfolio Corporate Sustainability Rating': 'SPCFPortCorpSustRating',
    'Portfolio Corporate Sustainability Score (12mo Average)': 'SPCFFundHistAvgSustScore',
    'Portfolio Sovereign Sustainability Rating': 'SPCFPortSovgSustRating',
    'Portfolio Sovereign Sustainability Score (12mo Average)': 'SPCFHistSovgSustScore',
    'Environmental Score': 'SPCFPortfolioEnvironmentalScore',
    'Governance Score': 'SPCFPortfolioGovernanceScore',
    'Social Score': 'SPCFPortfolioSocialScore',
    'Environmental Global Category Average Score': 'SPCFEnvironmentalGlobalCatAvg',
    'Governance Global Category Average Score': 'SPCFGovernanceGlobalCatAvg',
    'Social Global Category Average Score': 'SPCFSocialGlobalCatAvg',
    'Port Contribution to Sustainability Rating - Corporate %': 'SPCFPortSustRatingCorpContibPct',
    'Port Contribution to Sustainability Rating - Sov %': 'SPCFPortSustRatingSovgContribPct',
    'Negligible (% Of Corp AUM)': 'SPCFPctAumNegligibleESGRiskScores',
    'Low (% Of Corp AUM)': 'SPCFPctAumLowESGRiskScores',
    'Medium (% Of Corp AUM)': 'SPCFPctAumMediumESGRiskScores',
    'High (% Of Corp AUM)': 'SPCFPctAumHighESGRiskScores',
    'Severe (% Of Corp AUM)': 'SPCFPctAumSevereESGRiskScores',
    'Negligible to Medium (Sum) (% Of Corp AUM)': 'SPCFCorpESGNeg2Med',
    'High to Severe (Sum) (% Of Corp AUM)': 'SPCFCorpESGHigh2Sev',
    'Negligible (% Of Sov AUM)': 'SPCFSovgPctPortCoveredNegligibleESGRiskScore',
    'Low (% Of Sov AUM)': 'SPCFSovgPctPortCoveredLowESGRiskScore',
    'Medium (% Of Sov AUM)': 'SPCFSovgPctPortCoveredMediumESGRiskScore',
    'High (% Of Sov AUM)': 'SPCFSovgPctPortCoveredHighESGRiskScore',
    'Severe (% Of Sov AUM)': 'SPCFSovgPctPortCoveredSevereESGRiskScore',
    'Negligible to Medium (Sum) (% Of Sov AUM)': 'SPCFSovESGNeg2Med',
    'High to Severe (Sum) (% Of Sov AUM)': 'SPCFSovESGHigh2Sev',
    'None (% of Controversies AUM)': 'SPCFPctAumNoControversies',
    'Low (% of Controversies AUM)': 'SPCFPctAumLowControversies',
    'Moderate (% of Controversies AUM)': 'SPCFPctAumModerateControversies',
    'Significant (% of Controversies AUM)': 'SPCFPctAumSignificantControversies',
    'High (% of Controversies AUM)': 'SPCFPctAumHighControversies',
    'Severe (% of Controversies AUM)': 'SPCFPctAumSevereControversies',
    'None to Moderate (% of Controversies AUM)': 'SPCFPctAumMod2NoControversies',
    'Significant to Severe (% of Controversies AUM)': 'SPCFPctAumSev2SigControversies',
    '% Contribution to Risk Score - Environmental Risk': 'SPCFPortfolioSustScoreContribPctEnvRisk',
    '% Contribution to Risk Score - Social Risk': 'SPCFPortfolioSustScoreContribPctSocialRisk',
    '% Contribution to Risk Score - Governance Risk': 'SPCFPortfolioSustScoreContribPctGovRisk',
    '% Contribution to Risk Score - Unallocated Risk': 'SPCFPortfolioSustScoreContribPctUnallocatedESGRisk',
    'Global Category Name': 'SPCFMorningstarGlobalCategoryName',
    'Percent Of AUM Covered For ESG': 'SPCFPercentofAUMCoveredESG',
    'Sustainability Rating Date': 'SPCFSustainabilityRatingDate',
    'Carbon Risk Classification': 'SPCFCarbonRiskClassification',
    'Low Carbon Designation': 'SPCFLowCarbonDesignation',
    'Carbon Risk Score - Current': 'SPCFCarbonRiskScore',
    'Carbon Risk Score (Current) - Percentile Category Rank': 'SPCFCarbonRiskScorePercentRankinCategory',
    'Fossil Fuel Involvement (12Mo Avg, % AUM)': 'SPCFAverage12MonthFossilFuelExposure',
    'Negligible (% Of AUM Breakdown)': 'SPCFPercentofAUMwithNegligibleCarbonRisk',
    'Low (% Of AUM Breakdown)': 'SPCFPercentofAUMwithLowCarbonRisk',
    'Medium (% Of AUM Breakdown)': 'SPCFPercentofAUMwithMediumCarbonRisk',
    'High (% Of AUM Breakdown)': 'SPCFPercentofAUMwithHighCarbonRisk',
    'Severe (% Of AUM Breakdown)': 'SPCFPercentofAUMwithSevereCarbonRisk',
    'Negligible to Medium (% Of AUM Breakdown)': 'SPCFPctAUMNeg2MedCarbonRisk',
    'High to Severe (% Of AUM Breakdown)': 'SPCFPctAUMHigh2SevCarbonRisk',
    'Carbon Solutions Involvement (% AUM)': 'SPCFCarbonSolutionsInvolvement',
    'Carbon Solutions Involvement (Cat Average % AUM)': 'SPCFCarbonSolutionsCategoryAverage',
    'Carbon Intensity (metric tonne of Co2) / Revenue (Mil USD)': 'SPCFCarbonIntensity',
    'Carbon Intensity (Cat Avg)': 'SPCFCarbonIntensityCategoryAverage',
    'Percent AUM Covered Carbon': 'SPCFPercentAUMCoveredCarbon',
    'Scope 1 & 2 Emissions': 'SPCFScope1Emissions',
    'Scope 1, 2 & 3 Emissions': 'SPCFScope2Emissions'
  }

export const KeyMeauresTableFieldDictionary =
  [
    'Morningstar Category Name',
    'Morningstar Rating - Overall',
    'Net Expense Ratio',
    'Product Type',
    'FundVest (Focus/Institutional)',
    'FundVest ETF',
    'Net Assets',
    'Share Class Net Assets',
    '12b-1 Fund',
    'Offshore Flex',
    'Pershing Eligible (FundCenter) Mutual Funds',
    'CUSIP',
    'Fund Family',
    'FundVest ETF Added Date'
  ]

export const OperationalTableFieldDictionary =
  [
    'Share Class',
    'FundServ',
    'Offshore Fund',
    'Offshore Trading Platform',
    'Load Type',
    'Closed to Sells',
    'Tender/Interval Fund',
    'Closed to Buy',
    'Distribution Reinvestment Available',
    'Capital Gains Reinvestment Available',
    'Dealer Agreement Required - Load Trades',
    'Dealer Agreement Required - Load Transfers',
    'Dealer Agreement Required - No-Load Trades',
    'Dealer Agreement Required - No-Load Transfers',
    'Bank Custody',
    'Surcharge'
  ]

export const ProfileTableFieldDictionary =
  [
    'Closed To New Investors',
    'Broad Asset Class',
    'Leveraged Fund',
    'Benchmark Leverage Factor',
    'Primary Underlying Benchmark',
    'Gross Expense Ratio',
    'Load Amount (Deferred)',
    'Load Amount (Front-end)',
    'Max Management Fee',
    'Inception Date',
    'Primary Portfolio Manager',
    'Manager Tenure',
    'Minimum Initial Investment',
    'Minimum Susequent Investment',
    'Minimum IRA Investment',
    'Last Close Price',
    'Last Close Date',
    'Last NAV',
    'Last NAV Date',
    'Last NAV Currency'
  ]

export const AssetAllocationTableFieldDictionary =
  [
    'Top 10 Weight in Portfolio',
    'Annual Turnover Ratio',
    'Annual Turnover Ratio Date',
    'Morningstar Equity Style Box',
    'Morningstar Bond Style Box',
    {
      'Asset Class': [
        'Allocation - Total Bonds (Net)',
        'Allocation - US Bonds (Net)',
        'Allocation - Non-US Bonds (Net)',
        'Allocation - Total Stock (Net)',
        'Allocation - US Stocks (Net)',
        'Allocation - Non-US Stocks (Net)',
        'Allocation - Cash (Net)',
        'Allocation - Convertible (Net)',
        'Allocation - Other (Net)'
      ]
    },
    {
      'Geographic': [
        'Region Allocation - United States',
        'Region Allocation - Canada',
        'Region Allocation - Europe',
        'Region Allocation - Asia',
        'Region Allocation - Other'
      ]
    },
    {
      'Equity': [
        'Stock Market Cap - Giant (Net)',
        'Stock Market Cap - Large (Net)',
        'Stock Market Cap - Micro (Net)',
        'Stock Market Cap - Mid (Net)',
        'Stock Market Cap - Small (Net)',
        'Stock Sector Allocation - Basic Materials (Net)',
        'Stock Sector Allocation - Communication Services (Net)',
        'Stock Sector Allocation - Cyclical (Net)',
        'Stock Sector Allocation - Consumer Defensive (Net)',
        'Stock Sector Allocation - Energy (Net)',
        'Stock Sector Allocation - Financial Services (Net)',
        'Stock Sector Allocation - Healthcare (Net)',
        'Stock Sector Allocation - Industrials (Net)',
        'Stock Sector Allocation - Real Estate (Net)',
        'Stock Sector Allocation - Technology (Net)',
        'Stock Sector Allocation - Utilities (Net)'
      ]
    },
    {
      'Fixed Income': [
        'Bond Sector Allocation - Cash & Equiv (Net)',
        'Bond Sector Allocation - Corporate (Net)',
        'Bond Sector Allocation - Derivative (Net)',
        'Bond Sector Allocation - Government (Net)',
        'Bond Sector Allocation - Municipal (Net)',
        'Bond Sector Allocation - Securitized (Net)',
        'Credit Quality Allocation - AAA (Net)',
        'Credit Quality Allocation - AA (Net)',
        'Credit Quality Allocation - A (Net)',
        'Credit Quality Allocation - BBB (Net)',
        'Credit Quality Allocation - BB (Net)',
        'Credit Quality Allocation - B (Net)',
        'Credit Quality Allocation - Below B (Net)',
        'Credit Quality Allocation - Not Rated (Net)'
      ]
    },
    'Portfolio Date'
  ]

export const IncomeTableFieldDictionary =
  [
    'Distribution Frequency',
    'Trailing 1 Year Yield',
    'SEC Yield'
  ]

export const PerformanceTableFieldDictionary =
  [
    'NAV % Change Last',
    'NAV Change',
    'Number of Positive Calendar Year Performance',
    'Number of Negative Calendar Year Performance',
    {
      'NAV Return': [
        '1 Month Return',
        '3 Month Return',
        'YTD Return',
        '1 Year Return',
        '3 Year Annualized Return',
        '5 Year Annualized Return',
        '10 Year Annualized Return',
        'Since Inception Annualized Return'
      ]
    },
    {
      'Market Return': [
        '1 Month Return ',
        '3 Month Return ',
        'YTD Return ',
        '1 Year Return ',
        '3 Year Annualized Return ',
        '5 Year Annualized Return ',
        '10 Year Annualized Return ',
        'Since Inception Annualized Return '
      ]
    },
    'Tax Cost Ratio 3 Year',
    'Tax Cost Ratio 5 Year',
    'Performance As Of Date',
    'Inception Date'
  ]

export const PortfolioCharacteristicsTableFieldDictionary =
  [
    'Number of Holdings - Total',
    'Number of Holdings - Bonds',
    'Number of Holdings - Stocks',
    {
      'Equity': [
        'Average Market Cap',
        'Average Price to Earnings Value',
        'Average Price to Book Value',
        'Average ROA',
        'Average ROE'
      ]
    },
    {
      'Fixed Income': [
        'Average Credit Quality',
        'Average Credity Quality As Of Date',
        'Average Coupon',
        'Average Effective Duration',
        'Average Effective Maturity',
        'Average Yield To Maturity'
      ]
    },
    'Portfolio Date'
  ]

export const RiskTableFieldDictionary =
  [
    {
      '3 Year Risk': [
        'Sharpe Ratio - 3 Year',
        'Standard Deviation - 3 Year',
        'Alpha *',
        'Beta *',
        'Rsquared*',
        'Capture Ratio Up *',
        'Capture Ratio Down *'
      ]
    },
    {
      '5 Year Risk': [
        'Sharpe Ratio - 5 Year',
        'Standard Deviation - 5 Year',
        'Alpha *',
        'Beta *',
        'Rsquared *',
        'Capture Ratio Up *',
        'Capture Ratio Down *'
      ]
    },
    '* Morningstar Primary Benchmark',
    'Performance As Of Date'
  ]

export const RatingsTableFieldDictionary =
  [
    'Morningstar Medalist Rating',
    'Morningstar Rating - Overall',
    'Morningstar Rating - 3 Year',
    'Morningstar Rating - 5 Year',
    'Morningstar Rating - 10 Year',
    'Morningstar Performance Score - Overall',
    'Morningstar Risk Score - Overall',
    'Medalist Rating Date',
    'Morningstar Rating As of Date'
  ]

export const TechnicalIndicatorsTableFieldDictionary =
  [
    'On Balance Volume Indicator',
    'Volume - Last Trade Day',
    'Volume - 10 Day Average',
    'Volume - 90 Day Average',
    'Percent Above 52 Week Low',
    'Percent Below 52 Week High',
    'Premium/Discount 1 Month Average',
    'Premium/Discount 1 Year Average',
    'Premium/Discount 1 Year High',
    'Premium/Discount 1 Year Low',
    'Premium/Discount As Of Date'
  ]

export const ESGRatingTableFieldDictionary =
  [
    'Sustainable Investment',
    'Morningstar Sustainability Rating',
    'Portfolio Corporate Sustainability Rating',
    'Portfolio Corporate Sustainability Score (12mo Average)',
    'Portfolio Sovereign Sustainability Rating',
    'Portfolio Sovereign Sustainability Score (12mo Average)',
    'Environmental Score',
    'Governance Score',
    'Social Score',
    'Environmental Global Category Average Score',
    'Governance Global Category Average Score',
    'Social Global Category Average Score',
    'Port Contribution to Sustainability Rating - Corporate %',
    'Port Contribution to Sustainability Rating - Sov %',
    {
      'Corporate ESG Risk Score (% Of Corp AUM)': [
        'Negligible (% Of Corp AUM)',
        'Low (% Of Corp AUM)',
        'Medium (% Of Corp AUM)',
        'High (% Of Corp AUM)',
        'Severe (% Of Corp AUM)',
        'Negligible to Medium (Sum) (% Of Corp AUM)',
        'High to Severe (Sum) (% Of Corp AUM)'
      ]
    },
    {
      'Sovereign ESG Risk Score (% Of Sov AUM)': [
        'Negligible (% Of Sov AUM)',
        'Low (% Of Sov AUM)',
        'Medium (% Of Sov AUM)',
        'High (% Of Sov AUM)',
        'Severe (% Of Sov AUM)',
        'Negligible to Medium (Sum) (% Of Sov AUM)',
        'High to Severe (Sum) (% Of Sov AUM)'
      ]
    },
    {
      'Controversies (% of AUM)': [
        'None (% of Controversies AUM)',
        'Low (% of Controversies AUM)',
        'Moderate (% of Controversies AUM)',
        'Significant (% of Controversies AUM)',
        'High (% of Controversies AUM)',
        'Severe (% of Controversies AUM)',
        'None to Moderate (% of Controversies AUM)',
        'Significant to Severe (% of Controversies AUM)'
      ]
    },
    '% Contribution to Risk Score - Environmental Risk',
    '% Contribution to Risk Score - Social Risk',
    '% Contribution to Risk Score - Governance Risk',
    '% Contribution to Risk Score - Unallocated Risk',
    'Global Category Name',
    'Percent Of AUM Covered For ESG',
    'Sustainability Rating Date'
  ]
export const ESGCarbonMetricsTableFieldDictionary = [
  'Carbon Risk Classification',
  'Low Carbon Designation',
  'Carbon Risk Score - Current',
  'Carbon Risk Score (Current) - Percentile Category Rank',
  'Fossil Fuel Involvement (12Mo Avg, % AUM)',
  {'Carbon Risk % AUM Breakdown': [
    'Negligible (% Of AUM Breakdown)',
    'Low (% Of AUM Breakdown)',
    'Medium (% Of AUM Breakdown)',
    'High (% Of AUM Breakdown)',
    'Severe (% Of AUM Breakdown)',
    'Negligible to Medium (% Of AUM Breakdown)',
    'High to Severe (% Of AUM Breakdown)'
  ]
  },
  'Carbon Solutions Involvement (% AUM)',
  'Carbon Solutions Involvement (Cat Average % AUM)',
  'Carbon Intensity (metric tonne of Co2) / Revenue (Mil USD)',
  'Carbon Intensity (Cat Avg)',
  'Percent AUM Covered Carbon',
  'Scope 1 & 2 Emissions',
  'Scope 1, 2 & 3 Emissions'
]
export const FundCompareMillionColumnFormat = ['SPCFFundNetAssets', 'SPCFAverageMarketCap', 'SPCFFundShareClassNetAssets', 'SPCFVolume', 'SPCFAvg10DayVolume', 'SPCFVolume90dAvg']

export const FundCompareDateColumnFormat = ['SPCFInceptionDate', 'SPCFMstarOverrideLastClosePriceDate', 'SPCFMstarOverrideLastNAVDate', 'SPCFTurnoverRatioDate', 'SPCFPortfolioDate', 'SPCFPerformanceAsOfDate', 'SPCFCreditQualityDate',
  'SPCFPremiumDiscountAsOfDate', 'SPCFMedalistRatingDate', 'SPCFMStarRatingAsOfDate']

export const FundCompareNonMSDateColumnFormat = ['SPCFSustainabilityRatingDate']

export const FundNumberColumnFormat = ['SPCFLeveragedFundFactor', 'SPCFManagementFee', 'SPCFManagerTenure', 'SPCFMStarOverrideLastNAV', 'SPCFMorningstarOverallRating', 'SPCFMorningstar3YrRating', 'SPCFMorningstar5YrRating', 'SPCFMorningstar10YrRating',
  'SPCFSharpeRatioY3', 'SPCFSharpeRatioY5', 'SPCFMStarOverrideMinimumInitialInvestment', 'SPCFMStarOverrideSubsequentMinimumInvestment', 'SPCFMinInvIRA', 'SPCFFundHistAvgSustScore', 'SPCFHistSovgSustScore',
  'SPCFHistSovgSustScore', 'SPCFPortfolioEnvironmentalScore', 'SPCFPortfolioGovernanceScore', 'SPCFPortfolioSocialScore', 'SPCFEnvironmentalGlobalCatAvg', 'SPCFGovernanceGlobalCatAvg', 'SPCFSocialGlobalCatAvg', 'SPCFPercentAUMCoveredCarbon'
]

export const FundCompareNumberFormatterField = ['SPCFPctChgVs52wkLo', 'SPCFPctChgVs52wkHi',
  'SPCFPremiumDiscountM1Average', 'SPCFPremiumDiscountY1Average', 'SPCFPremiumDiscountY1High', 'SPCFPremiumDiscountY1Low', 'SPCFEarningsYieldLongInv', 'SPCFBookValueYieldLongInv', 'SPCFROALong', 'SPCFROELong', 'SPCFAvgCoupon', 'SPCFAvgEffDuration', 'SPCFAvgEffMaturity',
  'SPCFSalesLoadDeferred', 'SPCFSalesLoadMaxFrontLoad', 'SPCFYieldToMaturityLong']

export const FundCompareNumberColumnPrecisionFormat = ['SPCFCalendarYearPostivePerformance', 'SPCFCalendarYearNegativePerformance']

export const FundCompareDollarColumnFormat = ['SPCFNAVChgAmt']
export const FundCompareColumnSpecialHandling = ['SPCFMStarOverrideLoadType', 'SPCFOffShoreProcessingIndicator', 'SPCFOffShoreIndicator']
export const FundComparePercentColumnFormat = ['SPCFWeightingTop10Holdings', 'SPCFYearlyTurnoverRatio', 'SPCFTotalBondNet', 'SPCFUSBondNet', 'SPCFNonUSBondNet', 'SPCFTotalStockNet', 'SPCFUSStockNet', 'SPCFNonUSStockNet', 'SPCFCashNet', 'SPCFConvertibleNet', 'SPCFOtherNet', 'SPCFRegionUnitedStates', 'SPCFRegionCanada', 'SPCFRegionEurope', 'SPCFRegionAsia', 'SPCFRegionOther',
  'SPCFStockMarketGiantCapNet', 'SPCFStockMarketLargeCapNet', 'SPCFStockMarketMicroCapNet', 'SPCFStockMarketMidCapNet', 'SPCFStockMarketSmallCapNet',
  'SPCFStockSectorBasicMaterials', 'SPCFStockSectorCommunicationServices', 'SPCFStockSectorConsumerCyclical', 'SPCFStockSectorConsumerDefensive',
  'SPCFStockSectorEnergy', 'SPCFStockSectorFinancialServices', 'SPCFStockSectorHealthcare', 'SPCFStockSectorIndustrials', 'SPCFStockSectorRealEstate',
  'SPCFStockSectorTechnology', 'SPCFStockSectorUtilities', 'SPCFBondSectorCashAllocationNet', 'SPCFBondSectorCorporateAllocationNet', 'SPCFBondSectorDerivativeAllocationNet',
  'SPCFBondSectorGovernmentAllocationNet', 'SPCFBondSectorMunicipalAllocationNet', 'SPCFBondSectorSecuritizedAllocationNet', 'SPCFCreditQualityAAA',
  'SPCFCreditQualityAA', 'SPCFCreditQualityA', 'SPCFCreditQualityBBB', 'SPCFCreditQualityBB', 'SPCFCreditQualityB', 'SPCFCreditQualityBelowB', 'SPCFCreditQualityNotRated', 'SPCFTrailing1YrYield', 'SPCFSECYield', 'SPCFNAVChgPct',
  'SPCFStandardDeviationY3', 'SPCFPrimaryIndexAlphaY3', 'SPCFPrimaryIndexBetaY3', 'SPCFPrimaryIndexRSquaredY3', 'SPCFCaptureRatio3YearUp', 'SPCFCaptureRatio3YearDown', 'SPCFStandardDeviationY5', 'SPCFPrimaryIndexAlphaY5', 'SPCFPrimaryIndexBetaY5', 'SPCFPrimaryIndexRSquaredY5', 'SPCFCaptureRatio5YearUp',
  'SPCFCaptureRatio5YearDown', 'SPCFGrossExpenseRatio', 'SPCFProspectusNetExpenseRatio', 'SPCFPortSustRatingCorpContibPct', 'SPCFPortSustRatingSovgContribPct', 'SPCFPctAumNegligibleESGRiskScores',
  'SPCFPctAumLowESGRiskScores', 'SPCFPctAumMediumESGRiskScores', 'SPCFPctAumHighESGRiskScores', 'SPCFPctAumSevereESGRiskScores', 'SPCFCorpESGNeg2Med', 'SPCFCorpESGHigh2Sev', 'SPCFSovgPctPortCoveredNegligibleESGRiskScore',
  'SPCFSovgPctPortCoveredLowESGRiskScore', 'SPCFSovgPctPortCoveredMediumESGRiskScore', 'SPCFSovgPctPortCoveredHighESGRiskScore', 'SPCFSovgPctPortCoveredSevereESGRiskScore', 'SPCFSovESGNeg2Med',
  'SPCFSovESGHigh2Sev', 'SPCFPctAumNoControversies', 'SPCFPctAumLowControversies', 'SPCFPctAumModerateControversies', 'SPCFPctAumSignificantControversies', 'SPCFPctAumHighControversies',
  'SPCFPctAumSevereControversies', 'SPCFPctAumMod2NoControversies', 'SPCFPctAumSev2SigControversies', 'SPCFPortfolioSustScoreContribPctEnvRisk', 'SPCFPortfolioSustScoreContribPctSocialRisk',
  'SPCFPortfolioSustScoreContribPctGovRisk', 'SPCFPortfolioSustScoreContribPctUnallocatedESGRisk', 'SPCFPercentofAUMCoveredESG', 'SPCFAverage12MonthFossilFuelExposure', 'SPCFPercentofAUMwithNegligibleCarbonRisk', 'SPCFPercentofAUMwithLowCarbonRisk', 'SPCFPercentofAUMwithMediumCarbonRisk', 'SPCFPercentofAUMwithSevereCarbonRisk', 'SPCFPercentofAUMwithHighCarbonRisk', 'SPCFPctAUMNeg2MedCarbonRisk', 'SPCFPctAUMHigh2SevCarbonRisk', 'SPCFCarbonSolutionsInvolvement', 'SPCFCarbonSolutionsCategoryAverage']

export const FundComparePreccisionPercentColumnFormat = ['SPCFTrailingReturnM1', 'SPCFTrailingReturnM3', 'SPCFTrailingReturnYTD',
  'SPCFTrailingReturnY1', 'SPCFTrailingReturnY3', 'SPCFTrailingReturnY5', 'SPCFTrailingReturnY10', 'SPCFTrailingReturnSinceInception', 'SPCFTrailingMarketReturnM1', 'SPCFTrailingMarketReturnM3', 'SPCFTrailingMarketReturnYTD', 'SPCFTrailingMarketReturnY1',
  'SPCFTrailingMarketReturnY3', 'SPCFTrailingMarketReturnY5', 'SPCFTrailingMarketReturnY10', 'SPCFTrailingMarketReturnSinceInception', 'SPCFTaxCostRatioY3', 'SPCFTaxCostRatioY5']

export const FundCompareBooleanColumnFormat = ['SPCFIsFVETF', 'SPCFIndicator12b1', 'SPCFFundservIndicator', 'SPCFClosedToSellsIndicator', 'SPCFTenderIndicator', 'SPCFDealerAgreementRequiredForLoadTrades', 'SPCFDealerAgreementRequiredForLoadTransfers',
  'SPCFDealerAgreementRequiredForNo_LoadTrades', 'SPCFDealerAgreementRequiredForNo_LoadTransfers', 'SPCFSURCHARGE', 'SPCFLeveragedFundFlag', 'SPCFClosedToBuysIndicator', 'SPCFDividendReinvestindicator', 'SPCFCapitalGainReinvestIndicator',
  'SPCFMStarOverrideClosedToNewInvestors']

export const FundCompareBankCustodyColumnFormat = ['SPCFBNYMellon']

export const FundCompareFreqColumnFormat = ['SPCFMStarOverrideDividendFrequency']

export const FundCompareIndicatorColumnFormat = ['SPCFNoTransactonFeeEligibilityIndicator']

export const FundComparePerformanceTabNegativeCell = [
  '1MonthReturn',
  '3MonthReturn',
  'YTDReturn',
  '1YearReturn',
  '3YearAnnualizedReturn',
  '5YearAnnualizedReturn',
  '10YearAnnualizedReturn',
  'SinceInceptionAnnualizedReturn',
  'TaxCostRatio3Year',
  'TaxCostRatio5Year'
]
export const AICustomItems = ['PERAIDepositoryID', 'PERAIProductFeeIndicator', 'PERAIMinorProductCode', 'PERAISecRegistered', 'PERAIUnitTradedData']
const AICustomDict = {
  'LPPP': 'Fee',
  'PASS': 'Fee',
  'BANC': 'Fee',
  'NTFP': 'No Fee',
  '040': 'Limited Partnerships',
  '042': 'Hedge Fund',
  '043': 'Exchange Fund',
  '044': 'Private Equity',
  '045': 'Real Estate',
  '046': 'Collateralized Debt Obligations',
  '047': 'Group Annuity Contract',
  '048': 'Fund of Funds',
  '049': 'Managed Futures',
  '04A': 'Private Debt',
  '04B': 'Managed Debt',
  '166': 'Non-Traded REIT',
  '167': 'Business Development Company',
  'CUSTDTC': 'Non-Retirement',
  'AccountNull': 'Retirement',
  'Y': 'Yes',
  'N': 'No',
  'FundStrucY': 'Unitized',
  'FundStrucN': 'Capital Balance'
}
export const AICustomText = (fieldname, value) => {
  switch (fieldname) {
    case 'PERAIDepositoryID':
      if (value === null) { return AICustomDict['AccountNull'] }
      if (value === 'CUSTDTC') { return AICustomDict['CUSTDTC'] } else return DEFAULT_VALUE
    case 'PERAIUnitTradedData':
      switch (value) {
        case 'Y':
          return AICustomDict['FundStrucY']
        case 'N':
          return AICustomDict['FundStrucN']
        default:
          return DEFAULT_VALUE
      }
    case 'PERAISecRegistered':
      switch (value) {
        case 'Y':
        case 'N':
          return AICustomDict[value]
        default:
          return AICustomDict['N']
      }
    case 'PERAIFundStructure':
        return value || DEFAULT_VALUE
    default:
      let x = AICustomDict[value]
      if (!x) return DEFAULT_VALUE
      else return x
  }
}

export const QuoteCardScreenerResultField = [
  'SPCFFundType', 'SPCFMStarOverrideCUSIP', 'SPCFFundNetAssets', 'SPCFIsFVETF', 'SPCFNoTransactonFeeEligibilityIndicator', 'SPCFticker', 'SPCFMStarOverrideFundName', 'SPCFOffshoreFlex', 'SPCFTrailingReturnY5', 'SPCFPerformanceAsOfDate']

export const EtfSnapshotRating = ['SPCFMedalistRating', 'SPCFMedalistRatingDate', 'SPCFMedalistRatingAnalystDrivenPct', 'SPCFMedalistRatingDataAvailabilityPct']

export const FundCompareWithoutMsDateColumnFormat = ['SPCFDateAdded']

export const FundCompareWithYesNoColumnFormat = ['SPCFTradeStatus']

export const DEFAULT_FUNDSCOMPARE_INPUTS = {
  argsOperator: 'AND',
  arguments: [],
  offset: 0,
  limit: 20,
  sortArguments: [
    {
      direction: 'A',
      field: 'SPCFWSODIssue'
    }
  ],
  resultFields: FundCompareResultColumnArr
}

export const MFSnapshotResultColumnArr = ['closedToBuysIndicator', 'closedToSellsIndicator', 'newInvestmentClosedIndicator', 'fundservIndicator', 'offShoreIndicator', 'dividendReinvestIndicator', 'capitalGainReinvestIndicator', 'bnyMellon', 'surcharge', 'SPCFGrossExpenseRatio', 'indicator12B1']

export const MARKETS_PERFORMANCE = {
  INDEXESNAME: {
    NYSE: 'NYSE',
    NASDAQ: 'NASDAQ',
    AMEX: 'AMEX'
  },
  INDEXES: {
    'NYSE': {
      'wsodIssue': 566677,
      'advancingIssue': 10025197,
      'decliningIssue': 10025198,
      'UnchangedIssue': 10544868,
      'advancingVolumeIssue': 10400806,
      'decliningVolumeIssue': 10544867,
      'unchangedVolumeIssue': 10544869,
      'symbol': 'NYSE',
      'darkColor': '#293DC0',
      'lightColor': 'rgba(41,61,192,0.25)',
      'borderColor': '#2938C8'
    },
    'NASDAQ': {
      'wsodIssue': 579435,
      'advancingIssue': 10025200,
      'decliningIssue': 10025201,
      'UnchangedIssue': 10546650,
      'advancingVolumeIssue': 10546648,
      'decliningVolumeIssue': 10546649,
      'unchangedVolumeIssue': 10546651,
      'symbol': 'NASDAQ',
      'darkColor': '#2FAEFF',
      'lightColor': 'rgba(47,174,255,0.20)',
      'borderColor': '#00AFFF'
    },
    'AMEX': {
      'wsodIssue': 583358,
      'advancingIssue': 7780680,
      'decliningIssue': 7780688,
      'UnchangedIssue': 10545163,
      'advancingVolumeIssue': 10545161,
      'decliningVolumeIssue': 10545162,
      'unchangedVolumeIssue': 10545164,
      'symbol': 'AMEX',
      'darkColor': '#FC7802',
      'lightColor': 'rgba(252,120,2,0.25)',
      'borderColor': '#FC7802'
    }
  },
  'dropDownValues': {
    PERFORMANCE: 'Performance',
    ADVANCERSDECLINERS: 'Advancers/Decliners'
  },
  PERFORMANCECHART: {
    DAYS: 1,
    DATAINTERVAL: 30,
    STYLE: {
      LABELFONTSIZE: 12,
      LABELFONTCOLOR: '#000000',
      LABELFONTFAMILY: 'Roboto-Regular',
      BORDERBOTTOMCOLOR: '#D9D9D9',
      GRIDHORIZONTALCOLOR: '#D9D9D9'
    },
    MARKERTYPE: 'lineWithShapes'
  }
}
export const CHART_EVENTS = [
  { id: 0, text: 'Dividends', checked: false, value: 'Dividends' },
  { id: 1, text: 'Splits', checked: false, value: 'Splits' },
  { id: 2, text: 'Earnings', checked: false, value: 'Earnings' }
]

export const STOCKS_PERFORMANCE = {
  INDEXES: {
    'darkColor': '#004480 ',
    'lightColor': 'rgb(191, 208, 223, 0.4)', // 'rgba(41,61,192,0.25)',
    'borderColor': '#2938C8'
  },
  'chartTypes': {
    LINECHART: 'chart-line',
    CANDLESTICKCHART: 'chart-candlestick',
    Fill: 'chart-area'
  },
  'dropDownDaysValues': {
    '1 Day': 1,
    '5 Days': 5,
    '1 Month': 30,
    '3 Months': 90,
    '1 Year': 365,
    '5 Years': 1825,
    '10 Years': 3650,
    'Max': 10000
  },
  'intervalMapping': {
    1: '1',
    5: '1',
    30: '1',
    90: '1',
    365: '1',
    1825: '1',
    3650: '1',
    10000: '1'
  },
  'periodMapping': {
    1: 'Hour',
    5: 'Hour',
    30: 'Week',
    90: 'Week',
    365: 'Month',
    1825: 'Year',
    3650: 'Year',
    10000: 'Year'
  },
  PERFORMANCECHART: {
    DATAINTERVAL: 30,
    STYLE: {
      LABELFONTSIZE: 12,
      LABELFONTCOLOR: '#000000',
      LABELFONTFAMILY: 'Roboto-Regular',
      BORDERBOTTOMCOLOR: 'rgb(0, 0, 0, .3)',
      GRIDHORIZONTALCOLOR: 'rgb(0, 0, 0, .15)'
    },
    MARKERTYPE: 'lineWithShapes'
  },
  TIME_PERIOD: {
    '1D': 1,
    '5D': 5,
    '1M': 30,
    '3M': 90,
    '1Y': 365,
    '5Y': 1825,
    '10Y': 3650,
    'Max': 10000
  }
}
export const FUNDS_PERFORMANCE = {
  INDEXES: {
    'darkColor': '#004480 ',
    'lightColor': 'rgb(191, 208, 223, 0.4)', // 'rgba(41,61,192,0.25)',
    'borderColor': '#2938C8'
  },
  'chartTypes': {
    LINECHART: 'chart-line',
    CANDLESTICKCHART: 'chart-candlestick',
    Fill: 'chart-area'
  },
  'dropDownDaysValues': {
    '1 Day': 1,
    '5 Days': 5,
    '7 Days': 7,
    '1 Month': 30,
    '3 Months': 90,
    '1 Year': 365,
    '5 Years': 1825,
    '10 Years': 3650,
    'Max': 10000
  },
  'intervalMapping': {
    1: '1',
    5: '1',
    7: '1',
    30: '1',
    90: '1',
    365: '1',
    1825: '1',
    3650: '1',
    10000: '1'
  },
  'periodMapping': {
    1: 'Hour',
    5: 'Hour',
    7: 'Day',
    30: 'Day',
    90: 'Day',
    365: 'Day',
    1825: 'Day',
    3650: 'Day', // To-DO - change to Month after calisto fix
    10000: 'Day'// To-DO - change to Month after calisto fix
  },
  PERFORMANCECHART: {
    DATAINTERVAL: 30,
    STYLE: {
      LABELFONTSIZE: 12,
      LABELFONTCOLOR: '#000000',
      LABELFONTFAMILY: 'Roboto-Regular',
      BORDERBOTTOMCOLOR: 'rgb(0, 0, 0, .3)',
      GRIDHORIZONTALCOLOR: 'rgb(0, 0, 0, .15)'
    },
    MARKERTYPE: 'lineWithShapes'
  },
  TIME_PERIOD: {
    '1D': 1,
    '5D': 5,
    '1W': 7,
    '1M': 30,
    '3M': 90,
    '1Y': 365,
    '5Y': 1825,
    '10Y': 3650,
    'Max': 10000
  }
}

export const ETF_STOCKS_PERFORMANCE = {
  INDEXES: {
    'darkColor': '#004480 ',
    'lightColor': 'rgb(191, 208, 223, 0.4)', // 'rgba(41,61,192,0.25)',
    'borderColor': '#2938C8'
  },
  'chartTypes': {
    LINECHART: 'chart-line',
    CANDLESTICKCHART: 'chart-candlestick',
    Fill: 'chart-area'
  },
  'dropDownDaysValues': {
    '1 Day': 1,
    '5 Days': 5,
    '1 Month': 30,
    '3 Months': 90,
    '1 Year': 365,
    '5 Years': 1825,
    '10 Years': 3650
  },
  'intervalMapping': {
    1: '1',
    5: '1',
    30: '1',
    90: '1',
    365: '1',
    1825: '1',
    3650: '1'
  },
  'periodMapping': {
    1: 'Hour',
    5: 'Hour',
    30: 'Week',
    90: 'Week',
    365: 'Month',
    1825: 'Year',
    3650: 'Year'
  },
  PERFORMANCECHART: {
    DATAINTERVAL: 30,
    STYLE: {
      LABELFONTSIZE: 12,
      LABELFONTCOLOR: '#000000',
      LABELFONTFAMILY: 'Roboto-Regular',
      BORDERBOTTOMCOLOR: 'rgb(0, 0, 0, .3)',
      GRIDHORIZONTALCOLOR: 'rgb(0, 0, 0, .15)'
    },
    MARKERTYPE: 'lineWithShapes'
  },
  TIME_PERIOD: {
    '1D': 1,
    '5D': 5,
    '1M': 30,
    '3M': 90,
    '1Y': 365,
    '5Y': 1825,
    '10Y': 3650
  }
}
export const DATE_FORMAT = {
  ...PARENT_DATE_FORMAT,
  MONTH_DAY_YEAR_WITHCOMMA: 'MMM DD, YYYY'
}

export const COLOR_DEFAULT_SET = [
  {key: 'red', value: '#BF360C'},
  {key: 'blue', value: '#004480'},
  {key: 'green', value: '#00B3A1'},
  {key: 'green1', value: '#00AC00'},
  {key: 'purple', value: '#9085FF'},
  {key: 'black', value: '#093055'},
  {key: 'blue2', value: '#00A0D4'},
  {key: 'orange', value: '#D97E07'},
  {key: 'lightblue', value: '#0095FF'},
  {key: 'pink', value: '#FF573F'}
]

export const COLOR_CHARTIST_SET = {
  DARK_BLUE: '#004480',
  LIGHT_BLUE: '#0095FF',
  ORANGE: '#D97E07',
  GREEN: '#00A695',
  PURPLE: '#AA01AA'
}

export const ERROR_MESSAGE = 'There is no data available to display.'

export const DEFAULT_VALUE = '--'

export const DEFAULT_SCREEN_ID = '290'
export const FUND_SCREEN_ID = '450'
export const MODEL_SCREEN_TYPE = 'MODEL'
export const FUND_SCREEN_TYPE = 'MF_ETF'
export const AI_SCREEN_TYPE = 'AI'
export const AI_SCREEN_ID = '448'
export const EQUITY_SCREEN_ID = '452'
export const EQUITY_SCREEN_TYPE = 'EQUITIES'
export const SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_API = 10000
export const SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_ALL_FUNDS = 1000
export const SCREENER_RESULT_THRESHOLD_LIMIT_PLATFORM_ALL = 3000

export const FundScreenerTableFieldDictionary =
  {'Ticker': 'SPCFticker',
    'Name': 'SPCFMStarOverrideFundName',
    'Morningstar Category Name': 'SPCFMorningstarCategoryName',
    'Morningstar Rating - Overall': 'SPCFMorningstarOverallRating',
    'Net Expense Ratio (%)': 'SPCFProspectusNetExpenseRatio',
    'Product Type': 'SPCFFundType',
    'FundVest (Focus/Institutional)': 'SPCFNoTransactonFeeEligibilityIndicator',
    'FundVest ETF': 'SPCFIsFVETF',
    'FundVest ETF Added Date': 'SPCFDateAdded',
    'Net Assets': 'SPCFFundNetAssets',
    'NAV Return (3Yr %)': 'SPCFTrailingReturnY3',
    'Tax Cost Ratio (3Yr %)': 'SPCFTaxCostRatioY3',
    '12b-1 Fund': 'SPCFIndicator12b1',
    'Offshore Flex': 'SPCFNoTransactonFeeEligibilityIndicator',
    'Pershing Eligible (FundCenter) Mutual Funds': 'SPCFTradeStatus',
    'CUSIP': 'SPCFMStarOverrideCUSIP',
    'ISIN': 'SPCFISIN',
    'Fund Family': 'SPCFMStarOverrideFundFamilyName',
    'Share Class': 'SPCFMStarOverrideFundShareClass',
    'FundServ': 'SPCFFundservIndicator',
    'Offshore Fund': 'SPCFOffShoreIndicator',
    'Offshore Trading Platform': 'SPCFOffShoreProcessingIndicator',
    'Load Type': 'SPCFMStarOverrideLoadType',
    'Closed To New Investors': 'SPCFMStarOverrideClosedToNewInvestors',
    'Closed to Sells': 'SPCFClosedToSellsIndicator',
    'Tender/Interval Fund': 'SPCFTenderIndicator',
    'Dealer Agreement Required - Load Trades': 'SPCFDealerAgreementRequiredForLoadTrades',
    'Dealer Agreement Required - Load Transfers': 'SPCFDealerAgreementRequiredForLoadTransfers',
    'Dealer Agreement Required - No-Load Trades': 'SPCFDealerAgreementRequiredForNo_LoadTrades',
    'Dealer Agreement Required - No-Load Transfers': 'SPCFDealerAgreementRequiredForNo_LoadTransfers',
    'Bank Custody': 'SPCFBNYMellon',
    'Surcharge': 'SPCFSURCHARGE',
    'Broad Asset Class': 'SPCFFundAssetClassGroup',
    'Leveraged Fund': 'SPCFLeveragedFundFlag',
    'Benchmark Leverage Factor': 'SPCFLeveragedFundFactor',
    'Primary Underlying Benchmark': 'SPCFProspectusPrimaryBenchmarkName',
    'Gross Expense Ratio': 'SPCFGrossExpenseRatio',
    'Load Amount (Deferred)': 'SPCFSalesLoadDeferred',
    'Load Amount (Front-end)': 'SPCFSalesLoadMaxFrontLoad',
    'Inception Date': 'SPCFInceptionDate',
    'Manager Tenure': 'SPCFManagerTenure',
    'Minimum Initial Investment': 'SPCFMStarOverrideMinimumInitialInvestment',
    'Top 10 Weight (%)': 'SPCFWeightingTop10Holdings',
    'Annual Turnover Ratio (%)': 'SPCFYearlyTurnoverRatio',
    'Annual Turnover Ratio Date': 'SPCFTurnoverRatioDate',
    'Morningstar Equity Style Box': 'SPCFEquityStyleBoxText',
    'Morningstar Bond Style Box': 'SPCFFixedIncomeStyleBoxText',
    'Total Bonds (Net %)': 'SPCFTotalBondNet',
    'Total Stock (Net %)': 'SPCFTotalStockNet',
    'Portfolio Date': 'SPCFPortfolioDate',
    'Distribution Frequency': 'SPCFMStarOverrideDividendFrequency',
    'Trailing 1 Year Yield': 'SPCFTrailing1YrYield',
    'SEC Yield': 'SPCFSECYield',
    'NAV % Change Last': 'SPCFNAVChgPct',
    'NAV Change': 'SPCFNAVChgAmt',
    'Market Return (YTD %)': 'SPCFTrailingMarketReturnYTD',
    'Market Return (1Yr %)': 'SPCFTrailingMarketReturnY1',
    'Market Return (3Yr %)*': 'SPCFTrailingMarketReturnY3',
    'Market Return (5Yr %)*': 'SPCFTrailingMarketReturnY5',
    'Market Return (10Yr %)*': 'SPCFTrailingMarketReturnY10',
    'NAV Return (YTD %)': 'SPCFTrailingReturnYTD',
    'NAV Return (1Yr %)': 'SPCFTrailingReturnY1',
    'NAV Return (3Yr %)*': 'SPCFTrailingReturnY3',
    'NAV Return (5Yr %)*': 'SPCFTrailingReturnY5',
    'NAV Return (10Yr %)*': 'SPCFTrailingReturnY10',
    // 'Tax Cost Ratio 3 Year': 'SPCFTaxCostRatioY3',
    'Tax Cost Ratio (5Yr %)': 'SPCFTaxCostRatioY5',
    'Performance As Of Date': 'SPCFPerformanceAsOfDate',
    'Number of Holdings - Total': 'SPCFNumberofHoldingsNet',
    'Average Market Cap': 'SPCFAverageMarketCap',
    'Average Price to Earnings': 'SPCFEarningsYieldLongInv',
    'Average Credit Quality': 'SPCFAverageCreditQualityLongLetter',
    'Average Coupon': 'SPCFAvgCoupon',
    'Average Yield To Maturity': 'SPCFYieldToMaturityLong',
    // 'Portfolio Date': 'SPCFPortfolioDate',
    'Morningstar Medalist Rating': 'SPCFMedalistRating',
    // 'Morningstar Rating - Overall': 'SPCFMorningstarOverallRating',
    'Morningstar Rating - 3 Year': 'SPCFMorningstar3YrRating',
    'Morningstar Rating - 5 Year': 'SPCFMorningstar5YrRating',
    'Morningstar Rating - 10 Year': 'SPCFMorningstar10YrRating',
    'Morningstar Performance Score - Overall': 'SPCFReturnScore',
    'Morningstar Risk Score - Overall': 'SPCFRiskScore',
    'Rating Date': 'SPCFMedalistRatingDate',
    'Morningstar Rating As of Date': 'SPCFMStarRatingAsOfDate',
    'Sharpe Ratio (3Yr %)': 'SPCFSharpeRatioY3',
    'Sharpe Ratio (5Yr %)': 'SPCFSharpeRatioY5',
    'Standard Deviation (3Yr %)': 'SPCFStandardDeviationY3',
    'Standard Deviation (5Yr %)': 'SPCFStandardDeviationY5',
    'Alpha (3Yr %)**': 'SPCFPrimaryIndexAlphaY3',
    'Alpha (5Yr %)**': 'SPCFPrimaryIndexAlphaY5',
    'Beta (3Yr %)**': 'SPCFPrimaryIndexBetaY3',
    'Beta (5Yr %)**': 'SPCFPrimaryIndexBetaY5',
    'Rsquared (3Yr %)**': 'SPCFPrimaryIndexRSquaredY3',
    'Rsquared (5Yr %)**': 'SPCFPrimaryIndexRSquaredY5',
    'Capture Ratio Up (3Yr)': 'SPCFCaptureRatio3YearUp',
    'Capture Ratio Down (3Yr)': 'SPCFCaptureRatio3YearDown',
    'Capture Ratio Up (5Yr)': 'SPCFCaptureRatio5YearUp',
    'Capture Ratio Down (5Yr)': 'SPCFCaptureRatio5YearDown',
    'Morningstar Primary Benchmark': 'SPCFMorningstarPrimaryBenchmarkName',
    // 'Performance As Of Date': 'SPCFPerformanceAsOfDate',
    'On Balance Volume Indicator': 'SPCFOnBalanceVolume',
    'Volume - Last Trade Day': 'SPCFVolume',
    'Volume - 10 Day Average': 'SPCFAvg10DayVolume',
    'Volume - 90 Day Average': 'SPCFVolume90dAvg',
    'Percent Above 52 Week Low (%)': 'SPCFPctChgVs52wkLo',
    'Percent Below 52 Week High (%)': 'SPCFPctChgVs52wkHi',
    'Premium/Discount 1 Month Average': 'SPCFPremiumDiscountM1Average',
    'Premium/Discount 1 Year Average': 'SPCFPremiumDiscountY1Average',
    'Premium/Discount 1 Year High': 'SPCFPremiumDiscountY1High',
    'Premium/Discount 1 Year Low': 'SPCFPremiumDiscountY1Low',
    'Premium/Discount As Of Date': 'SPCFPremiumDiscountAsOfDate',
    'Actual 12b-1 Fee': 'SPCFActual12B1Fee',
    'Sustainable Investment': 'SPCFSustainableInvestment',
    'Morningstar Sustainability Rtg': 'SPCFSustainabilityRating',
    'Port Corp Sustainability Rtg': 'SPCFPortCorpSustRating',
    'Port Sovereign Sustainability Rtg': 'SPCFPortSovgSustRating',
    'Corp Sustainability Score (12M Avg)': 'SPCFFundHistAvgSustScore',
    'Sov Sustainability Score (12M Avg)': 'SPCFHistSovgSustScore',
    'Environmental Score': 'SPCFPortfolioEnvironmentalScore',
    'Governance Score': 'SPCFPortfolioGovernanceScore',
    'Social Score': 'SPCFPortfolioSocialScore',
    'Corp % Port Contrib To Sustainability Rtg': 'SPCFPortSustRatingCorpContibPct',
    'Sov % Port Contrib to Sustainability Rtg': 'SPCFPortSustRatingSovgContribPct',
    'Corp ESG Risk Score (% Corp AUM) - Neg/Med Sum': 'SPCFCorpESGNeg2Med',
    'Corp ESG Risk Score (% Corp AUM) - High/Sev (Sum)': 'SPCFCorpESGHigh2Sev',
    'Sov ESG Risk Score (% Sov AUM) - Neg/Med (Sum)': 'SPCFSovESGNeg2Med',
    'Sov ESG Risk Score (% Sov AUM) - High/Sev (Sum)': 'SPCFSovESGHigh2Sev',
    'Controversies (% AUM) - None/Mod (Sum)': 'SPCFPctAumMod2NoControversies',
    'Controversies (% AUM) - Sig/Sev (Sum)': 'SPCFPctAumSev2SigControversies',
    'Carbon Risk Classification': 'SPCFCarbonRiskClassification',
    'Low Carbon Designation': 'SPCFLowCarbonDesignation',
    'Carbon Risk Score - Current': 'SPCFCarbonRiskScore',
    'Carbon Risk Score (Current) - Cat Rank': 'SPCFCarbonRiskScorePercentRankinCategory',
    'Fossil Fuel Involvement (12M Avg, % AUM)': 'SPCFAverage12MonthFossilFuelExposure',
    'Carbon Risk % AUM - Neg/Med': 'SPCFPctAUMNeg2MedCarbonRisk',
    'Carbon Risk % AUM - High/Sev': 'SPCFPctAUMHigh2SevCarbonRisk',
    'Carbon Solutions Involvement (% AUM)': 'SPCFCarbonSolutionsInvolvement',
    'Carbon Solutions Cat Avg (% AUM)': 'SPCFCarbonSolutionsCategoryAverage',
    'Carbon Intensity (mtonne Co2/Mil Rev USD)': 'SPCFCarbonIntensity',
    'Carbon Intensity (Cat Avg)': 'SPCFCarbonIntensityCategoryAverage',
    'Percent AUM Covered Carbon': 'SPCFPercentAUMCoveredCarbon',
    'Scope 1 & 2 Emissions': 'SPCFScope1Emissions',
    'Scope 1, 2 & 3 Emissions': 'SPCFScope2Emissions'

  }
export const dropDownDaysValues = {
  '1 Day': 1,
  '1 Week': 7,
  '1 Month': 30,
  '6 Months': 180,
  '1 Year': 365,
  '5 Years': 1825
}
export const dropDownDaysValuesText = {
  '1': '1 Day',
  '7': '1 Week',
  '30': '1 Month',
  '180': '6 Months',
  '365': '1 Year',
  '1825': '5 Years'
}
export const TIME_PERIOD = {
  '1D': 1,
  '1W': 7,
  '1M': 30,
  '6M': 180,
  '1Y': 365,
  '5Y': 1825
}
export const MOBILE_WIDTH_LIMIT = 596
export const CardTopBorderColors = [
  '#004480',
  '#0095FF',
  '#D97E07',
  '#00A695',
  '#AA01AA',
  '#FF573F',
  '#9085FF',
  '#BF360C',
  '#00AC00',
  '#093055',
  '#00A0D4',
  '#7D324C',
  '#5FA113',
  '#751D84',
  '#C98506',
  '#008778',
  '#E65100',
  '#7462E0',
  '#003E83',
  '#00BCD4'
]

export const ModuleName = {
  ETF_RESEARCH_SNAPSHOT: 'ETF_RESEARCH_SNAPSHOT',
  ETF_RESEARCH_SNAPSHOT_ASSET_ALLOCATION: 'ETF_RESEARCH_SNAPSHOT_ASSET_ALLOCATION',
  AI_COMPARE_RESULT_TABLE: 'AI_COMPARE_RESULT_TABLE',
  MF_RESEARCH_SNAPSHOT: 'MF_RESEARCH_SNAPSHOT',
  FUND_SCREENER: 'FundScreener'
}

export const FUNDMARKETPLACE_TABS = {
  screener: 'Screener',
  compare: 'Compare',
  profile: 'Fund Profile'
}

export const EQUITYMARKETPLACE_TABS = {
  screener: 'Screener',
  compare: 'Compare',
  profile: 'Equity Profile'
}

export const AIMARKETPLACE_TABS = {
  screener: 'Screener',
  profile: 'Alternative Profile'
}

export const HistoricalChartType = {
  HDIST: 'Historical Distribution',
  HNAV: 'Historical NAVs'
}

export const fundTypeWithStockHiglights = ['MF', 'ETF', 'CEF', 'FSCMF', 'FSCETF', 'AIN', 'MFSnapshot', 'AINSnapshot']

export const MOD_GOTO_PAGES = {
model: 'model-marketplace',
  MOD: 'model-marketplace',
  ModL: 'model-marketplace',
  SMAL: 'model-marketplace',
  SMA: 'model-marketplace',
  fund: 'funds',
  MF: 'funds',
  ETF: 'funds',
  FSCMF: 'funds',
  FSCETF: 'funds',
  alternative: 'alternative-investments',
  AI: 'alternative-investments',
  AIN: 'alternative-investments',
  EQ: 'equities',
  EQ_Screener: 'investment-center-equities'
}
export const FundCardTopWidth = 200

export const BetaRiskChartType = {
  DEFAULT_CHART: 'DefaultChart', // for 0 to 2
  DEFAULT_CHART4: 'DefaultChart4', // for 0 to 4
  BIPOLAR_CHART: 'BipolarChart', // for -5 to +5
  LONG_CHART: 'LongChart', // for 0 to 300
  BIPOLAR_CHART_MINUS_2: 'BipolarChartMinus'
}
export const EtfType = ['ETF', 'ETN', 'FSCETF']
export const GROWTH_10K_PERFORMANCE = {
  INDEXES: {
    'darkColor': '#004480',
    'lightColor': 'rgb(191, 208, 223, 0.4)', // 'rgba(41,61,192,0.25)',
    'borderColor': '#2938C8'
  },
  'chartTypes': {
    LINECHART: 'chart-line',
    CANDLESTICKCHART: 'chart-candlestick',
    Fill: 'chart-area'
  },
  'dropDownDaysValues': {
    '1 Day': 1,
    '5 Days': 5,
    '1 Month': 30,
    '3 Months': 90,
    '1 Year': 365,
    '5 Years': 1825,
    '10 Years': 3650,
    'Max': 10000
  },
  'dropDownDaysValuesInMonths': {
    '1 Month': 1,
    '3 Months': 3,
    '1 Year': 12,
    '3 Years': 36,
    '5 Years': 60,
    '10 Years': 120,
    'Max': 10000
  },
  'intervalMapping': {
    1: '1',
    5: '1',
    30: '1',
    90: '1',
    365: '1',
    1825: '1',
    3650: '1',
    10000: '1'
  },
  'periodMapping': {
    1: 'Hour',
    5: 'Hour',
    30: 'Week',
    90: 'Week',
    365: 'Month',
    1825: 'Year',
    3650: 'Year',
    10000: 'Year'
  },
  PERFORMANCECHART: {
    DATAINTERVAL: 30,
    STYLE: {
      LABELFONTSIZE: 12,
      LABELFONTCOLOR: '#000000',
      LABELFONTFAMILY: 'Roboto-Regular',
      BORDERBOTTOMCOLOR: 'rgb(0, 0, 0, .3)',
      GRIDHORIZONTALCOLOR: 'rgb(0, 0, 0, .15)'
    },
    MARKERTYPE: 'lineWithShapes'
  },
  TIME_PERIOD: {
    '1D': 1,
    '5D': 5,
    '1M': 30,
    '3M': 90,
    '1Y': 365,
    '5Y': 1825,
    '10Y': 3650,
    'Max': 10000
  },
  TIME_PERIOD_IN_MONTH: {
    '1M': 1,
    '3M': 3,
    '1Y': 12,
    '3Y': 36,
    '5Y': 60,
    '10Y': 120,
    'Max': 10000
  }
}

export const PROFILE = 'profile'
export const SCREENER = 'screener'
export const AINSnapshot = 'AINSnapshot'
export const MFSnapshot = 'MFSnapshot'
export const AI_SEARCH_TYPE_LIST = ['MOD', 'SMA', 'AIN', 'AIScreener', 'MF', 'Open-End Mutual Fund', 'ETF', 'ModL', 'SMAL', 'EQ']

export const SearchTypes = ['MF', 'ETF', 'EQ']
export const NonTradedReit = 'Non-Traded REIT'
export const AISearchType = ['AI', 'AIN', 'AIScreener', 'AINSnapshot']

export const StrategyTypes = {
  'Investment Philosophy': 'investmentPhilosophy',
  'Fund Strategy': 'investmentStrategy',
  'Portfolio Construction': 'portfolioConstruction'
}

export const ModelTypeUnlocked = ['SMA', 'MOD', 'MOC', 'TPMOC']
export const SearchResultsLimit = 20

export const externalScrollToFiltersList = ['esgFundRating-select', 'esgCarbonMetrics-select']

export const ESgCarbonKeys = {
  CoveredPortfolioPercentInvolved: 'coveredPortfolioPercentInvolved',
  HistoricalCoveredPortfolioPercentInvolved: 'historicalCoveredPortfolioPercentInvolved'
}

export const carbonScore = `The asset-weighted average of the carbon risk scores for the portfolio's covered, corporate holdings. The carbon risk score indicates the overall material risk a company faces from the transition to a low-carbon economy. A lower score is better. At least 67% of the portfolio's eligible assets must have carbon risk scores available in order for a score to be calculated.`

export const fossilFuleDesc= `Oil and gas power generation. Companies deriving at least 50% of their revenue from oil and gas products and services are also included.`

export const corporate = `The Morningstar Portfolio Corporate Sustainability Score is an asset-weighted average of Sustainalytics' company-level ESG Risk Score. The Sustainalytics' company-level ESG Risk Score measures the degree to which a company's economic value may be at risk driven by ESG factors. Like the ESG Risk Scores, the Portfolio Corporate Sustainability Score is rendered on a 0-100 scale, where lower scores are better, using an asset-weighted average of all covered securities. Morningstar determines whether each eligible portfolio holding can be classified under the corporate or sovereign framework. Each holding can only contribute an ESG Risk Score or a Country Risk score, not both. To receive a Corporate Sustainability Score, at least 67% of a portfolio's corporate assets under management (long positions only) must have a company ESG Risk Rating.`

export const sovereign = `The Morningstar Portfolio Sovereign Sustainability Score is an asset-weighted average of Sustainalytics' Country Risk Score. The Sustainalytics' Country Risk Score measures the risk to a country's long-term prosperity and economic development by assessing its wealth and its ability to managing its wealth sustainably. Like the Country Risk Scores, the Portfolio Sovereign Sustainability Score is rendered on a 0-100 scale, where lower scores are better, using an asset-weighted average of all covered securities. Morningstar determines whether each eligible portfolio holding can be classified under the corporate or sovereign framework. Each holding can only contribute an ESG Risk Score or a Country Risk score, not both. To receive a Sovereign Sustainability Score, at least 67% of a portfolio's sovereign assets under management (long positions only) must have a country ESG Risk Rating.`

export const sustainabilityRating = `Morningstar assigns Sustainability Ratings by combining a portfolio's Corporate Sustainability Rating and Sovereign Sustainability Rating proportional to the relative weight of the (long only) corporate and sovereign positions, rounded to the nearest whole number. Sovereign Historical Sustainability Scores and Corporate Historical Sustainability Scores are ranked and rated separately, to represent the ESG risk of the portfolio relative to its peers for its respective corporate and sovereign positions, and then combined by their relative weights for the Portfolio Sustainability Rating.`
export const sustainabilityRatingDate = `The effective date of the ESG risk for a portfolio's holdings from which the portfolio's Historical Corporate Sustainability Score and Historical Sovereign Sustainability Score are derived and  ranked within its Morningstar Global Category to determine  respective Corporate and Sovereign Sustainability Ratings. The Corporate and Sovereign Sustainability Ratings are subsequently combined to assign the Portfolio Morningstar Sustainability Rating.`
export const sustainableInvestment = `Morningstar defines "Sustainable Investment" as a fund that explicitly indicates any kind of sustainability, impact, or ESG strategy in their prospectus or offering documents.`
export const AUMCoveredESG = `The percent of a portfolio’s corporate assets under management that have a Sustainalytics' company-level ESG Risk Score. Morningstar determines whether each portfolio holdings' ESG risk can be classified as corporate, sovereign, or other. The corporate percent AUM covered is derived as the assets under management of holdings with a Sustainalytics' company-level ESG Risk Score divided by the total assets under management of all (long only) corporate positions.`
export const AUMCoveredControversy = `The percent of a portfolios’ sovereign assets under management that have a Sustainalytics' Country Risk Score. Morningstar determines whether each portfolio holdings' ESG risk can be classified as corporate, sovereign, or other. The sovereign percent AUM covered is derived as the assets under management of holdings with a Sustainalytics' Country ESG Risk Score divided by the total assets under management of all (long only) sovereign positions.`
export const AUMHighControveries = `The AUM distribution of sovereign holdings with a High to Severe country ESG Risk Classification per Sustainalytics, which is determined as ESG Risk Scores 30+`
