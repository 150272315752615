/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './BasicDropDown.module.scss'
import { generateUniqueID } from '../../../utils/utilities'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function BasicDropDown (props) {
  const [containerStyle, setContainerStyle] = useState({})
  const guid = generateUniqueID()

  let {data, id} = props
  let formattedData
  let selectedValueText
  let containsImages
  if (data?.length > 0 && typeof data[0].valueOf() === 'string') {
    containsImages = false
    selectedValueText = props.selectedValue && data.includes(props.selectedValue) ? props.selectedValue : data[0]
  } else {
    containsImages = true
    formattedData = data.map(item => item.Icon)
    const fetchedValue = props.selectedValue && formattedData?.includes(props.selectedValue) ? props.selectedValue : formattedData[0]
    selectedValueText = <FontAwesomeIcon icon={['fas', fetchedValue]} />
  }
  let liIndex = -1
  const span = props.label && props.label.length > 0 ? (<span value={props.label}>{props.label} </span>) : null
  const [isListOpen, setListOpen] = useState(0)
  const [selectedValue, setSelectedValue] = useState(selectedValueText)
  let valueViaUpDownKeys = selectedValue

  useEffect(() => {
    isListOpen && getDropDownPosition()
  }, [isListOpen])

  useEffect(() => {
    if (props.resetDefaultValue && props.resetDefaultValue.length > 0) {
      setSelection(props.resetDefaultValue)
      props.setRevertDefaultValue(null)
    }
  }, [props.resetDefaultValue])

  useEffect(() => {
    if (props.isScrollToCLose) {
      document.addEventListener('scroll', function (event) {
        setListOpen(0)
      }, false)

      return () => {
        document.removeEventListener('scroll', function (event) {
        }, false)
      }
    }
  }, [])

  const renderDropDownIcon = (isListOpen) => (
    <span className={`${styles['icon']} ${styles['float-right']}`}>
      {isListOpen ? (
        <FontAwesomeIcon icon={['fas', 'caret-up']} color='black' className={styles.caretIcon} />
      ) : (
        <FontAwesomeIcon icon={['fas', 'caret-down']} color='black' className={styles.caretIcon} />
      )}
    </span>
  );

  const getOpenClassName = (isListOpen, styleOpen, styleHide) => {
    return isListOpen ? styleOpen : styleHide;
};

  return (
    <React.Fragment>
      {span}
      <ul className={`${styles['basicDropDownContainer']} ${props.customStyle && styles['basicDropDownCustomContainer']} 
           ${getOpenClassName(isListOpen, styles['open'], '')} ${props.onlyBottomBorder ? styles['dropdownWithBottomBorder'] : ''} DropDownUL`} style={props.styles} id={guid} onBlur={handleOnBlur}>

        <li tabIndex='0' onClick={openCloseMenu} onKeyDown={handleKeys} className={` 
            ${!props.onlyBottomBorder ? styles['enableBorder'] : ''}`}>
          <span className={`${styles['name']}`} title={''} value={selectedValue}>
            {selectedValue}
          </span>
          {renderDropDownIcon(isListOpen)}
        </li>
        <div className={`${getOpenClassName(isListOpen, '', styles['hide'])} ${styles['basicDropDownValueContainer']} dropDownList`} style={containerStyle}>
          {
            BindDropDownValues(data, containsImages, id)

          }
        </div>
      </ul>
    </React.Fragment>
  )

  function openCloseMenu (e) {
    e?.stopPropagation()
    valueViaUpDownKeys = selectedValue
    setListOpen(!isListOpen)
  }

  function getDropDownPosition () {
    let elem = document.getElementById(guid)
    if (elem && elem.childElementCount === 2) {
      let ulDimensions = elem.getBoundingClientRect()
      let heightdropDownContainer = props.data.length * elem.children[1].firstElementChild.offsetHeight
      heightdropDownContainer = heightdropDownContainer > 450 ? 450 : heightdropDownContainer
      let firstChildHeight = ulDimensions.bottom - ulDimensions.top - heightdropDownContainer - 10
      let containerStyle = {
        visibility: 'unset'
      }
      if ((((document.documentElement.clientHeight - (ulDimensions.top + firstChildHeight)) < heightdropDownContainer))) {
        containerStyle.top = (-1 * (heightdropDownContainer + firstChildHeight + 15)) + 'px'
      }
      setContainerStyle(containerStyle)
    }
  }

  function setSelection (value) {
    if (containsImages) {
      formattedData = data.filter(item => {
        if (item.Text === value) {
          return item.Icon
        }
      })
      const selectedText = formattedData && formattedData.length > 0 && formattedData[0]?.Icon
      selectedValueText = <FontAwesomeIcon icon={['fas', selectedText]} />
      setSelectedValue(selectedValueText)
      valueViaUpDownKeys = selectedValueText
    } else {
      setSelectedValue(value)
      valueViaUpDownKeys = value
    }
  }

  function updateLiIndex (keyCode, lenrenderedLi) {
    if (keyCode === 40) { // Down arrow
      liIndex++;
      if (liIndex > lenrenderedLi) liIndex = 0;
    } else if (keyCode === 38) { // Up arrow
      liIndex--;
      if (liIndex < 0) liIndex = lenrenderedLi;
    }
  };

  function handleKeys (e) {
    let sibling = e.currentTarget.nextElementSibling
    let renderedLi = sibling.querySelectorAll('li')
    let lenrenderedLi = renderedLi.length - 1
    if (e.key === 'Enter') {
      const value = renderedLi[liIndex]?.getAttribute('data-value')
      if (value && value !== selectedValue) {
        setSelection(value)
        props.changeHandler && props.changeHandler(renderedLi[liIndex])
      }
      openCloseMenu(e)
    } else {
      let keyCode = e.which
      let isFound = 0
      renderedLi?.forEach((item, idx) => {
        item.classList.remove(styles['selected'])
        if (valueViaUpDownKeys === item.getAttribute('data-value') && !isFound) {
          liIndex = idx
          isFound = 1
        }
      })
      updateLiIndex(keyCode, lenrenderedLi);
      let ci = renderedLi[liIndex]
      ci?.classList.add(styles['selected'])
      valueViaUpDownKeys = (ci?.getAttribute('data-value'))
    }
  }

  function handleChangeEvent (e) {
    const value = e.currentTarget.getAttribute('data-value')
    setSelection(value)
    if (props.changeHandler) {
      props.changeHandler(e.currentTarget)
    }
    if (e.key === 'Enter') {
      handleKeys()
    } else { openCloseMenu() }
  }

  function BindDropDownValues (dropdownData, containsImages, id) {
    const dropdownBody = (dropdownData && dropdownData.length > 0 ? dropdownData.map(
      (item, index) => (
        <li tabIndex='0'
          key={index}
          onClick={handleChangeEvent}
          onKeyDown={handleChangeEvent}
          className={`
              ${isListOpen ? '' : styles['hide']}
              ${selectedValue === (containsImages ? item.Text : item) ? styles['selected'] : ''}`} data-value={(containsImages ? item.Text : item)} id={id || ''}>
          <span className={`${styles['name']}`} title={(containsImages ? item.Text : '')}>
            {/* only Text array */}
            {!containsImages && item }
            {/* image only */}
            {containsImages && item.Onlyvisual && <FontAwesomeIcon icon={['fas', item.Icon]} />}
            {/* image and text */}
            {containsImages && !item.Onlyvisual && item.Text}
            {containsImages && !item.Onlyvisual && <FontAwesomeIcon icon={['fas', item.Icon]} />}
          </span>
        </li>
      )
    ) : null)
    return dropdownBody
  }

  function handleOnBlur (e) {
    e?.stopPropagation()
    if (isListOpen && !e.currentTarget.contains(e.relatedTarget)) {
      openCloseMenu(e)
    }
  }
}

BasicDropDown.propTypes = {
  changeHandler: PropTypes.func,
  data: PropTypes.array,
  selectedValue: PropTypes.string,
  label: PropTypes.string,
  styles: PropTypes.object,
  onlyBottomBorder: PropTypes.bool,
  id: PropTypes.string,
  customStyle: PropTypes.bool,
  resetDefaultValue: PropTypes.string,
  setRevertDefaultValue: PropTypes.func,
  isScrollToCLose: PropTypes.bool
}
