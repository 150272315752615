import React, { useState, useEffect } from 'react'
import EventDropDown from './EventDropDown'
import PropTypes from 'prop-types'
import CustomSelectMenu from '../../Lib/CustomSelectMenu/CustomSelectMenu'

function ChartEvents ({label, eventData, onSelection, isResetEvent, setResetEvent, isVisible, popOverStyle, changeHandler, defaultSelectedValues}) {
  const [selectedValues, filterOptions] = useState([])
  useEffect(() => {
    defaultSelectedValues && changeHandlerMultiSelect(defaultSelectedValues)
  }, [])
  return (
    <React.Fragment>
      <CustomSelectMenu preFixLabel={label} isVisible={isVisible} popOverStyle={popOverStyle}
        popOverBody={
          <EventDropDown
            data={eventData}
            changeHandler={changeHandlerMultiSelect}
            selectedDropDown={onSelection}
            selectedValue={
              (selectedValues.valuesSelected && selectedValues.valuesSelected.map(item => { return item.text }).join(',')) || ''
            }
            isResetEvent={isResetEvent}
            setResetEvent={setResetEvent} />}
      />
    </React.Fragment>
  )
  function changeHandlerMultiSelect (valuesFromDropDown, e) {
    let values
    if (typeof (valuesFromDropDown) === 'string') {
      values = valuesFromDropDown.split(',')
    } else {
      values = valuesFromDropDown
    }
    if (changeHandler) {
      changeHandler(values, e)
    }
    const valuesSelected = []
    if (values.length > 0) {
      values.forEach(item => {
        valuesSelected.push({
          text: item,
          value: item,
          checked: true
        })
      })
      filterOptions({ valuesSelected })
    } else {
      filterOptions([])
    }
  }
}
export default ChartEvents
ChartEvents.propTypes = {
  label: PropTypes.string,
  onSelection: PropTypes.func,
  eventData: PropTypes.array,
  defaultSelectedValues: PropTypes.array,
  isResetEvent: PropTypes.bool,
  setResetEvent: PropTypes.func,
  isVisible: PropTypes.string,
  popOverStyle: PropTypes.string,
  changeHandler: PropTypes.func
}
