'use strict'

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { useQuote } from '../../../../hooks/useQuote'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Loader from '../../../Lib/common/Loader'
import { formatNumber, renderColorClass } from '../../../../utils/formatter'
import { enterKeyEvent } from '../../../../utils/utilities'
import styles from './SymbolCard.module.scss'

export default function SymbolCard ({symbol, venueXid, color, onClose, showHideHandler, isRealTime, userInfo}) {
  const quoteData = useQuote(venueXid, isRealTime, userInfo)
  const {quote, isLoading, isError} = quoteData
  const [toggle, setToggle] = useState(true)
  const blueColor = '#0572D6'

  const eyeClickHandler = () => {
    setToggle(!toggle)
    showHideHandler(venueXid)
  }

  return (
    <div className={styles.symbolCardContainer} style={{borderTop: `4px solid ${color}`}} data-symbol-card='modchart-symbol-card'>
      <div className={styles.header} data-symbol-name='modchart-symbol-card-name'>
        {symbol}
      </div>
      {isLoading ? <Loader />
        : isError ? <span>--</span>
          : <React.Fragment>
            <div className={styles.priceChange}>
              <span>
                {formatNumber(quote.lastTrade.last, {precision: 2})}
              </span>
              <span className={styles[renderColorClass(quote.lastTrade.change)]}>
                {formatNumber(quote.changePercent.today, {precision: 2, negSign: true, posSign: true, postFix: true})}
              </span>
              {showHideHandler && <div className={styles.closeButton} role='button' data-testid='eyeButton' tabIndex='0' onClick={eyeClickHandler} onKeyDown={(e) => enterKeyEvent(e, eyeClickHandler)}>
                {toggle ? <FontAwesomeIcon color={blueColor} icon={['far', 'eye']} /> : <FontAwesomeIcon color={blueColor} icon={['far', 'eye-slash']} />}
              </div>}
              {onClose &&
              <div className={styles.closeButton} role='button' data-testid='closeButton' tabIndex='0' onClick={onClose} onKeyDown={(e) => enterKeyEvent(e, onClose)}>
                <FontAwesomeIcon icon={['fal', 'times']} />
              </div>
              }
            </div>

          </React.Fragment>
      }
    </div>
  )
}
SymbolCard.propTypes = {
  symbol: PropTypes.string,
  venueXid: PropTypes.number,
  color: PropTypes.string,
  onClose: PropTypes.func,
  showHideHandler: PropTypes.func,
  isRealTime: PropTypes.bool,
  userInfo: PropTypes.object
}
