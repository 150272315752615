/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DataRequest from '../../services/DataRequest'
import { ModchartsInteractive } from '@markit/modcharts/dist/modcharts.umd.js'
import '../Lib/ModChartsCss/ModCharts.scss'
import Tabs from '../Lib/Tabs/Tabs'
import ChartSymbolPriceInfo from './ChartSymbolPriceInfo/ChartSymbolPriceInfo'
import BasicChartTools from './ChartTools/BasicChartTools'
import AdvancedChartTools from './ChartTools/AdvancedChartTools'
import SymbolComparison from './SymbolComparison/SymbolComparison'
import { CHARTCOLORS } from '../../utils/chartConstants'
import styles from './Charts.module.scss'
import ChartSettings from './ChartSettings/ChartSettings'
import ChartEventInfoContainer from './ChartsEventsInfo/ChartEventInfoContainer'
import Loader from '../Lib/common/Loader'
import { deviceType, getDeviceType, handleWindowResize } from '../../utils/utilities'
import {removeEvents} from './ChartUtils'
import { makeEntitlementsCall } from '../../services/EntitlementsAPICall.js'
import {ENT_LIST} from '../../utils/appConstants'
import DataUnavailable from '../Lib/DataUnavailable/DataUnavailable'

export default function Charts ({ xid, symbol, showTabs, showOnlyBasicTools, showAddComparison, showChartSettingIcon, showVolumeChart, chartUpperPanelHeight, insidePopOver, topBorder, enableChartPeriodChangeEvent, timeFrameInDays }) {
  const [isLoading, setLoader] = useState(true)
  const [chart, setChart] = useState(null)
  const [reset, setReset] = useState(false)
  const [upperIndicator, setUpperIndicator] = useState([])
  const [xrefSymbolList, setXrefSymbolList] = useState({})
  const [isAdvanceTab, setIsAdvance] = useState(false)
  const [device, setDevice] = useState(getDeviceType())
  const [resetSaveChart, setResetSaveChart] = useState(null)
  const [primarySymbol, setPrimarySymbol] = useState({symbol: symbol, xid: xid})
  const [chartSelectedParams, setChartSelectedParams] = useState({})
  const [isRealTime, setIsRealTime] = useState(false)
  const [isNoChartData, setChartDataStatus] = useState(false)
  const numberOfDays = timeFrameInDays || 365

  useEffect(() => {
    handleWindowResize((response) => { setDevice(response) })
  }, [])

  const chartInputs = () => {
    return {
      params: {
        days: numberOfDays,
        ...(
          timeFrameInDays === 1 && {
            dataInterval: 5,
            dataPeriod: 'Minute'
          }),
        symbol: xid,
        zoomEnabled: false,
        panelHeightUpper: chartUpperPanelHeight,
        panelHeightLower: 150,
        apiPath: window.MD.MOD_CHART_URL,
        showFlags: true,
        style: {
          panel: {
            border: {
              color: {
                top: topBorder ? 'rgb(0, 0, 0, .3)' : 'rgb(0, 0, 0, 0)',
                bottom: 'rgb(0, 0, 0, .3)',
                default: 'transparent'
              }
            },
            grid: {
              color: 'transparent',
              horizontal: {
                color: 'rgb(0, 0, 0, .15)',
                lineDash: ''
              },
              vertical: {
                alt: { color: 'transparent' }
              }
            },
            margin: { bottom: 50 },
            xAxis: {
              label: {
                textAlign: 'center',
                fontSize: '12px',
                color: '#000000',
                padding: {
                  top: 10
                }
              }
            },
            yAxis: {
              label: {
                fontSize: '12px',
                color: '#000000',
                padding: {
                  left: 10
                }
              }
            }
          }
        }
      },
      panels: addPanels()
    }
  }

  const addPanels = () => {
    const panels = [
      {
        indicators: [{
          id: 'price',
          markerType: 'line',
          style: {
            color: CHARTCOLORS[0],
            width: 1
          }
        }
        ],
        events: []
      }
    ]
    if (showVolumeChart) {
      panels.push({indicators: [{
        id: 'volume',
        markerType: 'line',
        style: {
          color: CHARTCOLORS[0],
          width: 2,
          seriesBarDistance: 12
        }
      }
      ]
      })
    }
    return panels
  }

  const InitChart = () => {
    if (chart) {
      chart.destroy()
    }
    const authToken = DataRequest.getAccessToken()
    let chartObject = new ModchartsInteractive()
    chartObject.setAuthToken(authToken)
    chartObject.mount('#priceChart')
    chartObject.load(chartInputs(), chartLoadComplete)
    bindChartEvents(chartObject)
  }

  const chartLoadComplete = (data) => {
    if (data) {
      setChart(data)
    }
    setUpperIndicator([])
    setReset(!reset)
    setResetSaveChart(new Date().toString())
    setLoader(false)
  }

  const handlePanelLegendUpdate = (legendElement, indicators, panel, chartObject) => {
    const indicator = indicators[0]
    if (indicator.params.id !== 'price') {
      const id = `removeBtn_${indicator.params.name}`
      if (indicator && legendElement && panel) {
        legendElement.innerHTML = `<span class="panelLegend">
    <span class="label">${buildLegendLabel(indicator.params)}</span>
    <button id="${id}" class="closeButton" />
  </span>`
      }
      const indicatorRemoveBtn = document.getElementById(id)
      if (indicatorRemoveBtn) {
        indicatorRemoveBtn.addEventListener('click', function () { removeLowerIndicator(chartObject, panel.params.uid) })
      }
    }
  }

  const handleIndicatorUpperAdd = (indicator, xid) => {
    const primaryIndicatorXid = indicator.params.symbol
    if (parseInt(primaryIndicatorXid) !== xid) {
      setUpperIndicator(prevState => [...prevState, indicator])
    }
  }

  const handleIndicatorUpperRemove = (indicator) => {
    setUpperIndicator(prevState => {
      return prevState.filter(i => i.params.uid !== indicator.params.uid)
    })
  }

  const handleTimeSeriesSet = (chartObject) => {
    chartObject.panels[0].events.forEach(event => {
      removeEvents(event.params.id)
    })
  }

  const handleEventRemove = (eventType) => {
    removeEvents(eventType)
  }

  const handleLoadStart = () => {
    removeEvents('dividends')
    removeEvents('earnings')
    removeEvents('splits')
  }

  const handleLoadStop = (chartObject) => {
    if (chartObject && chartObject.status === 0 && (chartObject.data && Object.keys(chartObject.data).length === 0)) {
      setChartDataStatus(true)
      setLoader(false)
    }
    for (const [key, value] of Object.entries(chartObject.xref)) {
      if (parseInt(key) !== parseInt(xid)) {
        xrefSymbolList[key] = value.displaySymbol
      }
    }
    setXrefSymbolList({...xrefSymbolList})
    const primaryIndicatorXid = parseInt(chartObject.panels[0].indicators[0].params.symbol)
    if (primaryIndicatorXid !== xid) {
      setPrimarySymbol({symbol: xrefSymbolList[primaryIndicatorXid], xid: primaryIndicatorXid})
    }
  }

  const bindChartEvents = (chartObject) => {
    chartObject.eventEmitter.on('PANEL_LEGEND_UPDATE', ({legendElement, indicators, panel}) => {
      handlePanelLegendUpdate(legendElement, indicators, panel, chartObject)
    })
    chartObject.eventEmitter.on('INDICATOR_UPPER_ADD', ({indicator}) => {
      handleIndicatorUpperAdd(indicator)
    })
    chartObject.eventEmitter.on('INDICATOR_UPPER_REMOVE', ({indicator}) => {
      handleIndicatorUpperRemove(indicator)
    })
    chartObject.eventEmitter.on('TIME_SERIES_SET', () => {
      handleTimeSeriesSet(chartObject)
    })
    chartObject.eventEmitter.on('EVENT_REMOVE', ({eventType}) => {
      handleEventRemove(eventType)
    })
    chartObject.eventEmitter.on('DATA_LOAD_START', () => {
      handleLoadStart()
    })
    chartObject.eventEmitter.on('DATA_LOAD_STOP', () => {
      handleLoadStop(chartObject)
    })
    chartObject.eventEmitter.on('DATA_LOAD_ERROR', () => {
      if (chartObject) {
        setChart(chartObject)
      }
      setChartDataStatus(false)
      setLoader(false)
    })
  }

  const buildLegendLabel = (params) => {
    let config = ''
    params.inputs.forEach(input => {
      config += `${input.value},`
    })
    config = config.substring(0, config.length - 1)
    config = config ? ` (${config})` : ''
    return `${params.name}${config}`
  }

  const addSymbolComparison = (symbolList) => {
    chart.setSymbolCompare(
      symbolList.map(s => s.venueXid),
      symbolList.map((s, i) => { return getColors()[i] })
    )
    chart.loadData()
    chart.render()
  }

  const getColors = () => {
    const notUsedColor = []
    const { indicators } = chart.panels[0]
    for (const paletteColor of CHARTCOLORS) {
      let isValid = true
      for (const i of indicators) {
        const { color } = i.params.style
        if (paletteColor === color) {
          isValid = false
        }
      }
      if (isValid) notUsedColor.push({color: paletteColor})
    }
    return notUsedColor
  }

  const removeUpperIndicator = (id) => {
    const indicator = chart.getIndicatorByUid(id)
    if (indicator) {
      chart.panels[0].removeIndicator(indicator)
      chart.render()
    }
  }

  const removeLowerIndicator = (chart, panelId) => {
    const panel = chart.getPanelByUid(panelId)
    chart.removePanel(panel)
    chart.loadData()
    chart.render()
  }

  const updateTabType = (tabType) => {
    setLoader(true)
    setPrimarySymbol({symbol: symbol, xid: xid})
    if (tabType === 'Advanced') {
      setIsAdvance(true)
    } else {
      setIsAdvance(false)
    }
  }

  getUserDetails()

  useEffect(() => {
    InitChart()
  }, [isAdvanceTab])

  function getUserDetails () {
    makeEntitlementsCall((response) => {
      if (!response.isError) {
        if (response?.data) {
          if (response.data.entList) {
            let flag = false
            for (const key in ENT_LIST) {
              if (response.data.entList.includes(ENT_LIST[key])) {
                flag = true
                break
              }
            }
            setIsRealTime(flag)
          }
        }
      }
    })
  }

  const chartUpperIndicators = () => {
    return (
      <SymbolComparison
        indicators={upperIndicator}
        xrefSymbolList={xrefSymbolList}
        symbol={primarySymbol.symbol}
        venueXid={primarySymbol.xid}
        removeUpperIndicator={removeUpperIndicator}
        addSymbolComparison={addSymbolComparison}
        isMobile={device === deviceType.Mobile}
        showAddComparison={showAddComparison}
      />
    )
  }

  const onInputsSelectionHandler = (inputs) => {
    setChartSelectedParams({
      days: inputs.days,
      events: inputs.events
    })
  }

  const chartTemplate = () => {
    return <React.Fragment>
      {chart && <ChartSymbolPriceInfo chart={chart} xid={primarySymbol.xid} reset={reset} />}
      <div id='priceChart'>
        {isLoading && <Loader spinnerSize={'2x'} msg={'Loading'} />}
        {isNoChartData && <DataUnavailable />}
      </div>
      {chart && <ChartEventInfoContainer primarySymbol={primarySymbol.xid} chart={chart} reset={reset} chartParams={chartSelectedParams} insidePopOver={insidePopOver} />}
    </React.Fragment>
  }

  const chartMobile = () => {
    return <React.Fragment>
      {chart && chartUpperIndicators()}
      {chartTemplate()}
    </React.Fragment>
  }

  return (
    <div className={`${device === deviceType.Mobile && isAdvanceTab ? styles['chartTabMobile'] : styles['chartTabContainer']}`}>
      {showChartSettingIcon &&
        <ChartSettings chart={chart} xid={primarySymbol.xid} numberOfDays={numberOfDays} addSymbolComparison={addSymbolComparison} resetChart={InitChart} symbol={symbol} isAdvance={isAdvanceTab} />
      }
      {
        (device === deviceType.Desktop || device === deviceType.Ipad) && showTabs &&
        <Tabs id={'charts_tab'} ariaLabel={'Charting tabs'} activeTab={'Basic'} onClick={(k) => { updateTabType(k) }} >
          {}
          <div key='Basic' aria-label='Basic Chart' label='Basic'>
            {(device === deviceType.Desktop || device === deviceType.Ipad) && <div className={styles.chartTools}>
              <BasicChartTools primarySymbol={primarySymbol.xid} numberOfDays={numberOfDays} chart={chart} resetChart={InitChart} onInputsSelection={onInputsSelectionHandler} enableChartPeriodChangeEvent />
            </div>
            }
            {chart && chartUpperIndicators()}
            {chartTemplate()}
          </div>
          {isRealTime &&
          <div key='Advanced' aria-label='Advanced Chart' label='Advanced'>
            <div className={styles.chartTools}>
              {(device === deviceType.Desktop || device === deviceType.Ipad) &&
              <AdvancedChartTools
                chart={chart}
                resetChart={InitChart}
                resetIndicators={setUpperIndicator}
                resetSaveChart={resetSaveChart}
                onInputsSelection={onInputsSelectionHandler} />
              }
            </div>
            {chart && chartUpperIndicators()}
            {chartTemplate()}
          </div>
          }
        </Tabs>
      }
      {showOnlyBasicTools &&
      <BasicChartTools primarySymbol={primarySymbol.xid} numberOfDays={numberOfDays} chart={chart} resetChart={InitChart} onInputsSelection={onInputsSelectionHandler} enableChartPeriodChangeEvent />
      }
      {!showTabs && chartMobile()}
    </div>
  )
}

Charts.propTypes = {
  symbol: PropTypes.string.isRequired,
  xid: PropTypes.number.isRequired,
  showTabs: PropTypes.bool,
  showOnlyBasicTools: PropTypes.bool,
  showAddComparison: PropTypes.bool,
  showChartSettingIcon: PropTypes.bool,
  showVolumeChart: PropTypes.bool,
  chartUpperPanelHeight: PropTypes.number.isRequired,
  insidePopOver: PropTypes.bool,
  topBorder: PropTypes.bool,
  enableChartPeriodChangeEvent: PropTypes.bool,
  timeFrameInDays: PropTypes.number
}
