/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import styles from './IndicatorConfiguration.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../Lib/Button/Button'
import BasicDropDown from '../../Lib/Dropdown/BasicDropDown'
import PropTypes from 'prop-types'
import ColorPalette from '../../Lib/ColorPalette/ColorPalette'
import { COLOR_DEFAULT_SET, CONFIRMATION_MESSAGES } from '../../../utils/appConstants'
import IndicatorData from '../../../data/ChartIndicator.json'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import IndicatorCancelPopup from './IndicatorCancelPopup'

function IndicatorConfiguration (props) {
  const [configurationData, setConfigurationData] = useState([...IndicatorData.CHARTINDICATORS.CONFIGURATIONITEMS])
  const [showCancelScreen, setShowCancelScreen] = useState(false)
  useEffect(() => {
    let elementStyle = document.querySelector('#CustomSelectMenu_Indicators > div')
    if (elementStyle) {
      elementStyle.style.height = '1px'
      elementStyle.style.position = 'absolute'
      elementStyle.style.top = '-9999px'
    }
  }, [])

  const handleExit = e => {
    if (props.isMobile && !props.showDropDownTypeMenu) {
      setShowCancelScreen(true)
    } else {
      popupExtraSettingReset()
      props.closePopup(true)
    }
  }

  function handleApply2 (e) {
    popupExtraSettingReset()
    props.closePopup(true)
    props.onSelection(getChartData())
    props.handleReset(e)
  }
  function onConfirmation (e) {
    setShowCancelScreen(false)
    if (e === CONFIRMATION_MESSAGES.Yes) {
      props.closePopup(true)
    }
  }

  const popupExtraSettingReset = () => {
    let extraSetting = document.getElementById('CustomSelectMenu_Indicators')
    if (extraSetting) {
      extraSetting.style = 'popupExtraSettingReset'
    }
  }

  const getChartData = () => {
    let indicatorList = []
    configurationData.filter(function (firstItem) {
      return props.selectedItems.filter(function (secondItem) {
        return (secondItem.key === firstItem.key && secondItem.name === firstItem.name)
      }).length !== 0
    }).map(({ key, name, listItems, panel, markerType }, index) => {
      let indicator = {}
      indicator['Type'] = key
      indicator['name'] = name
      indicator['panel'] = panel
      let params = {}
      let style = {}
      listItems.map(({ chartKey, selectedValue, type }) => {
        if (type === 'textbox') {
          params[chartKey] = selectedValue
        }
        if (type === 'colorpallette') {
          style[chartKey] = selectedValue
        }
      })
      indicator['Params'] = params
      indicator['style'] = style
      if (markerType) {
        indicator['markerType'] = markerType
      }
      indicatorList.push(indicator)
    })
    indicatorList.forEach(indicator => {
      const existingIndicator = props.chart.getIndicatorsById(indicator.Type)
      if (existingIndicator.length === 0) {
        if (indicator.panel === 'lower') {
          const newPanel = props.chart.addPanel()
          addIndicator(newPanel, indicator)
        } else {
          addIndicator(props.chart.panels[0], indicator)
        }
      }
    })
    props.chart.loadData()
    return { 'INDICATORS': indicatorList, 'hideChartIndicatorOptionMenu': new Date().toString() }
  }

  function addIndicator (panel, indicator) {
    const addedIndicator = panel.addIndicator(indicator.Type)
    for (let [key, value] of Object.entries(indicator.Params)) {
      addedIndicator.setInput(key, value)
    }
    if (indicator.style) {
      for (let [key, value] of Object.entries(indicator.style)) {
        addedIndicator.setStyle(key, value)
      }
    }
  }

  const changeHandler = (key, value) => {
    const data = [...configurationData]
    data.forEach((items) => {
      items.listItems.forEach((item) => {
        if (item.id === key) {
          item.selectedValue = value
        }
      })
    })
    setConfigurationData(data)
  }

  const getItems = configurationData.filter(function (firstItem) {
    return props.selectedItems.filter(function (secondItem) {
      return (secondItem.key === firstItem.key && secondItem.name === firstItem.name)
    }).length !== 0
  }).map(({ key, name, listItems }, index, arr) => {
    let body = []
    body.push(<div key={'indicatorConfig_' + index} className={`${styles['header']}`}>{props.selectedItems.find(x => (x.key === key && x.name === name)).value}</div>)
    listItems.map(({ display, values, selectedValue, id, type }, indexInner) => {
      body.push(
        <div key={'indicatorConfigInner_' + indexInner} className={styles.items}>
          <div className={styles.leftItem}>{display}</div>
          <div className={styles.rightItem}>
            {type === 'dropdown' && <BasicDropDown
              data={values}
              selectedValue={selectedValue}
              changeHandler={(e) => changeHandler(e.getAttribute('id'), e.getAttribute('data-value'))}
              id={id}
              key={id}
            />
            }
            {type === 'textbox' &&
              <input type='number' id={id} name={id} value={selectedValue} onChange={(e) => changeHandler(e.target.id, e.target.value)} />
            }
            {type === 'colorpallette' &&
              <ColorPalette key={id} colors={COLOR_DEFAULT_SET} id={id} selectedValue={selectedValue} onSelection={(e) => changeHandler(e.id, e.value)} />
            }
          </div>
        </div>
      )
    })
    body.push(<div className={`${styles.bottomLine} ${arr.length - 1 === index ? styles.fullBottomLine : ''}`} />)
    return body
  })

  const getConfigurationScreen = () => {
    return (
      <div className={styles.sections2}>
        <div className={styles.topSection2}>
          <div className={styles.topSectionHeader2}>{props.isMobile ? 'Indicator Settings' : 'Indicators'}</div>
          <div>
            <button onClick={handleExit} className={styles.crossButton}>
              <span>
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  className={styles.crossButton2}
                />
              </span>
            </button>
          </div>
        </div>
        <div className={styles.scroll2}>
          <div className={styles.itemsListContainer}>{getItems}</div>
        </div>
        <div className={styles.lineAboveSave} />
        <div className={`${styles['button-container']} ${styles['buttons']}`}>
          <Button
            clickHandler={handleExit}
            className={`${styles['reset']}`}
            labelText='Cancel'
            tabIndex='0'
          />
          <Button
            clickHandler={handleApply2}
            className={`${styles['apply']}`}
            labelText='Apply'
            tabIndex='0'
            buttonDataTestId='indicatorConfigurationApply'
          />
        </div>
      </div>
    )
  }
  const getConfigurationScreenMobile = () => {
    return (
      <div className={styles.sections2}>
        <div className={styles.topSection2}>
          <div className={styles.topSectionHeader2}>{props.isMobile ? 'Indicator Settings' : 'Indicators'}</div>
          <div>
            <button data-testid='closeId' onClick={handleExit}>
              <span>
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  className={styles.crossButton2}
                />
              </span>
            </button>
          </div>
        </div>
        <div className={styles.scroll2}>
          <div className={styles.itemsListContainer}>{getItems}</div>
        </div>
        <footer className={styles.bottomAlign}>
          <div className={styles.lineAboveSave} />
          <div className={`${styles['button-container']} ${styles['buttons']}`}>
            <Button
              clickHandler={handleApply2}
              className={`${styles['apply']}`}
              labelText='Apply'
              tabIndex='0'
              buttonDataTestId='indicatorConfigurationApplyMobile'
            />
          </div>
        </footer>
      </div>
    )
  }

  return (
    <div className={`${props.isMobile ? styles['indicatorConfigurationMobile'] : styles['indicatorConfiguration']}`}>
      { showCancelScreen ? <div className={styles.CancelPopup}>
        <ModalPopUp height={'157px'} width={'300px'} hideClose name={'IndicatorCancelPopup'}>
          <IndicatorCancelPopup conformation={onConfirmation} />
        </ModalPopUp>
      </div>
        : props.isMobile ? getConfigurationScreenMobile() : getConfigurationScreen()
      }
    </div>
  )
}

IndicatorConfiguration.propTypes = {
  selectedItems: PropTypes.array,
  onSelection: PropTypes.func,
  closePopup: PropTypes.func,
  isMobile: PropTypes.bool,
  chart: PropTypes.object,
  handleReset: PropTypes.func,
  showDropDownTypeMenu: PropTypes.bool
}
export default IndicatorConfiguration
