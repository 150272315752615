import Axios from './AxiosWrapper'

import {
  KEY_ACCESS_TOKEN,
  URLS
} from '../utils/appConstants'

import {
  EVENT_NAMES
} from '../utils/f2Constants'
import { isDebugInfoOn, logDebugInfo } from '../utils/utilities'
import { showDebugInfoTransform } from '../utils/showDebugInfoTransform'

/**
 * Method used to get a data request URL
 * @param {String} path [Data API endpoint path with prepended '/']
 * @return {String} [Fully qualified data request URL]
 */
const geRequestUrlWithParams = (path, params) => {
  if (!path) return null
  let baseUrl = window.MD.API_URL

  if (path.includes('pershing-netxwealth-api') && (isSubstringExistInHostname('services.mdgapp.net') || isSubstringExistInHostname('localhost'))) {
    switch (window.MD.ENV) {
      case 'localhost':
      case 'acceptance':
      case 'acceptance-2':
        baseUrl = 'https://api.qa.services.mdgapp.net/'
        break;
      case 'production':
        if (isSubstringExistInHostname('ptc')) {
          baseUrl = 'https://api.ptc.services.mdgapp.net/'
        } else {
          baseUrl = 'https://api.ctc.services.mdgapp.net/'
        }
        break;
      case 'development':
        baseUrl = 'https://api.dev.services.mdgapp.net/'
    }
  }

  if (!baseUrl) return null

  let dataRequestUrl = baseUrl + path

  // check if we have urls params than add those in the URL
  if (params && params instanceof Object) {
    dataRequestUrl += dataRequestUrl.indexOf('?') > -1 ? '&' : '?' + Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')
  }
  return dataRequestUrl
}

const isSubstringExistInHostname = (subString) => {
  return window.location.hostname.includes(subString);
}

/**
 * Method used to retrieve an access token
 * @return {String} [The access token]
 */
export const getAccessToken = () => sessionStorage.getItem(KEY_ACCESS_TOKEN)

/**
 * Emit INVALID_ACCESS_TOKEN F2 Event
 */
export const emitInvalidAccessTokenF2Event = (invalidAccessToken) => {
  logDebugInfo(invalidAccessToken ? 'MOD - INVALID ACCESS TOKEN!' : 'MOD - TOKEN NOT AVAILABLE!')
  logDebugInfo('MOD - Emitting: window.F2.Events.emit("' + EVENT_NAMES.INVALID_ACCESS_TOKEN + '")')

  window.F2 && window.F2.Events.emit(EVENT_NAMES.INVALID_ACCESS_TOKEN)
}

/**
 * Method used to execute a data request
 * @param {String} url [Fully qualified URL for a data request]
 * @param {String} method [Request method, defaults to 'GET']
 * @param {Function} callback [Callback to which a response will be passed]
 * @param {Object} postData [Data to be included in body of POST request]
 */
export const executeDataRequest = (url, method, cancelToken, callback, params, postData, headers, responseType, requestInput) => {
  if (!callback) return

  if (!url) {
    callback(RESPONSE_BAD_REQUEST)
    return
  }

  let dataRequestUrl = geRequestUrlWithParams(url, params)

  if (!dataRequestUrl) {
    callback(RESPONSE_BAD_REQUEST)
    return
  }

  const config = {
    data: postData,
    method: method || 'GET',
    url: dataRequestUrl,
    responseType: responseType
  }
  const accessToken = getAccessToken()

  // Add Authorization header if access token exists
  if (accessToken) {
    config.headers = {
      Authorization: 'Bearer ' + accessToken
    }
  } else {
    // token not available, than emit
    emitInvalidAccessTokenF2Event()
    return
  }

  if (headers && headers instanceof Object) {
    if (!config.headers) {
      config.headers = {}
    }
    Object.assign(config.headers, headers)
  }

  if (cancelToken && cancelToken.token) {
    config.cancelToken = cancelToken.token
  }

  if (isDebugInfoOn()) {
    config.params = config.params || {}
    config.params['..showDebugData..'] = 'on'

    config.transformResponse = config.transformResponse || []
    config.transformResponse.push(showDebugInfoTransform)
  }

  return Axios
    .request(config)
    .then(response => {
      let responseData = response && response.data

      if (responseData && responseType !== 'blob') {
        responseData = responseData.data
      }

      let dataResponse = {
        error: null,
        data: responseData || null,
        isError: false,
        isDataRequestComplete: true
      }

      requestInput && Object.assign(dataResponse, {requestInput: requestInput})

      callback(dataResponse)

      return dataResponse
    })
    .catch(error => {
      const isUnauthorized = error && error.response && error.response.status === 401

      // Emit F2 event requesting an update to access token
      isUnauthorized && emitInvalidAccessTokenF2Event(true)

      // Only execute callback if request was not cancelled
      let errorResponse = {
        data: null,
        isError: true,
        error: error.response,
        isDataRequestComplete: true
      }

      if (isUnauthorized) {
        errorResponse.isDataRequestComplete = false
      }
      requestInput && Object.assign(errorResponse, {requestInput: requestInput})
      !Axios.isCancel(error) && callback(errorResponse)

      return errorResponse
    })
}

/**
* RESPONSE_BAD_REQUEST
*/
const RESPONSE_BAD_REQUEST = {
  status: 400,
  isError: true,
  isDataRequestComplete: true,
  statusText: 'Bad Request'
}

/**
* Validation Script
* XREF URL: xref/v1/symbols
* Sample /xref/v1/symbols/AAPL
* Fetching Symbol which is item 4 and would be in array index 3
*/
const inputsValidation = (path) => {
  let isValid = true
  if ((path.includes(URLS.PLATFORM.XREF)) || (path.includes(URLS.PLATFORM.XREFGETSYMBOL)) || (path.includes(URLS.PLATFORM.XREFPREDICTIVESEARCH))) {
    const items = path.split('/')
    if (items.length >= 4) {
      if (!items[3] || (items[3] === 'undefined') || (items[3].trim() === '')) {
        isValid = false
      }
    }
  }
  return isValid
}
/**
 * Method used to execute a data request via an API path
 * @param {String} path [API path]
 * @param {Object} inputs [Request inputs
 * {
 *  method: 'GET',
 *  params: Object, URL parameters as key value pairs
 *  postData: Object, Data to be included in body of POST request
 * }]
 * @param {Function} callback [Callback to which a response will be passed]
 * @param {Object} params [URL parameters as key value pairs]
 */
const execute = (path, inputs, callback, cancelToken, headers) => {
  if (!callback) {
    callback = () => {}
  }

  if (!path) {
    callback(RESPONSE_BAD_REQUEST)
    return
  }

  if (!inputsValidation(path)) {
    callback(RESPONSE_BAD_REQUEST)
    return
  }

  if (!inputs) {
    inputs = {}
  }

  return executeDataRequest(
    path,
    inputs.method,
    cancelToken,
    callback,
    inputs.params,
    inputs.postData,
    headers,
    inputs.responseType,
    inputs.requestInput
  )
}
/**
* Method used to retrieve a cancel token
* Components can request more than one token
* @return {Object} [Contains a token that identifies the request and a cancel method]
*/
export const getCancelToken = () => Axios.CancelToken.source()

export default {
  execute,
  getAccessToken,
  getCancelToken
}
