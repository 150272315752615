import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DATE_FORMAT } from '../../../utils/appConstants'
import styles from './ChartSymbolPriceInfo.module.scss'

export default function ChartSymbolPriceInfo ({chart, reset, xid}) {
  const [data, setData] = useState({ date: '', open: '', high: '', low: '', close: '' })

  useEffect(() => {
    chart.eventEmitter.on('CROSSHAIR_MOVE', data => {
      let performaneChartDateInfo = new Date(data.date)
      let symbolListCount = Object.keys(chart.data).length
      setData({ date: moment(performaneChartDateInfo).format(DATE_FORMAT.MONTH_DAY_YEAR_LOCALE), open: symbolListCount > 1 ? chart.getActualFromNormalized(data.open) : data.open, high: symbolListCount > 1 ? chart.getActualFromNormalized(data.high) : data.high, low: symbolListCount > 1 ? chart.getActualFromNormalized(data.low) : data.low, close: symbolListCount > 1 ? chart.getActualFromNormalized(data.close) : data.close })
    })
  }, [reset])

  useEffect(() => {
    if (chart.data && chart.data[xid]) {
      let chartInitialInfo = chart.data[xid].price[Object.keys(chart.data[xid].price)[0]]
      let performaneChartDateInfo = new Date(chartInitialInfo[chartInitialInfo.length - 1].date)
      let symbolListCount = Object.keys(chart.data).length
      setData({ date: moment(performaneChartDateInfo).format(DATE_FORMAT.MONTH_DAY_YEAR_LOCALE), open: symbolListCount > 1 ? chart.getActualFromNormalized(chartInitialInfo[chartInitialInfo.length - 1].open) : chartInitialInfo[chartInitialInfo.length - 1].open, high: symbolListCount > 1 ? chart.getActualFromNormalized(chartInitialInfo[chartInitialInfo.length - 1].high) : chartInitialInfo[chartInitialInfo.length - 1].high, low: symbolListCount > 1 ? chart.getActualFromNormalized(chartInitialInfo[chartInitialInfo.length - 1].low) : chartInitialInfo[chartInitialInfo.length - 1].low, close: symbolListCount > 1 ? chart.getActualFromNormalized(chartInitialInfo[chartInitialInfo.length - 1].close) : chartInitialInfo[chartInitialInfo.length - 1].close })
    }
  }, [chart])

  return (
    <div className={styles.chartSymbolPriceInfo}>
      <div className={styles.chartSymbolPriceItem}>{data.date}</div>
      <div className={styles.chartSymbolPriceItem}>Open {data.open}</div>
      <div className={styles.chartSymbolPriceItem}>High {data.high}</div>
      <div className={styles.chartSymbolPriceItem}>Low {data.low}</div>
      <div className={styles.chartSymbolPriceItem}>Close {data.close}</div>
    </div>
  )
}

ChartSymbolPriceInfo.propTypes = {
  chart: PropTypes.object,
  reset: PropTypes.bool,
  xid: PropTypes.number
}
