'use strict'
import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'
import Popover from '../Popover/Popover'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './LineDropdown.module.scss'
import Button from '../Button/Button'

const LineDropdown = ({onSelection, id, selectedValue, popOverStyle, name}) => {
  const uniqueId = `line_dropdown_${(id)}`
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)

  const [selectedLineStyle, setSelectedLineStyle] = useState(selectedValue || 'solid-thin')

  const togglePopover = () => {
    setIsOpen(!isOpen)
  }

  const setSelection = data => {
    setSelectedLineStyle(data)
    onSelection(data)
  }

  const getLinesBody = <div className={styles.linesContainer}>
    <Button buttonDataTestId= {'solid-thin'} clickHandler={() => setSelection('solid-thin')} tabIndex='0' data-line='solid-thin' className={styles['solid-thin']} />
    <Button buttonDataTestId= {'solid-medium'} clickHandler={() => setSelection('solid-medium')} tabIndex='0' data-line='solid-medium' className={styles['solid-medium']} />
    <Button buttonDataTestId= {'solid-large'} clickHandler={() => setSelection('solid-large')} tabIndex='0' data-line='solid-large' className={styles['solid-large']} />
    <Button buttonDataTestId= {'dotted-thin'} clickHandler={() => setSelection('dotted-thin')} tabIndex='0' data-line='dotted-thin' className={styles['dotted-thin']} />
    <Button buttonDataTestId= {'dotted-medium'} clickHandler={() => setSelection('dotted-medium')} tabIndex='0' data-line='dotted-medium' className={styles['dotted-medium']} />
    <Button buttonDataTestId= {'dotted-large'} clickHandler={() => setSelection('dotted-large')} tabIndex='0' data-line='dotted-large' className={styles['dotted-large']} />
  </div>

  const setSelectedLine = () => {
    return (
      <span data-line={`${selectedLineStyle}`} className={`${styles.lineButton} ${styles[selectedLineStyle]}`}>&nbsp;</span>
    )
  }

  return (
    <React.Fragment>
      <button
        data-testid={`button_${uniqueId}`}
        id={id}
        aria-label={id}
        type='button'
        ref={ref}
        onClick={togglePopover}
        tabIndex='0'
        name={name}
        className={styles.customSelectContainer} >
        {setSelectedLine()}
        {
          !isOpen ? <FontAwesomeIcon icon={[('fas'), ('caret-down')]} color='black' className={styles.icon} />
            : <FontAwesomeIcon icon={[('fas'), ('caret-up')]} color='black' className={styles.icon} />
        }
      </button>
      {
        isOpen && <div className={popOverStyle || styles.popoverContainer}>
          <Popover
            id={uniqueId}
            closePopover={togglePopover}
            body={getLinesBody}
            referenceElement={ref.current}
            showPointingArrow={false}
            placement={'bottom'}
          />
        </div>
      }
    </React.Fragment>
  )
}

LineDropdown.propTypes = {
  popOverStyle: PropTypes.string,
  selectedValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onSelection: PropTypes.func
}

export default LineDropdown
