'use strict'

import { useState, useEffect } from 'react'
import DataRequest from '../services/DataRequest'
import { URLS } from '../utils/appConstants'
import {getRealtimeHeader} from '../utils/utilities'

export const useQuote = (venueXid, isRealTime = false, userInfo) => {
  const [quote, setQuote] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)

  useEffect(() => {
    let callback = (response) => {
      if (response.isError) {
        setError(true)
      }
      if (response.data) {
        let data =
          response &&
          response.data.quotes &&
          response.data.quotes.length > 0 &&
          response.data.quotes[0].data
        setQuote(data)
      }
      setLoading(false)
    }

    DataRequest.execute(
      URLS.PLATFORM.QUOTE,
      {
        params: {
          venueXids: venueXid,
          marketHoursTradeType: 'normal',
          clearPremarketQuote: false
        }
      },
      callback,
      null,
      getRealtimeHeader(isRealTime, userInfo)
    )
  }, [venueXid])
  return {
    quote,
    isLoading,
    isError
  }
}
