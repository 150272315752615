import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ModalPopUp from '../Lib/ModalPopUp/ModalPopUp'
import Loader from '../Lib/common/Loader'
import { getDeviceType, hideScroll, handleWindowResize } from '../../utils/utilities'
import styles from './Watchlist.module.scss'
import WatchlistCreateHeader from './WatchlistCreateHeader'
import WatchlistCreateBody from './WatchlistCreateBody'
import ExitAlert from '../Lib/ExitAlert/ExitAlert'

function Watchlist ({action, onClose, watchlistData, watchListHoldingData, symbol, exchange}) {
  const [isReady] = useState(true)
  const [device, setDevice] = useState(getDeviceType())
  const [closeAlertScreen, setCloseAlertScreen] = useState(false)

  useEffect(() => {
    handleWindowResize(resizeHandler)
  }, [])
  function resizeHandler (device) {
    setDevice(device)
  }
  const closePopup = (e) => {
    setCloseAlertScreen(true)
    hideScroll(false)
  }
  function onConfirmation (action) {
    setCloseAlertScreen(false)
    if (action === 'Yes') {
      onClose('Yes')
    }
  }

  function getLoader () {
    return (
      <div className={styles.watchlistContainer}>
        <div className={styles.popupScreen}>
          <ModalPopUp customHeader onClose={closePopup}>
            <WatchlistCreateHeader onClose={closePopup} isEdit={watchlistData} />
            <Loader spinnerSize='2x' isReady={false} spinnerStyle={{'width': '375px', 'height': '588px'}} />
          </ModalPopUp>
        </div>
      </div>)
  }
  return (
    <Loader ready={isReady} spinnerSize='2x' >
      <div className={styles.watchlistContainer}>
        { action === 'Create' && <div>

          <div className={styles.popupScreen}>
            {(watchlistData && !watchlistData.isLoaded) ? getLoader()
              : <ModalPopUp customHeader customBody stylesContent={{width: '480px'}} >
                <WatchlistCreateHeader onClose={closePopup} isEdit={watchlistData} />
                <WatchlistCreateBody device={device} onClose={onConfirmation} watchlistData={watchlistData} watchListHoldingData={watchListHoldingData || []} symbol={symbol} exchange={exchange} />
              </ModalPopUp>
            }
          </div>
        </div>
        }
        {closeAlertScreen &&
          <ExitAlert confirmation={onConfirmation} yesLabelName={'Confirm'} noLabelName={'Cancel'} message={'Are you sure you want to close? Your progress will not be saved.'} />
        }
      </div>
    </Loader>
  )
}
Watchlist.propTypes = {
  action: PropTypes.string,
  onClose: PropTypes.func,
  watchlistData: PropTypes.object,
  watchListHoldingData: PropTypes.array,
  symbol: PropTypes.string,
  exchange: PropTypes.string
}
export default Watchlist
