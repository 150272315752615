'use strict'

import React, { useState } from 'react'
import styles from './TimeRange.module.scss'
import ModalPopUp from '../../../Lib/ModalPopUp/ModalPopUp'
import timePeriods from '../../../../data/ChartTimePeriods.json'
import ChartsMobileHeader from '../../../Lib/ChartsMobileHeader/ChartsMobileHeader'
import ChartsTimeMobileFooter from '../ChartsTimeMobileFooter/ChartsTimeMobileFooter'
import RadioButton from '../../../Lib/RadioButton/RadioButton'
import PropTypes from 'prop-types'

export default function TimeRange ({closeTimeRange, activeTimeRange}) {
  const [selectedTimeRange, setSelectedTimeRange] = useState(activeTimeRange)

  const timeRangeChangeHandler = (e) => {
    setSelectedTimeRange(e.target.name)
  }

  return (<ModalPopUp customHeader customBody>
    <ChartsMobileHeader headerText='Time Range' closeHandler={() => { closeTimeRange('') }} headerDataTestId='timeRangeCloseTestId' />
    <div>
      {timePeriods.map((period, index) => {
        return <div key={'timeRange_' + index} className={styles.timeRangeInput}>
          <RadioButton key={index} tabIndex={0} label={period.text} isChecked={selectedTimeRange === period.text} onChange={timeRangeChangeHandler} />
        </div>
      })}
      <ChartsTimeMobileFooter applyHandler={() => { closeTimeRange(selectedTimeRange) }} resetHandler={() => { setSelectedTimeRange('1 Year') }} />
    </div>
  </ModalPopUp>)
}
TimeRange.propTypes = {
  activeTimeRange: PropTypes.string,
  closeTimeRange: PropTypes.func
}
