/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Options from '../Options/Options'
import styles from './SavedChartsDropdown.module.scss'
export default function SavedChartsDropdown (props) {
  let data = [...props.data]

  const [options, setOptions] = useState(false)
  const [chartName, setChartName] = useState('')
  // Returns html of dropdown with selection
  function BindDropDownValues () {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length - 1; i++) {
        if (data[i].isDefault) {
          let savedChart = data[0]
          data[0] = data[i]
          data[i] = savedChart
        }
      }
      return data.map((item, index) => (
        <li
          tabIndex={index + 1}
          id={item.id}
          data-testid={item.id}
          key={index}
          title={item.chartName}
        >
          <button className={styles.chartName} onClick={() => { props.clickHandler(item.chartName, 'ChartItemClick') }}>{item.chartName}</button>
          <button className={styles.chartNameSett} onClick={() => { clickHandler(item.chartName) }} onKeyDown={() => { clickHandler(item.chartName) }}>{item.isDefault ? 'Default' : ''}
            <span className={styles.icon}> ...</span></button>
        </li>
      ))
    } else {
      return null
    }
  }

  const clickHandler = (text) => {
    setOptions(!options)
    setChartName(text)
  }

  const optionClickHandler = (type) => {
    setOptions(!options)
    props.clickHandler(chartName, type)
  }

  return (
    <React.Fragment>
      <div className={styles.saveChartContainer} data-custom-event-container='custom-event-select-container'>
        <div className={styles.basicDropDownValueContainer}>
          <ul className={styles.ul}>
            {BindDropDownValues()}
          </ul>
        </div>
      </div>
      {options && <Options handler={optionClickHandler} />}
    </React.Fragment>
  )
}
SavedChartsDropdown.propTypes = {
  data: PropTypes.array,
  clickHandler: PropTypes.func
}
