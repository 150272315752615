import React from 'react'
import PropTypes from 'prop-types'
import styles from './RadioButton.module.scss'

export default function RadioButton ({label, isChecked, onChange, tabIndex}) {
  const id = `radio-${label}`
  return (
    <div className={styles.radioButtonContainer}>
      <div className={styles.radio}>
        <input id={id} data-testid={label} name={label} type='radio' checked={isChecked} onChange={onChange} tabIndex={tabIndex} />
        <label htmlFor={id} className={styles.radiolabel}>{label}</label>
      </div>
    </div>
  )
}
RadioButton.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number
}
