/* eslint-disable no-unused-vars */
'use strict'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './FundCompare.module.scss'
import AddComparisonCard from '../Shared/AddComparisonCard/AddComparisonCard'
import useSlider from '../Shared/CustomHook/Slider'
import ModalService from '../Shared/Modal/service/ModalService'
import AddCompareModal from '../Shared/AddCompareModal/AddCompareModal'
import MaxComparePopUp from '../Shared/MaxComparePopUp/MaxComparePopUp'
import ModalRoot from '../Shared/Modal/components/ModalRoot'
import QuoteCard from '../Shared/QuoteCardWithStockHighlights/QuoteCardWithStockHighlights'
import NavTabControl from '../Shared/NavTabControl/NavTabControl'
import Table from '../Shared/Table/Table'
import FundCompareTableHeader from '../FundCompare/FundCompareTableHeader/FundCompareTableHeader.json'
import ViewShareClassModal from '../Shared/ViewShareClassModal/ViewShareClassModal'
import {
  URLS, DEFAULT_FUNDSCOMPARE_INPUTS, FUND_SCREEN_TYPE, FundCompareTableFieldDictionary, KeyMeauresTableFieldDictionary,
  OperationalTableFieldDictionary, ProfileTableFieldDictionary, AssetAllocationTableFieldDictionary, IncomeTableFieldDictionary,
  PerformanceTableFieldDictionary, PortfolioCharacteristicsTableFieldDictionary, RiskTableFieldDictionary,
  RatingsTableFieldDictionary, TechnicalIndicatorsTableFieldDictionary, ESGRatingTableFieldDictionary, ESGCarbonMetricsTableFieldDictionary, DATE_FORMAT, FundCompareMillionColumnFormat, FundCompareDateColumnFormat, FundCompareNonMSDateColumnFormat,
  FundNumberColumnFormat, FundCompareNumberFormatterField, FundCompareNumberColumnPrecisionFormat, FundCompareDollarColumnFormat, FundCompareColumnSpecialHandling, FundComparePercentColumnFormat,
  FundComparePreccisionPercentColumnFormat, FundCompareBooleanColumnFormat, FundCompareFreqColumnFormat, FundCompareIndicatorColumnFormat, FundComparePerformanceTabNegativeCell, FundCompareWithoutMsDateColumnFormat, FundTabsConstant, CardTopBorderColors, FundCardTopWidth, MOD_GOTO_PAGES, FundCompareWithYesNoColumnFormat, FundCompareBankCustodyColumnFormat
} from '../../components/utils/appConstants'
import DataRequest from '../../../../../services/DataRequest'
import { addArgument, deviceType, handleWindowResize, getDeviceType, CreateUUID } from '../../components/utils/utilities'
import { addCards, getSpinnerStyle, scrollToTable, getTopPosition } from '../Helpers/CompareCommonFunctions'
import PerformanceOnComparePage from '../Shared/PerformanceOnComparePage/PerformanceOnComparePage'
import { textFormatter, formatNumber, numberFormatter, convertToInternationalCurrencySystem, formatDate, getRatingDescription, TEXT_NULL_VALUE, getYesNoStatus, isBankCustody } from '../utils/formatter'
import { screenerDivFreqDictionary, screenerIndicatorDictionary, screenerBoolDictionary } from '../Helpers/CommonFunctions'
import ScreenerHelper from '../Helpers/ScreenerHelper'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cloneDeep from 'lodash/cloneDeep'
import { emitF2Event } from '../../../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../utils/f2Constants'
import Footer from '../Shared/Disclosures/Footer/Footer'
import PerformanceDisclosure from '../Shared/Disclosures/PerformanceDisclosures/PerformanceDisclosure'
import ImportantDisclosure from '../Shared/Disclosures/ImportantDisclosures/ImportantDisclosure'
import EsgGlossary from '../Shared/ESGGlossary/EsgGlossary'
import FundEsgGlossary from '../Shared/ESGGlossary/FundEsgGlossary.json'
import { getQuoteData } from '../../../../../shared/dataMethods'

function FundCompare (props) {
  const [modelCompareCardData, setModelCompareCardData] = useState([])
  const [modalHeader, setModalHeader] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const cardCountValue = window.location.search.length > 0 ? parseInt(new URLSearchParams(window.location.search).get('cardCount')) : 0
  const [cardCount, setCardCount] = useState(cardCountValue)
  const topPosn = 0
  const defaultCardHeight = '277px'
  const collapsedCardHeight = '97px'
  const cardCountRef = useRef(cardCount)
  const [screenWidth, setScreenWidth] = useState(screen.width)
  const [numberOfCards, setNumberOfCards] = useState(3)
  const resizeOnLoad = useRef(false)
  const modelCompareCardDataRef = useRef(modelCompareCardData)
  const [keyMeasuresData, setKeyMeasuresData] = useState([])
  const [tableHeaders, setTableHeaders] = useState(FundCompareTableHeader.headers)
  const [operationalData, setOperationalData] = useState([])
  const [profileData, setProfileData] = useState([])
  const [assetAllocationData, setAssetAllocationData] = useState([])
  const [incomeData, setIncomeData] = useState([])
  const [performanceData, setPerformanceData] = useState([])
  const [portfolioCharacteristicsData, setPortfolioCharacteristicsData] = useState([])
  const [ratingsData, setRatingsData] = useState([])
  const [riskData, setRiskData] = useState([])
  const [technicalIndicatorsData, setTechnicalIndicatorsData] = useState([])
  const [esgRating, setESGRating] = useState([])
  const [esgCarbonMetrics, setESGCarbonMetrics] = useState([])
  const [navTabActive, setNavTabActive] = useState(FundTabsConstant.KeyMeasures)
  const csvInstance = useRef()
  const [csvData, setCsvData] = useState({ data: [], headers: [], fileName: '' })
  const groupingFormattedTableTypes = [FundTabsConstant.AssetAllocation, FundTabsConstant.Performance, FundTabsConstant.PortfolioCharacteristics, FundTabsConstant.Risk, FundTabsConstant.ESGRating, FundTabsConstant.ESGCarbonMetrics]
  const [venueXidList, setVenueXidList] = useState([])
  const [delayedQuoteDate, setDelayedQuoteDate] = useState(null)
  let deviceTypeMode = deviceType.Desktop
  const [resetQuoteCards, setResetQuoteCards] = useState('')
  const [showGlossary, setShowGlossary] = useState(false)
  const getScrollTop = () => {
    return document.documentElement.scrollTop || document.body.scrollTop
  }

  let setNavTab = (e) => {
    let value = e.currentTarget.innerText || e.currentTarget.textContent
    if (document.getElementById(value)) {
      setNavTabActive(value)
      debounce(() => scrollToTable(value, FundCardTopWidth), 100)
    }
  }

  useSlider('navTab')
  useEffect(() => {
    if (!resizeOnLoad.current) {
      const handleResize = () => {
        setScreenWidth(screen.width)
        let cardsToBeAdded = addCards(cardCountRef.current, numberOfCards, screenWidth)
        setNumberOfCards(cardsToBeAdded)
      }
      window.addEventListener('resize', handleResize)
      resizeOnLoad.current = true
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  useEffect(() => {
    deviceTypeMode = getDeviceType()
    handleWindowResize((_deviceType) => {
      deviceTypeMode = _deviceType
    })
  })

  function DownloadCsv (tableName) {
    let output = []
    let headers = getCsvHeaders()
    let formattedCsvData = []
    const selectedTableData = getCsvExportData(tableName)
    if (groupingFormattedTableTypes.includes(tableName)) {
      selectedTableData.forEach(item => {
        if (item.isGroupedCol) {
          item.data.map(subGroupItem => {
            /* no need to group, just show the columns (Asset Class, Geographic, Equity and Fixed Income) as is
            present under Asset Allocation and Portfolio Characteristics table having grouping data on UI */
            if (tableName === FundTabsConstant.AssetAllocation || tableName === FundTabsConstant.PortfolioCharacteristics) {
              formattedCsvData.push(subGroupItem)
            } else if (tableName === FundTabsConstant.Performance) {
              /* Add the suffix " - Market Return" (for Market Return column) & " - NAV Return" (for NAV Return column)
              present under Performance table having grouping data on UI */
              subGroupItem[''] = subGroupItem[''] + ' - ' + item['']
              formattedCsvData.push(subGroupItem)
            } else {
              /* Add the suffix " - 5 Year" (for 5 Year Risk column) & " - 3 Year" (for 3 Year Risk column)
              present under Risk table having grouping data on UI */
              const txt3Year = '3 Year'
              const txt5Year = '5 Year'
              if (item[''].includes(txt5Year) && !subGroupItem[''].includes(txt5Year)) {
                subGroupItem[''] = subGroupItem[''] + ' - ' + txt5Year
              } else if (item[''].includes(txt3Year) && !subGroupItem[''].includes(txt3Year)) {
                subGroupItem[''] = subGroupItem[''] + ' - ' + txt3Year
              }
              formattedCsvData.push(subGroupItem)
            }
          })
        } else {
          formattedCsvData.push(item)
        }
      })
    } else {
      selectedTableData.forEach(item => {
        if (tableName === FundTabsConstant.Ratings && (item[''] === 'Morningstar Risk Score - Overall' || item[''] === 'Morningstar Performance Score - Overall')) {
          let index = -1
          let cloneItem = cloneDeep(item)
          for (let key in cloneItem) {
            index++
            if (index === 0) continue // skip formatting for category
            cloneItem[key] = getRatingDescription(cloneItem[key])
          }
          formattedCsvData.push(cloneItem)
        } else {
          formattedCsvData.push(item)
        }
      })
    }
    output = [...output, ...formattedCsvData]
    setCsvData({ data: output, headers, fileName: tableName + '.csv' })
    setTimeout(function () {
      csvInstance.current.link.click()
    }, 100)
  }

  function getCsvHeaders () {
    let headers = []
    // push empty for first csv header
    headers.push({
      label: '',
      key: ''
    })
    modelCompareCardData.forEach(card => {
      headers.push({
        // add prefix card.symbol in card.name to get header name
        label: card.OffshoreFlex || card.OffshoreFlex === 'Yes' ? card.name : card.symbol + ' ' + card.name,
        key: card.name
      })
    })
    return headers
  }

  function handleDownloadCsv (e, tableName) {
    if (e.key === 'Enter') {
      debounce(function () { DownloadCsv(tableName) }, 1000)
    }
  }

  function getCsvExportData (tableName) {
    switch (tableName) {
      case FundTabsConstant.KeyMeasures:
        return keyMeasuresData
      case FundTabsConstant.Operational:
        return operationalData
      case FundTabsConstant.Profile:
        return profileData
      case FundTabsConstant.AssetAllocation:
        return assetAllocationData
      case FundTabsConstant.Income:
        return incomeData
      case FundTabsConstant.Performance:
        return performanceData
      case FundTabsConstant.PortfolioCharacteristics:
        return portfolioCharacteristicsData
      case FundTabsConstant.Ratings:
        return ratingsData
      case FundTabsConstant.Risk:
        return riskData
      case FundTabsConstant.TechnicalIndicators:
        return technicalIndicatorsData
      case FundTabsConstant.ESGRating:
        return esgRating
      case FundTabsConstant.ESGCarbonMetrics:
        return esgCarbonMetrics
    }
  }

  const getPositions = () => {
    const positions = {
      keyMeasures: getTopPosition(FundTabsConstant.KeyMeasures, FundCardTopWidth),
      operational: getTopPosition(FundTabsConstant.Operational, FundCardTopWidth),
      profile: getTopPosition(FundTabsConstant.Profile, FundCardTopWidth),
      assetAllocation: getTopPosition(FundTabsConstant.AssetAllocation, FundCardTopWidth),
      income: getTopPosition(FundTabsConstant.Income, FundCardTopWidth),
      performance: getTopPosition(FundTabsConstant.Performance, FundCardTopWidth),
      portfolioCharacteristics: getTopPosition(FundTabsConstant.PortfolioCharacteristics, FundCardTopWidth),
      ratings: getTopPosition(FundTabsConstant.Ratings, FundCardTopWidth),
      risk: getTopPosition(FundTabsConstant.Risk, FundCardTopWidth),
      technicalIndicators: getTopPosition(FundTabsConstant.TechnicalIndicators, FundCardTopWidth),
      esgRating: getTopPosition(FundTabsConstant.ESGRating, FundCardTopWidth),
      esgCarbonMetrics: getTopPosition(FundTabsConstant.ESGCarbonMetrics, FundCardTopWidth)
    }
    return positions
  }

  const getTabValue = (scrollTop, positions) => {
    const tabRanges = [
      { start: positions.keyMeasures, end: positions.operational, tab: FundTabsConstant.KeyMeasures },
      { start: positions.operational, end: positions.profile, tab: FundTabsConstant.Operational },
      { start: positions.profile, end: positions.esgRating, tab: FundTabsConstant.Profile },
      { start: positions.esgRating, end: positions.esgCarbonMetrics, tab: FundTabsConstant.ESGRating },
      { start: positions.esgCarbonMetrics, end: positions.assetAllocation, tab: FundTabsConstant.ESGCarbonMetrics },
      { start: positions.assetAllocation, end: positions.income, tab: FundTabsConstant.AssetAllocation },
      { start: positions.income, end: positions.performance, tab: FundTabsConstant.Income },
      { start: positions.performance, end: positions.portfolioCharacteristics, tab: FundTabsConstant.Performance },
      { start: positions.portfolioCharacteristics, end: positions.ratings, tab: FundTabsConstant.PortfolioCharacteristics },
      { start: positions.ratings, end: positions.risk, tab: FundTabsConstant.Ratings },
      { start: positions.risk, end: positions.technicalIndicators, tab: FundTabsConstant.Risk },
      { start: positions.technicalIndicators, end: Infinity, tab: FundTabsConstant.TechnicalIndicators }
    ];

    const selectedTab = tabRanges.find(range => scrollTop >= range.start && scrollTop < range.end);

    return selectedTab ? selectedTab.tab : FundTabsConstant.KeyMeasures;
  };

  const setHeightForTopZero = () => {
    document.querySelectorAll('#addComparisionOuterDiv').forEach(function (e) {
      e.style.height = defaultCardHeight
    })
    document.querySelectorAll('#addComparison').forEach(function (e) {
      e.style.height = defaultCardHeight
    })
    if (document.getElementById('PerformanceOnComparePage')) {
      document.getElementById('PerformanceOnComparePage').style.display = ''
    }
    document.querySelectorAll('#blankBorder')?.forEach(function (e) {
      e.style.height = defaultCardHeight
    })
    document.querySelectorAll('[data-testid=QuoteCardContainer]')?.forEach(function (e) {
      e.style.height = defaultCardHeight
    })
    document.querySelectorAll('[name=hideScroll]').forEach(function (e) {
      e.style.display = ''
    })
    if (cardCount === 0 && document.querySelectorAll('[name=zeroAddComparisionCard]')?.length) {
      document.querySelectorAll('[name=zeroAddComparisionCard]')[0].style.paddingRight = '9px'
      document.querySelectorAll('[name=zeroAddComparisionCard]')[0].style.zIndex = '0'
      if (document.getElementById('delayedQuoteInfo')) { document.getElementById('delayedQuoteInfo').style.zIndex = '0' }
    }
    let topHeight = deviceTypeMode === deviceType.Ipad ? '14px' : '12px'
    document.querySelectorAll('[name=quoteCard]').forEach(function (e) {
      e.setAttribute('cardtype', 'openCard')
    })
    document.querySelectorAll('[name=comparisonWithQuote]').forEach(function (e) {
      e.style.top = topHeight
    })
  }

  const adjustHeight = (scrollTop) => {
    if (scrollTop === 0) {
      setHeightForTopZero()
    } else {
      document.querySelectorAll('#addComparisionOuterDiv').forEach(function (e) {
        e.style.height = collapsedCardHeight
      })
      document.querySelectorAll('#addComparison').forEach(function (e) {
        e.style.height = collapsedCardHeight
      })
      document.querySelectorAll('#blankBorder')?.forEach(function (e) {
        e.style.height = collapsedCardHeight
      })

      document.querySelectorAll('[data-testid=QuoteCardContainer]').forEach(function (e) {
        e.style.height = collapsedCardHeight
      })
      document.querySelectorAll('[name=hideScroll]').forEach(function (e) {
        e.style.display = 'none'
      })
      document.querySelectorAll('[name=MOC]').forEach(function (e) {
        e.style.marginBottom = '8px'
      })
      document.querySelectorAll('[name=TPMOC]').forEach(function (e) {
        e.style.marginBottom = '8px'
      })
      if (cardCount === 0 && document.querySelectorAll('[name=zeroAddComparisionCard]')?.length) {
        document.querySelectorAll('[name=zeroAddComparisionCard]')[0].style.paddingRight = '9px'
        let cardFixedIndex = document.querySelectorAll('[name=zeroAddComparisionCard]')[0].getBoundingClientRect().top === 0 ? '99999' : '0'
        document.querySelectorAll('[name=zeroAddComparisionCard]')[0].style.zIndex = cardFixedIndex
        if (document.getElementById('delayedQuoteInfo')) { document.getElementById('delayedQuoteInfo').style.zIndex = cardFixedIndex }
      }
      let topHeight = deviceTypeMode === deviceType.Desktop ? '40px' : '38px'
      document.querySelectorAll('[name=quoteCard]').forEach(function (e) {
        e.setAttribute('cardtype', 'collapsedCard')
      })
      document.querySelectorAll('[name=comparisonWithQuote]').forEach(function (e) {
        e.style.top = topHeight
      })
    }
  }

  let handleScroll = () => {
    if (document.getElementById('tableSection')) {
      let tabValue = FundTabsConstant.KeyMeasures
      let scrollTop = Math.ceil(getScrollTop())
      if (scrollTop > 0) {
        tabValue = getTabValue(scrollTop, getPositions())
      }
      adjustHeight(scrollTop)
      setNavTabActive(tabValue)
    }
  }
  let debounce = (method, delay) => {
    if (!method) return
    clearTimeout(method._tId)
    method._tId = setTimeout(function () {
      method()
    }, delay)
  }

  useEffect(() => {
    modelCompareCardDataRef.current = modelCompareCardData
    setTableHeadersMap()
    handleScroll()
    document.addEventListener('scroll', function (event) {
      debounce(handleScroll, 100)
    }, false)

    return () => {
      document.removeEventListener('scroll', function (event) {
      }, false)
    }
  }, [modelCompareCardData])

  useEffect(() => {
    let updatedModelCompareCardData = JSON.parse(sessionStorage.getItem('fundCompareData') || '[]')
    if (props.xid) {
      if (props.name && !updatedModelCompareCardData.some(res => res.name === props.name) && updatedModelCompareCardData.length < 10) {
        updatedModelCompareCardData.push({
          'type': props.type,
          'venueXid': props.xid.toString(),
          'symbol': props.symbol,
          'name': props.name,
          'IsFVETF': props.IsFVETF,
          'CUSIP': props.CUSIP,
          'cardTopBorderColor': '#004480',
          'OffshoreFlex': props['OffshoreFlex'],
          'fundType': props.fundType
        })
      }
      addFundsCompareData(updatedModelCompareCardData)
    }
  }, [props.xid])

  useEffect(() => {
    let updatedModelCompareCardData = JSON.parse(sessionStorage.getItem('fundCompareData') || '[]')
    if (props.compareData && props.compareData.length > 0) {
      props.compareData.map(item => {
        if (item.name && !updatedModelCompareCardData.some(res => res.name === item.name) && updatedModelCompareCardData.length < 10) {
          updatedModelCompareCardData.push({
            'type': item.type,
            'venueXid': item.venueXid.toString(),
            'symbol': item.symbol,
            'name': item.name,
            'IsFVETF': props.IsFVETF,
            'CUSIP': item.CUSIP,
            'cardTopBorderColor': '#004480',
            'OffshoreFlex': item['OffshoreFlex'],
            'fundType': item.fundType
          })
        }
      })
      addFundsCompareData(updatedModelCompareCardData)
    }
    if (!props.xid && props.compareData && props.compareData.length === 0 && updatedModelCompareCardData.length > 0 && updatedModelCompareCardData.length < 10) {
      addFundsCompareData(updatedModelCompareCardData)
    }
  }, [props.compareData])

  function addFundsCompareData (updatedModelCompareCardData) {
    let cardCounter = updatedModelCompareCardData.length
    setCardCount(cardCounter)
    if (cardCounter <= 10) {
      setModelCompareCardData(updatedModelCompareCardData)
      let cardsToBeAdded = addCards(cardCounter, numberOfCards, screenWidth)
      setNumberOfCards(cardsToBeAdded)
      cardCountRef.current = cardCounter
      modelCompareCardDataRef.current = updatedModelCompareCardData
      setTableHeadersMap()
      sessionStorage.setItem('fundCompareData', JSON.stringify(updatedModelCompareCardData))
    }
  }

  function setTableHeadersMap () {
    const distinctWsods = []
    let tableHeaderItems = tableHeaders
    if (modelCompareCardDataRef.current.length > 0) {
      modelCompareCardDataRef.current.forEach(item => {
        distinctWsods.push(item.venueXid.toString())
        let isMatch = tableHeaderItems.some(x => x.wsodIssue === item.venueXid.toString())
        if (!isMatch) {
          let headerItem = {
            'name': item.name,
            'symbol': item.OffshoreFlex || item.OffshoreFlex === 'Yes' ? '' : item.symbol,
            'isSymbolWithName': true,
            'noWrap': 1,
            'control': '',
            'textType': '',
            'textAlign': 'right',
            'separator': true,
            'hideInMobile': false,
            'staticColWidth': 0,
            'isToggledInMobile': false,
            'showCollapseIconInMobile': false,
            'leftPosWithToggle': 0,
            'leftPosWithoutToggle': 114,
            'staticMobileColWidth': 132,
            'staticTabletColWidth': 182,
            'fixedWidth': 219,
            'wsodIssue': item.venueXid.toString(),
            'isFundCompare': true,
            'searchType': item.type,
            'CUSIP': item.CUSIP
          }
          tableHeaderItems.push(headerItem)
        }
      })
      let updatedtableHeaderItems = tableHeaderItems.filter(result => (distinctWsods.indexOf(result.wsodIssue) > -1 || result.wsodIssue === ''))
      setTableHeaders(updatedtableHeaderItems)
      let screenerArguments = [
        addArgument('SPCFWSODIssue', distinctWsods, 'Like')
      ]
      setVenueXidList(distinctWsods)
      const venueXids = distinctWsods.map(Number)
      if (venueXids?.length) {
        getQuote(venueXids, screenerArguments)
      }
    }
  }

  function getQuote (venueXids, screenerArguments) {
    let callback = (response) => {
      if (!response.isError) {
        let responseData = response.data?.quotes

        let latestQuoteDate = null

        responseData.forEach(item => {
          let dataItem = item.data
          if (latestQuoteDate === null || (new Date(dataItem.lastTrade.date) > new Date(latestQuoteDate))) {
            latestQuoteDate = dataItem.lastTrade.date
          }
        })
        setDelayedQuoteDate(formatDate(latestQuoteDate, { UTCtoEST: true, postFix: 'ET', format: DATE_FORMAT.MONTH_DAY_YEAR_TIMEINSEC }))
      }
      getTableData(screenerArguments)
    }
    getQuoteData(venueXids, callback, false)
  }

  function setTableDataSelected (tableName, dataItems) {
    switch (tableName) {
      case FundTabsConstant.KeyMeasures:
        setKeyMeasuresData(dataItems)
        break
      case FundTabsConstant.Operational:
        setOperationalData(dataItems)
        break
      case FundTabsConstant.Profile:
        setProfileData(dataItems)
        break
      case FundTabsConstant.AssetAllocation:
        setAssetAllocationData(dataItems)
        break
      case FundTabsConstant.Income:
        setIncomeData(dataItems)
        break
      case FundTabsConstant.Performance:
        setPerformanceData(dataItems)
        break
      case FundTabsConstant.PortfolioCharacteristics:
        setPortfolioCharacteristicsData(dataItems)
        break
      case FundTabsConstant.Ratings:
        setRatingsData(dataItems)
        break
      case FundTabsConstant.Risk:
        setRiskData(dataItems)
        break
      case FundTabsConstant.TechnicalIndicators:
        setTechnicalIndicatorsData(dataItems)
        break
      case FundTabsConstant.ESGRating:
        setESGRating(dataItems)
        break
      case FundTabsConstant.ESGCarbonMetrics:
        setESGCarbonMetrics(dataItems)
        break
    }
  }

  function formatColumnVal (item, field) {
    const fieldMapping = [
      { condition: FundCompareFreqColumnFormat, handler: val => textFormatter(screenerDivFreqDictionary[val] || val) },
      { condition: FundCompareColumnSpecialHandling, handler: val => textFormatter(ScreenerHelper.updateColumnUIMapping(field, val)) },
      { condition: FundCompareIndicatorColumnFormat, handler: val => val === 'N' ? '--' : textFormatter(screenerIndicatorDictionary[val]) },
      { condition: FundCompareBooleanColumnFormat, handler: val => textFormatter(screenerBoolDictionary[val]) },
      { condition: FundCompareMillionColumnFormat, handler: convertToInternationalCurrencySystem },
      { condition: FundCompareDateColumnFormat, handler: val => formatDate(val, { format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: true }) },
      { condition: FundCompareNonMSDateColumnFormat, handler: val => formatDate(val, { format: DATE_FORMAT.MONTH_DAY_YEAR_DATE, isMsDate: false }) },
      { condition: FundNumberColumnFormat, handler: formatNumber },
      { condition: FundCompareNumberFormatterField, handler: val => formatNumber(val, { negSign: true, posSign: true, precision: 2, parentheses: false }) },
      { condition: FundCompareDollarColumnFormat, handler: val => formatNumber(val, { currencyPreFix: true, currencyPreFixValue: '$' }) },
      { condition: FundCompareNumberColumnPrecisionFormat, handler: numberFormatter },
      { condition: FundComparePercentColumnFormat, handler: val => formatNumber(val, { postFix: true, postFixValue: '%' }) },
      { condition: FundComparePreccisionPercentColumnFormat, handler: val => formatNumber(val, { negSign: true, posSign: true, precision: 2, parentheses: false, postFix: true, postFixValue: '%' }) },
      { condition: FundCompareWithoutMsDateColumnFormat, handler: val => formatDate(val, { format: DATE_FORMAT.MONTH_DAY_YEAR_DATE }) },
      { condition: FundCompareWithYesNoColumnFormat, handler: getYesNoStatus },
      { condition: FundCompareBankCustodyColumnFormat, handler: isBankCustody }
    ];

    const matchedField = fieldMapping.find(map => map.condition.indexOf(field) > -1);
    return matchedField ? matchedField.handler(item[field]) : textFormatter(item[field]);
  }


  const setTableHeadersIfDataExists = (keyMeauresitemToPush, items, keyItem, field) => {
    let isDataExists = items.some(item => item['SPCFWSODIssue'].toString() === keyItem.wsodIssue)
    if (isDataExists) {
      items?.map(item => {
        if (item['SPCFWSODIssue'].toString() === keyItem.wsodIssue) {
          keyMeauresitemToPush[keyItem.name] = formatColumnVal(item, FundCompareTableFieldDictionary[field])
        }
      })
    } else {
      keyMeauresitemToPush[keyItem.name] = '--'
    }
  }

  function setTableData (fieldDictionary, responseData, tableName) {
    let dataItems = []
    fieldDictionary.map(field => {
      let keyMeauresitemToPush = {
        '': field
      }
      tableHeaders.map(keyItem => {
        if (keyItem.name !== '') {
          setTableHeadersIfDataExists(keyMeauresitemToPush, responseData.items, keyItem, field)
        }
      })
    dataItems.push(keyMeauresitemToPush)
  })
  setTableDataSelected(tableName, dataItems)
}

const setGroupedTableHeaders = (items, groupedItem, matchField) => {
  tableHeaders.map(keyItem => {
    if (keyItem.name !== '') {
      let isDataExists = items.some(item => item['SPCFWSODIssue'].toString() === keyItem.wsodIssue)
      if (isDataExists) {
        items?.map(item => {
          if (item['SPCFWSODIssue'].toString() === keyItem.wsodIssue) {
            let fieldtoMatch = FundCompareTableFieldDictionary[matchField]
            // Show values from screener in all tables
            groupedItem[keyItem.name] = formatColumnVal(item, fieldtoMatch)
          }
        })
      } else {
        groupedItem[keyItem.name] = '--'
      }
    }
  })
}

const checkIfDataItemExists = (items, wsodIssue) => items?.some(item => item['SPCFWSODIssue'].toString() === wsodIssue)

const processItem = (item, keyItem, assetAllocationItem, field) => {
  if (item['SPCFWSODIssue'].toString() === keyItem.wsodIssue) {
    assetAllocationItem[keyItem.name] = formatColumnVal(item, FundCompareTableFieldDictionary[field]);
  }
};

const processKeyItem = (keyItem, items, assetAllocationItem, field) => {
  if (keyItem.name === '') return;
  const isDataExists = checkIfDataItemExists(items, keyItem.wsodIssue)
  if (isDataExists) {
    items?.forEach(item => processItem(item, keyItem, assetAllocationItem, field));
  } else {
    assetAllocationItem[keyItem.name] = '--'
  }
}


function setGroupedTableData (fieldDictionary, responseData, tableName) {
  let dataItems = []
  fieldDictionary.map(field => {
    let assetAllocationItem = {
      '': field
    }
    if (typeof field === 'object') {
      let groupedItem = {}
      for (let key in field) {
        assetAllocationItem = {
          '': key,
          'isGroupedCol': true,
          'data': []
        }
        let groupedData = []
        Object.values(field[key]).forEach(matchField => {
          groupedItem = {
            '': matchField
          }
          setGroupedTableHeaders(responseData?.items, groupedItem, matchField)
          groupedData.push(groupedItem)
        })
        assetAllocationItem.data = groupedData
      }
    } else {
      tableHeaders.map(keyItem => processKeyItem(keyItem, responseData.items, assetAllocationItem, field))
    }
    dataItems.push(assetAllocationItem)
  })
  setTableDataSelected(tableName, dataItems)
}

function getTableData (screenerArguments) {
  const screenDataCallback = (response) => {
    if (!response.isDataRequestComplete) return
    if (!response.isError) {
      const responseData = response.data || {}
      setTableData(KeyMeauresTableFieldDictionary, responseData, FundTabsConstant.KeyMeasures)
      setTableData(OperationalTableFieldDictionary, responseData, FundTabsConstant.Operational)
      setTableData(ProfileTableFieldDictionary, responseData, FundTabsConstant.Profile)
      setGroupedTableData(AssetAllocationTableFieldDictionary, responseData, FundTabsConstant.AssetAllocation)
      setTableData(IncomeTableFieldDictionary, responseData, FundTabsConstant.Income)
      setGroupedTableData(PerformanceTableFieldDictionary, responseData, FundTabsConstant.Performance)
      setGroupedTableData(PortfolioCharacteristicsTableFieldDictionary, responseData, FundTabsConstant.PortfolioCharacteristics)
      setTableData(RatingsTableFieldDictionary, responseData, FundTabsConstant.Ratings)
      setGroupedTableData(RiskTableFieldDictionary, responseData, FundTabsConstant.Risk)
      setTableData(TechnicalIndicatorsTableFieldDictionary, responseData, FundTabsConstant.TechnicalIndicators)
      setGroupedTableData(ESGRatingTableFieldDictionary, responseData, FundTabsConstant.ESGRating)
      setGroupedTableData(ESGCarbonMetricsTableFieldDictionary, responseData, FundTabsConstant.ESGCarbonMetrics)
    }
  }

  DataRequest.execute(
    `${URLS.CUSTOM.SCREEN + '?screenType=' + FUND_SCREEN_TYPE}`,
    buildScreenInputs(screenerArguments),
    screenDataCallback
  )
}
const buildScreenInputs = (screenerArguments) => {
  const preScreenInputs = DEFAULT_FUNDSCOMPARE_INPUTS
  preScreenInputs.arguments = screenerArguments || []
  return {
    method: 'POST',
    postData: preScreenInputs
  }
}

useSlider('zeroAddComparisionCard')
function compareBtnHandler (data) {
  let updatedModelCompareCardData = []
  let compareData = [...modelCompareCardData]
  data.map(item => {
    if (!compareData.some(res => res.name === item.Name)) {
      updatedModelCompareCardData.push({
        'type': (item.Type === 'Open-End Mutual Fund') || (item.Type === 'Money Market') || (item.Type === 'Mutual Fund') ? 'FSCMF' : 'FSCETF',
        'venueXid': item.xid,
        'symbol': item.symbol,
        'name': item.Name,
        'IsFVETF': item.IsFVETF,
        'CUSIP': item.CUSIP,
        'cardTopBorderColor': '#004480',
        'OffshoreFlex': item['OffshoreFlex'],
        'fundType': item.CategoryName
      })
    }
  })
  let cardCounter = cardCount + updatedModelCompareCardData.length
  setCardCount(cardCounter)
  compareData = compareData.concat(updatedModelCompareCardData)
  setModelCompareCardData(compareData)
  let cardsToBeAdded = addCards(cardCounter, numberOfCards, screenWidth)
  setNumberOfCards(cardsToBeAdded)
  cardCountRef.current = cardCounter
  sessionStorage.setItem('fundCompareData', JSON.stringify(compareData))
}
function viewShareClassModalHandler (xid, symbol) {
  ModalService.open(ViewShareClassModal, { venueXid: xid }, { symbol }, { cardCount })
  setModalHeader('Share Classes for ' + symbol)
}

function addCompare () {
  if (cardCount === 10) {
    ModalService.open(MaxComparePopUp)
    setModalHeader('')
    setErrorMsg('')
  } else {
    ModalService.open(AddCompareModal, {
      searchType: 'fund',
      searchTextLabel: deviceTypeMode === deviceType.Mobile ? 'Search' : 'Search by ticker, CUSIP or description',
      tableCaption: 'Selected Funds',
      zIndexHandlingReqd: false
    })
    setModalHeader('Add Fund Comparison')
    setErrorMsg('')
  }
}
const closeHandler = () => { }
function overlayKeyHandler (venueXid) {
  let compareData = modelCompareCardDataRef.current
  let updatedModelCompareCardData = compareData.filter(result => result.venueXid.toString() !== venueXid)
  sessionStorage.setItem('fundCompareData', JSON.stringify(updatedModelCompareCardData))
  setCardCount(updatedModelCompareCardData.length)
  setModelCompareCardData(updatedModelCompareCardData)
  let cardsToBeAdded = addCards(updatedModelCompareCardData.length, numberOfCards, screenWidth)
  setNumberOfCards(cardsToBeAdded)
  cardCountRef.current = updatedModelCompareCardData.length
  modelCompareCardDataRef.current = updatedModelCompareCardData
  setTableHeadersMap()
  let dataEmit = {}
  dataEmit = {
    gotoPage: MOD_GOTO_PAGES.fund, // Option can be funds, model-marketplace, alternative-investments  ---  3rd Level Navigation tabs own by Pershing
    activeTabKey: 'compare',
    compareData: updatedModelCompareCardData
  }
  console.log(dataEmit)
  emitF2Event(EVENT_NAMES.SYMBOL_CHANGED_EVENT, dataEmit)
}

function isDisplay () {
  if (modelCompareCardDataRef.current.length > 0) {
    return styles.isSectionDisplay
  }
  return styles.isSectionHide
}

function renderDownloadCsvButtonContainer (tableName) {
  return <div className={styles.downLoadBtnContainer}>
    <span id={'downloadCSV' + tableName.replace(/\s+/g, '')}
      aria-label='Download CSV'
      className={styles.hide}
      onKeyDown={(e) => { handleDownloadCsv(e, tableName) }}
      onClick={() => debounce(function () {
        DownloadCsv(tableName)
      }, 1000)}
      role='button'
      tabIndex='0'>
      <FontAwesomeIcon icon={['fas', 'download']} className={styles.downloadIcon} />
    </span>
  </div>
}

function refreshDelayedQuoteDate (e) {
  let screenerArguments = [
    addArgument('SPCFWSODIssue', venueXidList, 'Like')
  ]
  const venueXids = venueXidList.map(Number)
  if (venueXids?.length) {
    getQuote(venueXids, screenerArguments)
  }
  setResetQuoteCards(CreateUUID())
}

function settingShowGlossary (ele) {
  setShowGlossary(ele)
}

return (
  <div className={styles.fundCompareContainer} id='main'>
    {cardCount !== 0 &&
      <div className={`${styles['delayed-quote-info']}`} id='delayedQuoteInfo'>
        <div className={`${styles['date-info']}`}>Delayed Quote as of {delayedQuoteDate || TEXT_NULL_VALUE}</div>
        <div id='refreshDelayedQuoteBtn' className={`${styles['refresh-icon']}`} onClick={refreshDelayedQuoteDate}
          onKeyDown={refreshDelayedQuoteDate} role='button' tabIndex='0' aria-label='Refresh quote data'>
          <FontAwesomeIcon icon={['far', 'sync-alt']} data-icon-style='fa-blue-refresh' />
        </div>
      </div>
    }
    <div role='menu'
      id='zeroAddComparisionCard'
      name='zeroAddComparisionCard'
      style={{ marginTop: topPosn + 'px' }} // TODO modify should pick top value for card dynamically to be handled in PRW-607
      className={styles.scrollContainer}>
      {cardCount === 0
        ? <div className={styles.noQuoteCardAdded} key={'noQuoteCard'}>
          <AddComparisonCard cardtype='fund' addCompare={addCompare} data='Add Comparison' iconName={['fal', 'plus-circle']} iconStyling={{ color: '#0572D6' }} valueStyling={{ height: getScrollTop() > 0 ? collapsedCardHeight : defaultCardHeight, width: '99%', color: '#0572D6' }} cardCount={cardCount} />
        </div>
        : <div key={resetQuoteCards}>
          {
            modelCompareCardData.slice(0, cardCount).map((item, index) => {
              return (<div key={'quotecard-' + index} name='quoteCard' className={styles.quoteCard} cardtype={getScrollTop() ? 'collapsedCard' : 'openCard'}>
                <QuoteCard moduleType={'fundCompare'} fundType={item.fundType} type={item.type} IsFVETF={item.IsFVETF} spinnerStyle={getSpinnerStyle()} viewShareClassModalHandler={viewShareClassModalHandler} style={{ height: getScrollTop() > 0 ? collapsedCardHeight : defaultCardHeight, borderTop: '4px solid ' + CardTopBorderColors[index] }} id={'quoteCardNews_' + item.venueXid} key={'quoteCardNews_' + item.venueXid} venueXid={parseInt(item.venueXid)} symbol={item.symbol} name={item.name} CUSIP={item.CUSIP} overlayKeyHandler={(venueXid) => overlayKeyHandler(venueXid)} />
              </div>)
            })
          }
          <div name='comparisonWithQuote' className={styles.comparisonWithQuote}>
            {cardCount >= 10
              ? <AddComparisonCard cardtype='fund' addCompare={addCompare} data='Maximum of 10 Comparisons' iconName={['fal', 'exclamation-triangle']} iconStyling={{ height: '24px', width: '27px', marginBottom: '10px', lineHeight: '24px', color: '#666666' }} valueStyling={{ height: getScrollTop() > 0 ? collapsedCardHeight : defaultCardHeight, color: '#666666' }} cardCount={cardCount} />
              : [...Array(numberOfCards)].map((u, i) => <AddComparisonCard key={'card-' + i} cardtype='fund' index={i} data='Add Comparison' addCompare={addCompare} iconName={['fal', 'plus-circle']} iconStyling={{ color: '#0572D6' }} valueStyling={{ height: getScrollTop() > 0 ? collapsedCardHeight : defaultCardHeight, color: '#0572D6' }} cardCount={cardCount} />)
            }
          </div>
        </div>
      }
    </div>
    <div id='PerformanceOnComparePage' className={`${styles.PerformanceOnComparePage} ${isDisplay()}`}>
      <PerformanceOnComparePage modelCompareCardData={modelCompareCardData} />
    </div>
    <div id='navTab' className={`${styles.modelTabs} ${isDisplay()}`}>
      <NavTabControl itemList={[FundTabsConstant.KeyMeasures, FundTabsConstant.Operational, FundTabsConstant.Profile, FundTabsConstant.ESGRating, FundTabsConstant.ESGCarbonMetrics, FundTabsConstant.AssetAllocation, FundTabsConstant.Income, FundTabsConstant.Performance, FundTabsConstant.PortfolioCharacteristics, FundTabsConstant.Ratings, FundTabsConstant.Risk, FundTabsConstant.TechnicalIndicators]} onClick={(e) => setNavTab(e)} activeItem={navTabActive} />
    </div>
    <span style={{ display: 'none' }}><CSVLink ref={csvInstance} data={csvData.data} headers={csvData.headers} filename={csvData.fileName} /></span>
    <main id='tableSection' className={`${styles.modelCompareTableSection} ${isDisplay()}`}>
      <div>
        <div className={styles.tableTitle} id='Key Measures' role='button' tabIndex='0' >
          <div aria-hidden='true'>{FundTabsConstant.KeyMeasures}</div>
          <p className='sr-only' role='heading' aria-level='2'>Key Measures Table Section</p>
          {renderDownloadCsvButtonContainer(FundTabsConstant.KeyMeasures)}
        </div>
        <div className={styles.modelTable}>
          <Table _data={keyMeasuresData} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'KeyMeasures'} />
        </div>
      </div>
      <div>
        <div className={styles.tableTitle} id='Operational' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.Operational}</div>
          <p className='sr-only' role='heading' aria-level='2'>Operational Table Section</p>
          {renderDownloadCsvButtonContainer(FundTabsConstant.Operational)}
        </div>
        <div className={styles.modelTable}>
          <Table _data={operationalData} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'Operational'} />
        </div>
      </div>
      <div>
        <div className={styles.tableTitle} id='Profile' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.Profile}</div>
          <p className='sr-only' role='heading' aria-level='2'>Profile Table Section</p>
          {renderDownloadCsvButtonContainer(FundTabsConstant.Profile)}
        </div>
        <div className={styles.modelTable}>
          <Table _data={profileData} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'Profile'} />
        </div>
      </div>
      <div>
        <div className={styles.tableTitle} id='ESG Rating' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.ESGRating}</div>
          <p className='sr-only' role='heading' aria-level='2'>ESG Rating Table Section</p>
          <div className={styles.downloadGlossary}>
            <div className={styles.glossary}>
              <EsgGlossary glossary={FundEsgGlossary.glossary} showGlossary={showGlossary} settingShowGlossary={settingShowGlossary} /></div>
            {renderDownloadCsvButtonContainer(FundTabsConstant.ESGRating)}
          </div>
        </div>
        <div className={styles.modelTable}>
          <Table _data={esgRating} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'ESGRating'} />
        </div>
      </div>
      <div>
        <div className={styles.tableTitle} id='ESG Carbon Metrics' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.ESGCarbonMetrics}</div>
          <p className='sr-only' role='heading' aria-level='2'>ESG Carbon Metrics Table Section</p>
          <div className={styles.downloadGlossary}>
            <div className={styles.glossary}>
              <EsgGlossary glossary={FundEsgGlossary.glossary} showGlossary={showGlossary} settingShowGlossary={settingShowGlossary} /></div>
            {renderDownloadCsvButtonContainer(FundTabsConstant.ESGCarbonMetrics)}
          </div>
        </div>
        <div className={styles.modelTable}>
          <Table _data={esgCarbonMetrics} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'ESGCarbonMetrics'} />
        </div>
      </div>
      <div>
        <div className={styles.tableTitle} id='Asset Allocation' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.AssetAllocation}</div>
          <p className='sr-only' role='heading' aria-level='2'>Asset Allocation Table Section</p>
          {renderDownloadCsvButtonContainer(FundTabsConstant.AssetAllocation)}
        </div>
        <div className={styles.modelTable}>
          <Table _data={assetAllocationData} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'AssetAllocation'} />
        </div>
      </div>
      <div>
        <div className={styles.tableTitle} id='Income' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.Income}</div>
          <p className='sr-only' role='heading' aria-level='2'>Income Table Section</p>
          {renderDownloadCsvButtonContainer(FundTabsConstant.Income)}
        </div>
        <div className={styles.modelTable}>
          <Table _data={incomeData} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'Income'} />
        </div>
      </div>
      <div>
        <div className={styles.tableTitle} id='Performance' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.Performance}</div>
          <p className='sr-only' role='heading' aria-level='2'>Performance Table Section</p>
          {renderDownloadCsvButtonContainer(FundTabsConstant.Performance)}
        </div>
        <div className={styles.modelTable}>
          <Table _data={performanceData} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'Performance'} negativeNumberStyleFieldsForRowHeader={FundComparePerformanceTabNegativeCell} />
        </div>
        <PerformanceDisclosure />
      </div>
      <div>
        <div className={styles.tableTitle} id='Portfolio Characteristics' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.PortfolioCharacteristics}</div>
          <p className='sr-only' role='heading' aria-level='2'>Portfolio Characteristics Table Section</p>
          {renderDownloadCsvButtonContainer(FundTabsConstant.PortfolioCharacteristics)}
        </div>
        <div className={styles.modelTable}>
          <Table _data={portfolioCharacteristicsData} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'PortfolioCharacteristics'} />
        </div>
      </div>
      <div>
        <div className={styles.tableTitle} id='Ratings' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.Ratings}</div>
          <p className='sr-only' role='heading' aria-level='2'>Ratings Table Section</p>
          {renderDownloadCsvButtonContainer(FundTabsConstant.Ratings)}
        </div>
        <div className={styles.modelTable}>
          <Table _data={ratingsData} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'Ratings'} />
        </div>
      </div>
      <div>
        <div className={styles.tableTitle} id='Risk' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.Risk}</div>
          <p className='sr-only' role='heading' aria-level='2'>Risk Table Section</p>
          {renderDownloadCsvButtonContainer(FundTabsConstant.Risk)}
        </div>
        <div className={styles.modelTable}>
          <Table _data={riskData} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'Risk'} />
        </div>
        <div className={styles.primaryBenchmark}>
          <span><sup>*</sup>Morningstar Primary Benchmark</span>
        </div>
      </div>
      <div>
        <div className={styles.tableTitle} id='Technical Indicators' role='button' tabIndex='0'>
          <div aria-hidden='true'>{FundTabsConstant.TechnicalIndicators}</div>
          <p className='sr-only' role='heading' aria-level='2'>Technical Indicators Table Section</p>
          {renderDownloadCsvButtonContainer(FundTabsConstant.TechnicalIndicators)}
        </div>
        <div className={styles.modelTable}>
          <Table _data={technicalIndicatorsData} headers={tableHeaders} maxSelectionLength={10} fixedColumnCount={1} moduleName={'TechnicalIndicators'} />
        </div>
      </div>
    </main>
    <footer>
      <ImportantDisclosure />
      <Footer />
    </footer>
    <ModalRoot
      header={modalHeader}
      bodyLabel='Screener Name'
      errorMsg={errorMsg}
      compareBtnHandler={(data) => compareBtnHandler(data)}
      closeHandler={() => closeHandler()}
      cardCount={cardCount}
    />
  </div>
)
}

FundCompare.defaultProps = {
  compareData: []
}

FundCompare.propTypes = {
  symbol: PropTypes.string,
  xid: PropTypes.string,
  name: PropTypes.string,
  IsFVETF: PropTypes.bool,
  type: PropTypes.string,
  CUSIP: PropTypes.string,
  OffshoreFlex: PropTypes.string,
  fundType: PropTypes.string,
  compareData: PropTypes.array
}

export default FundCompare
