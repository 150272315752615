'use strict'
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import Button from '../../../Lib/Button/Button'
import Divider from '../../../Lib/Divider/Divider'
import styles from './SaveChartPopupBody.module.scss'
import PropTypes from 'prop-types'

const SaveChartPopupBody = forwardRef((props, ref) => {
  const [chartName, SetChartName] = useState(props.chartName)
  const [errorMsg, setErrorMsg] = useState('')

  const saveChart = () => {
    if (chartName === '') {
      setErrorMsg('*Please enter chart name.')
    } else if (chartName.trim().length > 40) {
      setErrorMsg('*Name too long.')
    } else {
      props.saveChartHandler(chartName)
    }
  }

  useImperativeHandle(ref, () => ({
    setNameErrorMsg () {
      setErrorMsg('*Name already exists.')
    }
  }))

  const onChange = (e) => {
    setErrorMsg('')
    SetChartName(e.target.value)
  }

  return (<div className={styles.saveChartContainer}>
    <div className={styles.saveChartInputContainer}>
      <input type='text' value={chartName} id='SaveChartTxtBox' onChange={onChange} className={styles.saveChartInput} placeholder='Name your chart' />
      <span className={styles.saveChartErrorMsg}>{errorMsg}</span>
    </div>
    <Divider style={{marginTop: '0px', borderTop: 'none'}} />
    <div className={styles.saveChartButtonContainer}>
      <Button className={styles.saveChartButton} labelText='Save Chart' clickHandler={saveChart} />
      <Button className={styles.cancelChartButton} labelText='Cancel' clickHandler={props.cancelChartHandler} />
    </div>
  </div>)
})
export default SaveChartPopupBody
SaveChartPopupBody.propTypes = {
  saveChartHandler: PropTypes.func,
  cancelChartHandler: PropTypes.func,
  chartName: PropTypes.string
}
