import DataRequest from './DataRequest'
import { URLS, XREF_XIDS_REQUEST_SIZE } from '../utils/appConstants'

export const makeXrefCall = (symbol, callbackHandler, isSymbolCall, venueXidList, requestInput) => {
  !isSymbolCall ? DataRequest.execute(
    `${URLS.PLATFORM.XREFGETSYMBOL}/${symbol}`,
    {
      params: {
        isCaseSensitive: false
      },
      requestInput: requestInput
    },
    xrefCallback,
    null
  )
    : DataRequest.execute(
      `${URLS.PLATFORM.XREF}`,
      {
        params: {
          venueXids: venueXidList
        },
        requestInput: requestInput
      },
      xrefCallback
    )

  function xrefCallback (response) {
    callbackHandler(response)
  }
}

export const makeXrefChunksCall = (callbackHandler, venueXidList, requestInput) => {
  return new Promise((resolve, reject) => {
  const xidChunks = []
  let promiseArray = []
  for (let i = 0; i < venueXidList.length; i += XREF_XIDS_REQUEST_SIZE) {
    const chunk = venueXidList.slice(i, i + XREF_XIDS_REQUEST_SIZE)
    xidChunks.push(chunk)
  }

  xidChunks.forEach((xidChunk) => {
    promiseArray.push(xidChunk && DataRequest.execute(
      `${URLS.PLATFORM.XREF}`,
      {
        params: {
          venueXids: xidChunk
        },
        requestInput
      },
      (res) => {
      }
    ));
  });
  Promise.all(promiseArray)
  .then(responseArray => {
    const xrefResult = responseArray.reduce((item, idx) => {
      return item.concat(idx?.data?.items)
    }, [])
    const xrefResultObj = {data: xrefResult,
      isError: responseArray[0]?.isError,
      error: responseArray[0]?.error,
      isDataRequestComplete: responseArray[0]?.isDataRequestComplete
    }
    return resolve(callbackHandler(xrefResultObj))
  })
  })
}
