'use strict'

import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import BasicChartTools from './BasicChartTools'
import SaveChart from '../ChartTools/SaveChart/SaveChart'
import ChartToolsMenu from '../ChartTools/ChartToolsMenu/ChartToolsMenu'
import styles from './ChartTools.module.scss'
import LinkButton from '../../Lib/LinkButton/LinkButton'
import CustomSelectMenu from '../../Lib/CustomSelectMenu/CustomSelectMenu'
import ColorPalette from '../../Lib/ColorPalette/ColorPalette'
import LineDropdown from '../../Lib/LineDropdown/LineDropdown'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import { COLOR_DEFAULT_SET } from '../../../utils/appConstants'
import { tools } from '@markit/modcharts/dist/modcharts.umd.js'
import AddText from './AddText/AddText'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function AdvancedChartTools ({ chart, resetChart, resetIndicators, resetSaveChart, onInputsSelection }) {
  const [showToolsMenu, setShowToolsMenu] = useState(false)
  const [selectedTool, setSelectedTool] = useState({
    toolName: 'Arrow'
  })
  const [currentTool, setCurrentTool] = useState({
    color: {value: ''}
  })
  const [displayAddTextPopup, setDisplayAddTextPopup] = useState(false)

  useEffect(() => {
    chart && chart.eventEmitter.on('TOOL_DOUBLE_CLICK', (tool) => {
      if (tool.name === 'Text') {
        setCurrentTool({
          uid: tool.uid,
          value: tool.value,
          fontSize: tool.style.fontSize,
          color: {value: tool.style.fontColor || '#000'}
        })

        setDisplayAddTextPopup(true)
      }
    })
  }, [chart])

  let toolOptions = {
    tool: 'arrow',
    color: '#bf360c'
  }

  function toolsClickHandler () {
    setShowToolsMenu(!showToolsMenu)
    chart.setToolMode(
      new tools[toolOptions.tool](chart, toolOptions.color)
    )
  }

  function resetTools () {
    setShowToolsMenu(false)
  }

  const addText = (data) => {
    if (!currentTool) return

    chart.panels[0]['tools'].map((tool) => {
      if (tool.params.uid === currentTool.uid) {
        tool.params.value = data.value
        tool.params.style.fontColor = data.color.value || '#000'
        tool.params.style.fontSize = data.fontSize || 12

        setCurrentTool({
          uid: tool.params.uid,
          value: data.value,
          fontSize: data.fontSize,
          color: {value: data.color.value || '#000'}
        })
      }
    })

    chart.render()
    setDisplayAddTextPopup(false)
  }

  const setChartTools = (tool) => {
    if (!tool) return

    if (tool.tool) {
      toolOptions.tool = tool.tool
    }
    if (tool.color) {
      toolOptions.color = tool.color
    }

    if (tool.toolName) {
      setSelectedTool({
        toolName: tool.toolName
      })
    }

    chart.setToolMode(
      new tools[toolOptions.tool](chart, {
        style: {
          lineColor: tool.color,
          lineWidth: 2
        }
      })
    )
  }

  const clearDrawings = () => {
    chart.removeTools()
  }

  return (
    <React.Fragment>
      <div className={styles.advancedChartTools}>
        <BasicChartTools chart={chart} showToolsButton onInputsSelection={onInputsSelection} toolsClickHandler={toolsClickHandler} resetTools={resetTools} isAdvanceChart resetChart={resetChart} />
        <div className={styles.advanceSaveChart}>
          <SaveChart
            currentChart={chart}
            resetChart={resetChart}
            resetIndicators={resetIndicators}
            resetSaveChart={resetSaveChart} />
        </div>
      </div>
      {
        showToolsMenu &&
        <div className={styles.chartToolsMenuContainr}>
          <CustomSelectMenu
            preFixLabel={selectedTool.toolName}
            popOverStyle={styles.chartToolbox}
            name='ddlArrow'
            popOverBody={<ChartToolsMenu chart={chart} onSelection={(data) => setChartTools(data)} />} />

          <div className={styles.colorPalatteContainer}>
            <ColorPalette
              colors={COLOR_DEFAULT_SET}
              selectedValue={''}
              name='btnChartColorPalatte'
              onSelection={(data) => setChartTools({color: data.value})} />
          </div>

          <div className={styles.lineDropdownContainer}>
            <LineDropdown
              colors={COLOR_DEFAULT_SET}
              selectedValue={''}
              onSelection={(e) => {}} />
          </div>

          <div className={styles.clearDrawingsContainer} >
            <LinkButton label={'Clear Drawings'} onClick={clearDrawings} />
          </div>
        </div>
      }
      {
        displayAddTextPopup && <ModalPopUp addEventListener={false} customHeader stylesPopUp={{height: '200px !important', width: '400px', 'min-height': '200px'}} stylesContent={{padding: '0px'}}>
          <div className={styles.addTextHeader}>
            <span>
              Insert Text
            </span>
            <button className={styles.closeButton}
              alt='Close'
              id='closeButton'
              onClick={() => {
                setDisplayAddTextPopup(false)
              }}>
              <FontAwesomeIcon icon={['fal', 'times']} className={styles.btnPopUpClose} id='closeButtonFontAwsome' />
            </button>
          </div>
          <AddText
            toolValues={currentTool || {}}
            onApply={(data) => {
              addText(data)
            }}
            onCancel={() => {
              setDisplayAddTextPopup(false)
            }} />
        </ModalPopUp>
      }
    </React.Fragment>
  )
}

AdvancedChartTools.propTypes = {
  chart: PropTypes.object,
  resetChart: PropTypes.func,
  resetIndicators: PropTypes.func,
  resetSaveChart: PropTypes.string,
  onInputsSelection: PropTypes.func
}
