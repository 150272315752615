import React, {useState} from 'react'
import PropTypes from 'prop-types'
import RadioButton from '../../Lib/RadioButton/RadioButton'
import PrimaryButton from '../../Lib/Button/PrimaryButton'
import SecondryButton from '../../Lib/Button/SecondryButton'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import ChartsMobileHeader from '../../Lib/ChartsMobileHeader/ChartsMobileHeader'
import styles from './ChartStylesMobile.module.scss'

export default function ChartStylesMobile ({getSelectedStyle, onClose, stylesList}) {
  const [chartStyles, setChartStyles] = useState([...stylesList])

  const checkUncheck = (name, isChecked) => {
    const updatedStyles = chartStyles.map(style => {
      if (style.Text === name) {
        style.checked = isChecked
      }
      return style
    })
    setChartStyles([...updatedStyles])
  }

  const onChange = (e) => {
    const name = e.target.name
    const isChecked = e.target.checked
    onReset(e)
    checkUncheck(name, isChecked)
  }
  const onApply = (e) => {
    const selectedStyle = chartStyles.filter(style => style.checked === true)
    if (selectedStyle.length > 0) {
      getSelectedStyle({...selectedStyle[0]})
    } else {
      getSelectedStyle({})
    }
  }
  const onReset = (e) => {
    const resetStyles = chartStyles.map(style => {
      style.checked = false
      return style
    })
    setChartStyles([...resetStyles])
  }

  return (
    <ModalPopUp customHeader customBody>
      <ChartsMobileHeader headerText='Style' closeHandler={onClose} headerDataTestId='chartStyleClose' />
      <div className={styles.chartStylesMobile}>
        <div className={styles.styleOptions}>
          {chartStyles.map((style, key) => {
            return <RadioButton key={key} tabIndex={0} label={style.Text} isChecked={style.checked} onChange={onChange} />
          })}
        </div>
        <div className={styles.footer}>
          <PrimaryButton tabIndex='0' buttonText='Apply' clickHandler={onApply} dataTestId='chartStyleApplyButton' />
          <SecondryButton tabIndex='0' buttonText='Reset' clickHandler={onReset} dataTestId='chartStyleResetButton' />
        </div>
      </div>
    </ModalPopUp>
  )
}

ChartStylesMobile.propTypes = {
  getSelectedStyle: PropTypes.func,
  onClose: PropTypes.func,
  stylesList: PropTypes.array
}
