export function removeEvents (eventType) {
  const eventsIcons = document.querySelectorAll(`[data-event-icon=${eventType}]`)
  if (eventsIcons) {
    eventsIcons.forEach(icon => {
      let element = document.getElementById(icon.id)
      if (element) {
        element.remove()
      }
    })
  }
}
