import React, { useState, useEffect } from 'react'
import styles from './Indicator.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../Lib/Button/Button'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import PropTypes from 'prop-types'
import IndicatorConfiguration from './IndicatorConfiguration'
import IndicatorData from '../../../data/ChartIndicator.json'
import {handleWindowResize, getDeviceType, deviceType} from '../../../utils/utilities'
import CheckBox from '../../Lib/CheckBox/CheckBox'

function Indicator (props) {
  const [freeTextSearch, setSetFreeTextSearch] = useState('')
  const [selectedItems, setSelectedItems] = useState([])
  const [showConfigurationScreen, setShowConfigurationScreen] = useState(false)
  const [allItems, setAllItems] = useState(getIndicatorData())
  const [device, setDevice] = useState(getDeviceType())
  const [typesList, setTypesList] = useState([
    {ItemType: 'Trends', IsOpen: false},
    {ItemType: 'Momentum', IsOpen: false},
    {ItemType: 'Volume', IsOpen: false},
    {ItemType: 'Fundamental', IsOpen: false}])

  useEffect(() => {
    handleWindowResize(resizeHandler)
  }, [device])

  useEffect(() => {
    if (props.isResetIndicator) {
      setSelectedItems([])
      setAllItems(getDataDefault())
      setShowConfigurationScreen(false)
      setSetFreeTextSearch('')
      props.setResetIndicator(false)
    }
  }, [props.isResetIndicator])

  function resizeHandler (device) {
    setDevice(device)
  }

  function getIndicatorData () {
    return props.isAdvance ? [...IndicatorData.CHARTINDICATORS.DATA] : [...IndicatorData.CHARTINDICATORS.BASIC_DATA]
  }

  function handleApply (e) {
    e.preventDefault()
    let items = []
    const data = [...allItems]
    data.forEach(item => {
      if (item.isChecked) {
        items.push({key: item.key, name: item.name, value: item.value})
      }
    })
    // hideScroll(true)
    setSelectedItems(items)
    let element = document.getElementById('CustomSelectMenu_Indicators')
    if (element) {
      element.style = 'popupExtraSetting'
    }
    if (items.length > 0) {
      setShowConfigurationScreen(true)
    } else {
      props.toggleArrow(prev => {
        return !prev
      })
    }
  }
  const onSelection = (data) => {
    if (props.isMobile) {
      props.closeIndicator(data)
    } else {
      let element = document.getElementById('CustomSelectMenu_Indicators')
      if (element) {
        element.style = 'popupExtraSettingReset'
      }
    }
  }

  function clearSearchBox () {
    setSetFreeTextSearch('')
  }
  function handleReset (e) {
    e.preventDefault()
    setSelectedItems([])
    setAllItems(getDataDefault())
    setShowConfigurationScreen(false)
    setSetFreeTextSearch('')
    if (props.isMobile) {
      props.closeIndicator({ 'INDICATORS': [], 'hideChartIndicatorOptionMenu': new Date().toString() })
    }
  }

  function handleExit (e) {
    if (props.isMobile) {
      props.closeIndicator({INDICATORS: []})
    }
  }
  const getDataDefault = () => {
    let data = getIndicatorData()
    return data.map(item => {
      if (item.isChecked) {
        item.isChecked = false
      }
      return item
    })
  }
  const handleOnChange = position => {
    const data = [...allItems]
    data.forEach((item, index) => {
      if (index === position) {
        item.isChecked = !item.isChecked
      }
    })
    setAllItems(data)
  }
  const handleKeyBasedOnChange = (key, name) => {
    const data = [...allItems]
    data.forEach((item) => {
      if ((item.key === key && item.name === name)) {
        item.isChecked = !item.isChecked
      }
    })
    setAllItems(data)
  }

  const handleExpandCollapse = position => {
    const data = [...typesList]
    data.forEach((item, index) => {
      if (index === position) {
        item.IsOpen = !item.IsOpen
      }
    })
    setTypesList(data)
  }

  const commonList = () => {
    return <ul>
      {allItems
        .filter(items => items.isCommon === true)
        .map(({ key, value, isChecked }, index) => (
          <li key={`cl-${index}`} title={value}>
            <CheckBox id={`checkbox-${index}`} index={index} value={value} isChecked={isChecked} label={value} onCheck={() => handleOnChange(index)} />
          </li>
        ))}
    </ul>
  }

  const allItemsList = () => {
    return <ul>
      {allItems.map(({ key, value, isChecked }, index) => (
        <li key={`gl-${index}`} title={value}>
          <CheckBox id={`checkbox-${index}`} index={index} value={value} isChecked={isChecked} label={value} onCheck={() => handleOnChange(index)} />
        </li>
      ))}
    </ul>
  }

  const freeTextChanged = e => {
    const value = e.target.value
    const filter = value && value.toUpperCase()
    setSetFreeTextSearch(e.target.value)
    if (e.target.value === '') {
      const copyMaster = getIndicatorData()
      setAllItems(copyMaster)
      if (!props.isAdvance) {
        ExpandAll(false)
      }
    } else {
      const copyMaster = getIndicatorData()
      const data = copyMaster.filter(item => !item.isCommon && item.value && item.value.toUpperCase().indexOf(filter) > -1)
      setAllItems(data)
      if (!props.isAdvance) {
        ExpandAll(true)
      }
    }
  }

  const ExpandAll = (option) => {
    const data = [...typesList]
    data.forEach((item, index) => {
      item.IsOpen = option
    })
    setTypesList(data)
  }

  const searchInputBox = () => {
    return <div className={styles.searchFilterContainer}>
      <div className={styles.searchBoxFilter}>
        <input
          type='text'
          data-testid={`button_search`}
          placeholder='Search'
          onChange={freeTextChanged}
          value={freeTextSearch}
        />
        <span className={styles.inputSearchIcon}>
          {freeTextSearch.length > 0 ? <FontAwesomeIcon data-testid='clear_search' icon={['fal', 'times']} color='rgba(0, 0, 0, 0.3)' onClick={clearSearchBox} />
            : <FontAwesomeIcon icon={['far', 'search']} color='rgba(0, 0, 0, 0.3)' />}
        </span>
      </div>
    </div>
  }

  const renderFooter = () => {
    return <footer className={styles.bottomAlign} >
      <div className={styles.bottomLine} />
      <div className={`${styles['button-container']} ${styles['buttons']}`}>
        <Button
          clickHandler={handleReset}
          className={`${styles['reset']}`}
          labelText='Reset'
          tabIndex='0'
          buttonDataTestId='resetButton'
        />
        <Button
          clickHandler={handleApply}
          className={`${styles['apply']}`}
          labelText='Apply'
          tabIndex='0'
          buttonDataTestId='indicator-apply-button'
        />
      </div>
    </footer>
  }

  const getIndicatorsScreenAdvanceMobile = () => {
    return (
      <div>
        <div className={styles.sections}>
          {searchInputBox()}
          <div className={styles.scroll}>
            <div className={styles.itemsListContainer}>
              {freeTextSearch === '' && <div>
                <div className={`${styles['itemsHeader']}`}>Commonly Used</div>
                <div className={`${styles['items']}`}>
                  {commonList()}
                </div>
              </div>
              }
              <div className={`${styles['itemsHeader']}`}> {freeTextSearch === '' ? 'All Indicators' : 'Search Results'} </div>
              <div className={`${styles['items']}`}>
                {allItemsList()}
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

  // type true for advance and false for basic
  const getHtmlForBasicAdvance = () => {
    if (props.isAdvance) {
      return <div className={styles.itemsListContainer}>
        {freeTextSearch === '' && <div>
          <div className={`${styles['itemsHeader']}`}>Commonly Used</div>
          <div className={`${styles['items']}`}>
            {commonList()}
          </div>
        </div>
        }
        <div className={`${styles['itemsHeader']}`}> {freeTextSearch === '' ? 'All Indicators' : 'Search Results'} </div>
        <div className={`${styles['items']}`}>
          {allItemsList()}
        </div>
      </div>
    } else {
      return <div className={styles.itemsListContainer}>
        {freeTextSearch === '' ? '' : <div className={`${styles['itemsHeader']}`}> {'Search Results'} </div>}
        <div className={`${styles['items']}`}>
          {basicRendering()}
        </div>
      </div>
    }
  }

  // This is for rendering of content in desktop and ipad view where flag is true for advance
  const getIndicatorsScreenDesktopIpad = () => {
    return <div className={styles.sections}>
      {searchInputBox()}
      <div className={styles.scroll}>
        {getHtmlForBasicAdvance()}
      </div>
      {renderFooter()}
    </div>
  }

  const basicRendering = () => {
    return <ul>
      {typesList.map(({ItemType, IsOpen}, indexM) => (
        <div key={'indicatorBasicRender_' + indexM} className={styles.itemContainer}>
          <li key={`gl-${indexM}`} title={ItemType} className={styles.itemLIContainer}>
            {freeTextSearch === '' && <div className={styles.expColHead}>
              <div>
                <button data-testid={`${ItemType}-Indicator-Group`} onClick={() => handleExpandCollapse(indexM)}>
                  {IsOpen ? <FontAwesomeIcon icon={['far', 'chevron-down']} /> : <FontAwesomeIcon icon={['far', 'chevron-right']} />}
                  <span className={styles.leftSpace}>{ItemType}</span>
                </button>
              </div>
            </div>}
            <div className={`${styles['panel-collapse']} ${IsOpen ? '' : styles['panel-close']}`}>
              <ul>
              {renderItems(allItems, ItemType)}
              </ul>
            </div>

          </li>
        </div>
      ))}
    </ul>
  }

  const renderItems = (allItems, ItemType) => {
    return allItems
      .filter(item => item.type === ItemType)
      .map(({ key, name, value, isChecked }, index) => (
        <li key={`gl-${key}${name}`} title={value}>
          <CheckBox
            id={`gl-checkbox-${key}${name}`}
            index={index}
            value={value}
            isChecked={isChecked}
            label={value}
            onCheck={() => handleKeyBasedOnChange(key, name)}
          />
        </li>
      ));
  };

  const getIndicatorsScreenBasic = () => {
    return (
      <div>
        <div className={styles.sections}>
          {searchInputBox()}
          <div className={styles.scroll}>
            <div className={styles.itemsListContainer}>
              {freeTextSearch === '' ? '' : <div className={`${styles['itemsHeader']}`}> {'Search Results'} </div>}
              <div className={`${styles['items']}`}>
                {basicRendering()}
              </div>
            </div>
          </div>
          {renderFooter()}
        </div>
      </div>
    )
  }

  const hideConfigurationPageHanlder = () => {
    if (props.isMobile) {
      props.closeIndicator(getChartDataDefault())
    } else {
      setShowConfigurationScreen(false)
      props.toggleArrow(prev => {
        return !prev
      })
    }
  }

  const getChartDataDefault = () => {
    return { 'hideChartIndicatorOptionMenu': new Date().toString() }
  }

  const modalHeader = () => {
    return <div className={styles.topSection}>
      <div className={styles.topSectionHeader}>Indicators</div>
      <div>
        <button data-testid='indicator-exit-button' onClick={handleExit}>
          <span>
            <FontAwesomeIcon
              icon={['fal', 'times']}
              className={styles.crossButton}
            />
          </span>
        </button>
      </div>
    </div>
  }

  const getIndicatorConfiguration = () => {
    return <IndicatorConfiguration
      selectedItems={selectedItems}
      onSelection={onSelection}
      isMobile={device === deviceType.Mobile}
      closePopup={hideConfigurationPageHanlder}
      chart={props.chart}
      handleReset={handleReset}
      showDropDownTypeMenu={props.showDropDownTypeMenu} />
  }

  return (
    <div className={styles.indicatorContainer}>
      {props.showDropDownTypeMenu &&
        <React.Fragment>
          {!showConfigurationScreen && (
            getIndicatorsScreenDesktopIpad()
          )}
          {showConfigurationScreen && (
            <div className={styles.popupScreen}>
              <ModalPopUp hideClose>
                {getIndicatorConfiguration()}
              </ModalPopUp>
            </div>
          )}
        </React.Fragment>
      }
      {!props.showDropDownTypeMenu &&
        <React.Fragment>
          <ModalPopUp customHeader customBody name={'IndicatorConfigurationScreen'}>
            {!showConfigurationScreen && (
              <div>
                {modalHeader()}
                {props.isAdvance ? getIndicatorsScreenAdvanceMobile() : getIndicatorsScreenBasic()}
              </div>
            )}
            {showConfigurationScreen && (
              <div className={styles.popupScreen}>
                {getIndicatorConfiguration()}
              </div>
            )}
          </ModalPopUp>
        </React.Fragment>
      }
    </div>
  )
}

Indicator.propTypes = {
  closeIndicator: PropTypes.func,
  isMobile: PropTypes.bool,
  isAdvance: PropTypes.bool,
  isResetIndicator: PropTypes.bool,
  setResetIndicator: PropTypes.func,
  chart: PropTypes.object,
  toggleArrow: PropTypes.func,
  showDropDownTypeMenu: PropTypes.bool
}
export default Indicator
