import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './WatchlistCreateBody.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchBox from '../Lib/SearchBox/SearchBox'
import { debounce } from 'throttle-debounce'
import WatchlistTable from './WatchlistTable'
import { deviceType, enterKeyEvent } from '../../utils/utilities'
import PrimaryButton from '../Lib/Button/PrimaryButton'
import { URLS } from '../../utils/appConstants'
import DataRequest from '../../services/DataRequest'
import { WATCHLIST, CLASSIFICATION_ID } from './WatchlistsConstant'
import CheckBox from '../Lib/CheckBox/CheckBox'
import { formatNumber } from '../../utils/formatter'
import Button from '../Lib/Button/Button'
import Loader from '../Lib/common/Loader'
import { WATCHLISTS_LANDING_CONSTANTS } from './View/WatchlistsConstants'
import SymbolWithPopover from './View/WatchlistStocks/SymbolWithPopover'
import { fireToastMessage } from '../Lib/CustomToast.js/CustomToast'
import { emitF2Event } from '../../utils/f2Methods'
import { EVENT_NAMES } from '../../utils/f2Constants'
import { sanitizeValue } from '../../utils/sanitizeInputs'
import { getQuoteData } from '../../shared/dataMethods'
function WatchlistCreateBody ({device, onClose, watchlistData, watchListHoldingData, symbol, exchange=null}) {
  const isWatchlistData = !!watchlistData
  const [inputValues, setInputValues] = useState(setInitialState(isWatchlistData))
  const [optionSymbolList, setOptionSymbolList] = useState([])
  const [equitySymbolList, setEquitySymbolList] = useState([])
  const [etfSymbolList, setEtfSymbolList] = useState([])
  const [mfSymbolList, setMfSymbolList] = useState([])
  const [selectedOptionSymbolList, setSelectedOptionSymbolList] = useState([])
  const [selectedEquitySymbolList, setSelectedEquitySymbolList] = useState([])
  const [selectedEtfSymbolList, setSelectedEtfSymbolList] = useState([])
  const [selectedMfSymbolList, setSelectedMfSymbolList] = useState([])
  const [selectedHoldingList, setSelectedHoldingList] = useState([])
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [exchangeId, setExchangeId] = useState(null)
  const inputRef = useRef()
  function setInitialState (isWatchlistData) {
    return {
      name: isWatchlistData ? watchlistData.name : '',
      nameError: null,
      successMessage: null,
      description: isWatchlistData ? watchlistData.description : '',
      descriptionError: '',
      maxItemsError: ''
    }
  }

  useEffect(() => {
    if (exchange) {
      getExchanges(exchange)
    }
  }, [])

  useEffect(() => {
    getVenueXidFromXref(symbol)
  }, [symbol, exchangeId])

  const defineParams = (exchangeId) => {
    return exchangeId ? { exchangeId, isCaseSensitive: false } : { isCaseSensitive: false }
  }

  const getVenueXidFromXref = (symbolForVenueXid) => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response?.data
        if (responseData.xids.venue) {
          const symbolObject = {
            symbol,
            name: responseData.name,
            isCheck: true,
            venueXid: responseData.xids.venue
          }
          const symbolItem = new Array(symbolObject)
          if (responseData.classification.id === 3) {
            setEquitySymbolList(symbolItem)
            setSelectedEquitySymbolList(symbolItem)
          } else if (responseData.classification.id === 18) {
            setEtfSymbolList(symbolItem)
            setSelectedEtfSymbolList(symbolItem)
          } else if (responseData.classification.id === 5) {
            setMfSymbolList(symbolItem)
            setSelectedMfSymbolList(symbolItem)
          } else if (responseData.classification.id === 2) {
            setOptionSymbolList(symbolItem)
            setSelectedOptionSymbolList(symbolItem)
          }
          addToWatchlistCommon(symbolItem)
        }
      }
    }
    DataRequest.execute(
      `${URLS.PLATFORM.XREFGETSYMBOL}/` + symbolForVenueXid,
      {
        params: defineParams(exchangeId)
      },
      callback,
      null
    )
  }

  const getExchanges = (symbolExchange) => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (response?.data) {
        const responseData = response?.data?.exchange
        const exchange = responseData.find(obj => obj.code === symbolExchange)?.id
        setExchangeId(exchange)
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.EXCHANGES}`,
      null,
      callback
    )
  }

  useEffect(() => {
    if (watchlistData) {
      // set holdings
      setSelectedHoldingListState()

      // set Selected symbol list
      setSymbolListState()
    }
  }, [watchlistData])

  useEffect(() => {
    if (watchListHoldingData.length > 0) {
      let equitySymbolList = []
      let holdings = []
      watchListHoldingData.forEach(data => {
        if (data.isStock) {
          equitySymbolList.push({
            symbol: data.selectedSymbol,
            name: data.Description,
            isCheck: true,
            venueXid: data.xid
          })
        } else if (data.isETF) {
          etfSymbolList.push({
            symbol: data.selectedSymbol,
            name: data.Description,
            isCheck: true,
            venueXid: data.xid
          })
        } else if (data.isMF) {
          mfSymbolList.push({
            symbol: data.selectedSymbol,
            name: data.Description,
            isCheck: true,
            venueXid: data.xid
          })
        } else if (data.isOption) {
          optionSymbolList.push({
            symbol: data.selectedSymbol,
            name: data.Description,
            isCheck: true,
            venueXid: data.xid
          })
        }
        holdings.push({
          'selectedSymbol': data.selectedSymbol,
          'Description': data.Description,
          'Price': data.Price,
          'Change': data.Change
        })
      })
      setSelectedOptionSymbolList(optionSymbolList)
      setSelectedEquitySymbolList(equitySymbolList)
      setSelectedEtfSymbolList(etfSymbolList)
      setSelectedMfSymbolList(mfSymbolList)
      setSelectedHoldingList(holdings)
    }
  }, [watchListHoldingData])

  function setSymbolListState () {
    let equitySymbolList = []
    watchlistData?.data?.forEach(data => {
      if (data.isStock) {
        equitySymbolList.push({
          symbol: data.symbol,
          name: data.name,
          isCheck: true,
          venueXid: data.xid,
          id: data.fieldId
        })
      } else if (data.isETF) {
        etfSymbolList.push({
          symbol: data.symbol,
          name: data.name,
          isCheck: true,
          venueXid: data.xid,
          id: data.fieldId
        })
      } else if (data.isMF) {
        mfSymbolList.push({
          symbol: data.symbol,
          name: data.name,
          isCheck: true,
          venueXid: data.xid,
          id: data.fieldId
        })
      } else if (data.isOption) {
        optionSymbolList.push({
          symbol: data.symbol,
          name: data.name,
          isCheck: true,
          venueXid: data.xid,
          id: data.fieldId
        })
      }
    })
    setSelectedOptionSymbolList(optionSymbolList)
    setSelectedEquitySymbolList(equitySymbolList)
    setSelectedEtfSymbolList(etfSymbolList)
    setSelectedMfSymbolList(mfSymbolList)
  }

  function setSelectedHoldingListState () {
    let holdings = []
    watchlistData?.data?.forEach(data => {
      holdings.push({
        'selectedSymbol': data.symbol,
        'Description': data.name,
        'Price': data.price,
        'Change': data.change
      })
    })
    setSelectedHoldingList(holdings)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setInputValues({
      ...inputValues,
      [name]: value
    })
  }

  function setClassifiedSymbols (symbols, classificationId) {
    switch (classificationId) {
      case CLASSIFICATION_ID.EQUITY: setEquitySymbolList(symbols)
        break
      case CLASSIFICATION_ID.ETF: setEtfSymbolList(symbols)
        break
      case CLASSIFICATION_ID.MF: setMfSymbolList(symbols)
        break
    }
  }

  let xrefCallback = (response) => {
    if (response.data) {
      const items = response.data.items
      if (items && items.length > 0) {
        const symbols = items.map(item => {
          let isCheck = false
          if (selectedHoldingList.find(x => x.selectedSymbol === item.symbol) !== undefined) {
            isCheck = true
          }
          let xids = item.xids ? item.xids.venue : ''
          return {
            symbol: item.symbol,
            name: item.name,
            isCheck,
            venueXid: xids
          }
        })
        setClassifiedSymbols(symbols, response.requestInput.classificationId)
      }
    }
  }

  function searchFor (query, classificationId) {
    DataRequest.execute(
      URLS.PLATFORM.XREFPREDICTIVESEARCH,
      {
        params: {
          term: query,
          classificationIds: classificationId,
          limit: 5
        },
        requestInput: {
          classificationId
        }
      },
      xrefCallback,
      null
    )
  }

  const searchSymbol = (query) => {
    setIsTableVisible(false)
    setEquitySymbolList([])
    setEtfSymbolList([])
    setMfSymbolList([])
    if (!query) {
      return
    }
    setIsTableVisible(true)
    searchFor(query, CLASSIFICATION_ID.EQUITY)
    searchFor(query, CLASSIFICATION_ID.ETF)
    searchFor(query, CLASSIFICATION_ID.MF)
  }

  const debouncedChangeHandler = debounce(500, (query) => {
    searchSymbol(query)
  })

  const addToWatchlist = (e) => {
    addToWatchlistCommon(null)
  }
  const addToWatchlistCommon = (inputSymbolItem) => {
    let holdings = []
    const useSymbolList = inputSymbolItem || [...selectedEquitySymbolList, ...selectedEtfSymbolList, ...selectedMfSymbolList, ...selectedOptionSymbolList]
    let venueXids = [...new Set(useSymbolList.map(x => x.venueXid))]
    const getItem = (data, xid) => data.find(x => x.venueXid === xid)
    let callback = (response) => {
      if (!response.isError) {
        let quoteData = response?.data?.quotes
        const useSelectedSymbolList = inputSymbolItem || [...selectedEquitySymbolList, ...selectedEtfSymbolList, ...selectedMfSymbolList, ...selectedOptionSymbolList]
        quoteData.forEach(item => {
          let data = {
            'selectedSymbol': getItem(useSelectedSymbolList, item.data.venueXid)?.symbol,
            'Description': getItem(useSelectedSymbolList, item.data.venueXid)?.name,
            'Price': formatNumber(item.data.lastTrade.last, { precision: 2 }),
            'Change': `${formatNumber(item.data.changePercent.today, { precision: 2, negSign: true })} %`
          }
          holdings.push(data)
        })
        holdings = [...new Set(holdings)]
        setSelectedHoldingList(holdings)
        setIsTableVisible(false)
      }
    }
    getQuoteData(venueXids, callback, false)
  }
  const selectedItems = (classificationID, isSelected, name) => {
    let selectedList = []
    switch (classificationID) {
      case CLASSIFICATION_ID.EQUITY: equitySymbolList.forEach(item => {
        if (item.symbol === name) {
          item.isCheck = isSelected
        }
        selectedList.push(item)
      })
        break
      case CLASSIFICATION_ID.ETF: etfSymbolList.forEach(item => {
        if (item.symbol === name) {
          item.isCheck = isSelected
        }
        selectedList.push(item)
      })
        break
      case CLASSIFICATION_ID.MF: mfSymbolList.forEach(item => {
        if (item.symbol === name) {
          item.isCheck = isSelected
        }
        selectedList.push(item)
      })
        break
      case CLASSIFICATION_ID.OPTION: optionSymbolList.forEach(item => {
        if (item.symbol === name) {
          item.isCheck = isSelected
        }
        selectedList.push(item)
      })
        break
    }
    return selectedList
  }

  const onTableSelect = (name) => {
    setEquitySymbolList(selectedItems(CLASSIFICATION_ID.EQUITY, false, name))
    setEtfSymbolList(selectedItems(CLASSIFICATION_ID.ETF, false, name))
    setMfSymbolList(selectedItems(CLASSIFICATION_ID.MF, false, name))
    setOptionSymbolList(selectedItems(CLASSIFICATION_ID.OPTION, false, name))
    const removeUncheck = selectedEquitySymbolList.filter(item => item.symbol !== name)
    setSelectedEquitySymbolList([...removeUncheck])
    const removeUncheckETF = selectedEtfSymbolList.filter(item => item.symbol !== name)
    setSelectedEtfSymbolList([...removeUncheckETF])
    const removeUncheckMf = selectedMfSymbolList.filter(item => item.symbol !== name)
    setSelectedMfSymbolList([...removeUncheckMf])
    const removeUncheckTableList = selectedHoldingList.filter(item => item.selectedSymbol !== name)
    setSelectedHoldingList(removeUncheckTableList)
    const removeUncheckOption = selectedOptionSymbolList.filter(item => item.symbol !== name)
    setSelectedOptionSymbolList(removeUncheckOption)
  }
  const GetList = (classificationId) => {
    let list = []
    switch (classificationId) {
      case CLASSIFICATION_ID.EQUITY:
        list = equitySymbolList
        break
      case CLASSIFICATION_ID.ETF:
        list = etfSymbolList
        break
      case CLASSIFICATION_ID.MF:
        list = mfSymbolList
        break
    }
    return list
  }

  const setSymbolListForChecked = (classificationId, selectedSymbol, name) => {
    switch (classificationId) {
      case CLASSIFICATION_ID.EQUITY:
        setEquitySymbolList(selectedItems(CLASSIFICATION_ID.EQUITY, true, name))
        setSelectedEquitySymbolList([...selectedEquitySymbolList, selectedSymbol])
        break;
      case CLASSIFICATION_ID.ETF:
        setEtfSymbolList(selectedItems(CLASSIFICATION_ID.ETF, true, name))
        setSelectedEtfSymbolList([...selectedEtfSymbolList, selectedSymbol])
        break;
      case CLASSIFICATION_ID.MF:
        setMfSymbolList(selectedItems(CLASSIFICATION_ID.MF, true, name))
        setSelectedMfSymbolList([...selectedMfSymbolList, selectedSymbol])
        break;
      default:
        break;
    }
  }
  const setSymbolListForUnchecked = (classificationId, selectedSymbol, name) => {
    let removeUncheck = []
    const removeUncheckData = (list) => list.filter(item => item.symbol !== selectedSymbol.symbol)
    switch (classificationId) {
      case CLASSIFICATION_ID.EQUITY:
        setEquitySymbolList(selectedItems(CLASSIFICATION_ID.EQUITY, false, name))
        removeUncheck = removeUncheckData(selectedEquitySymbolList)
        setSelectedEquitySymbolList([...removeUncheck])
        break;
      case CLASSIFICATION_ID.ETF:
        setEtfSymbolList(selectedItems(CLASSIFICATION_ID.ETF, false, name))
        removeUncheck = removeUncheckData(selectedEtfSymbolList)
        setSelectedEtfSymbolList([...removeUncheck])
        break;
      case CLASSIFICATION_ID.MF:
        setMfSymbolList(selectedItems(CLASSIFICATION_ID.MF, false, name))
        removeUncheck = removeUncheckData(selectedMfSymbolList)
        setSelectedMfSymbolList([...removeUncheck])
        break;

      case CLASSIFICATION_ID.OPTION:
        setOptionSymbolList(selectedItems(CLASSIFICATION_ID.OPTION, false, name))
        removeUncheck = removeUncheckData(selectedOptionSymbolList)
        setSelectedOptionSymbolList([...removeUncheck])
        break;

      default:
        break;
    }
  };

  const onSelect = (e, classificationId) => {
    const name = e.target.name
    const isChecked = e.target.checked
    const symbolList = GetList(classificationId)
    const selectedSymbol = symbolList.find(symbol => symbol.symbol === name)
    if (isChecked) {
      setSymbolListForChecked(classificationId, selectedSymbol, name)
    } else {
      setSymbolListForUnchecked(classificationId, selectedSymbol, name)
    }
  }

  const handleExit = (e) => {
    onClose('Yes')
  }

  const getHeaders = () => {
    let headers = []
    headers.push({ CellValue: 'Symbol', CellMap: 'selectedSymbol' })
    headers.push({ CellValue: 'Description', CellMap: 'Description' })
    headers.push({ CellValue: 'Price', CellMap: 'Price' })
    headers.push({ CellValue: 'Change', CellMap: 'Change' })
    return headers
  }

  const historyHeaderData = getHeaders()

  const IsFirstcolumnFroze = () => {
    return device === deviceType.Mobile
  }

  const isFirstColFrooze = IsFirstcolumnFroze()

  const outsideClickHandler = (event) => {
    // if the blur was because of outside focus
    // currentTarget is the parent element, relatedTarget is the clicked element
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsTableVisible(false)
    }
  }

  const buildWatchlistInputs = () => {
    let watchlistInputs = {
      name: sanitizeValue(inputValues.name ? inputValues.name.trim() : ''),
      description: sanitizeValue(inputValues.description ? inputValues.description.trim() : '')
    }

    return {
      method: isWatchlistData ? 'PUT' : 'POST',
      postData: watchlistInputs
    }
  }

  let saveWatchlistCallback = (response) => {
    if (response.isError && response?.error?.data) {
      const data = response.error.data
      const errormsg = data?.errors?.[0]?.message || data?.error?.message
      const msg = (errormsg && WATCHLIST[errormsg] && WATCHLIST.WATCHLIST_ALREADY_EXISTS) || errormsg
      setInputValues({
        ...inputValues,
        nameError: msg.indexOf(WATCHLISTS_LANDING_CONSTANTS.DUPLICATE_MESSAGE_API) > -1 ? WATCHLISTS_LANDING_CONSTANTS.ERROR_MESSAGE : msg,
        successMessage: null
      })
      inputRef?.current?.focus()
      return
    } else {
      let watchlistId = response?.data?.id
      isWatchlistData ? callDeleteSaveHoldings(watchlistId) : callSaveHoldings(watchlistId)
    }

    setInputValues({
      ...inputValues,
      nameError: null,
      successMessage: 'Saved Successfully.'
    })
    handleExit()
  }

  function SaveWatchlist () {
    DataRequest.execute(
      `${URLS.PLATFORM.WATCHLIST}${isWatchlistData ? ('/' + watchlistData.id) : ''}`,
      buildWatchlistInputs(),
      saveWatchlistCallback
    )
  }

  function callSaveHoldings (watchlistId) {
    let msg = ''
    let callbackWatchlistHoldings = (response) => {
      if (response.isDataRequestComplete && !response.isError) {
        msg = `Watchlist ${watchlistData ? 'updated' : 'created'} successfully.`
      } else {
        msg = 'An error occurred. Please try again later!'
      }
      emitF2Event(EVENT_NAMES.WATCHLIST_UPDATED, msg)
      fireToastMessage({
        message: msg
      })
    }

    let watchListsHoldings = buildWatchlistHoldingInputs()

    if (watchListsHoldings?.postData?.holdings?.length > 0) {
      DataRequest.execute(
        `${URLS.PLATFORM.WATCHLIST}/${watchlistId}/holdings`,
        watchListsHoldings,
        callbackWatchlistHoldings
      )
    } else {
      msg = `Watchlist ${watchlistData ? 'updated' : 'created'} successfully.`
      emitF2Event(EVENT_NAMES.WATCHLIST_UPDATED, msg)
      fireToastMessage({
        message: msg
      })
    }
  }

  function callDeleteSaveHoldings (watchlistId) {
    let callbackWatchlistDeletedHoldings = (response) => {
      if (!response.isError) {
        callSaveHoldings(watchlistId)
      }
    }
    let holdingsTobeDeleted = ''
    watchlistData?.data?.forEach(item => {
      if (!(selectedEquitySymbolList.findIndex(e => e.id === item.fieldId) > -1 ||
        (selectedEtfSymbolList.findIndex(e => e.id === item.fieldId) > -1) ||
        (selectedMfSymbolList.findIndex(e => e.id === item.fieldId) > -1) ||
        (selectedOptionSymbolList.findIndex(e => e.id === item.fieldId) > -1))) {
        holdingsTobeDeleted += item.fieldId + ','
      }
    })
    if (holdingsTobeDeleted.length > 0) {
      DataRequest.execute(
        `${URLS.PLATFORM.WATCHLIST}/${watchlistId}/holdings`,
        {
          method: 'DELETE',
          params: {
            holdingId: holdingsTobeDeleted.trim()
          }
        },
        callbackWatchlistDeletedHoldings
      )
    } else {
      callSaveHoldings(watchlistId)
    }
  }

  const buildWatchlistHoldingInputs = () => {
    let holdings = []
    let selectedSymbolList = [...selectedEquitySymbolList, ...selectedEtfSymbolList, ...selectedMfSymbolList, ...selectedOptionSymbolList]
    selectedSymbolList.forEach(item => {
      const data = {
        'description': item.symbol,
        'xid': item.venueXid
      }
      if (!isWatchlistData || (isWatchlistData && !item.id &&
        (!watchlistData.data || // Check if watchlist already has a holding that is being deleted and again added while editing || watchlist does not contain anything
          (watchlistData.data && watchlistData.data.indexOf(item.venueXid) <= -1)))) {
        holdings.push(data)
      }
    })

    return {
      method: 'POST',
      postData: { holdings }
    }
  }

  function saveHandler (e) {
    setInputValues({
      ...inputValues,
      'nameError': null,
      'successMessage': null,
      'descriptionError': null,
      'maxItemsError': null
    })
    SaveWatchlist()
  }
  function keyDownHandler (e) {
    setInputValues({
      ...inputValues,
      'nameError': null,
      'successMessage': null,
      'descriptionError': null,
      'maxItemsError': null
    })
    SaveWatchlist()
  }
  const getHistoryTableData = () => {
    if (selectedHoldingList && selectedHoldingList.length === 0) {
      let data = []
      let item
      const dummyRows = (device === deviceType.Desktop ? 2 : 1)
      for (let i = 0; i < dummyRows; i++) {
        item = { '': '' }
        data.push(item)
      }
      return data
    } else {
      return selectedHoldingList
    }
  }

  const historyTableData = getHistoryTableData()

  return (
    <div className={styles.watchlistCreateContainer}>
      <div className={styles.item}>
        <input type='text'
          ref={inputRef}
          placeholder='Watchlist Name (max 50 characters)'
          name={'name'}
          id={'name'}
          label={'Name'}
          autoComplete='off'
          maxLength='50'
          value={inputValues['name']}
          className={`${!inputValues.nameError ? styles.inputBox : styles.errorBox}`}
          onChange={handleInputChange}
        />
        <div className={styles.validationErrorMsg}>
          {
            inputValues.nameError &&
            <div>
              <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className={`${styles.alertIcon} ${styles.red}`} />
              {inputValues.nameError}
            </div>
          }
          {
            inputValues.successMessage &&
            <div>
              <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className={`${styles.alertIcon} ${styles.green}`} />
              {inputValues.successMessage}
            </div>
          }
        </div>
      </div>

      <div className={styles.item}>
        <textarea
          placeholder='Description (max 150 characters)'
          id={'description'}
          name='description'
          label={'Description'}
          maxLength='150'
          rows='3'
          onChange={handleInputChange}
          className={styles.inputBox}
          value={inputValues.description} />
      </div>

      <div id='searchWatchlist' className={styles.item} onBlur={outsideClickHandler} tabIndex='-1'>
        <div className={styles.searchLabel}>Search for Securities</div>
        <SearchBox onQuery={debouncedChangeHandler} placeholderText='Search by symbol, CUSIP, or description' />

        <div>
          {isTableVisible && <div className={styles.searchResults}>
            {(equitySymbolList.length > 0 || etfSymbolList.length > 0 || mfSymbolList.length > 0) ? <div>
              <div className={styles.searchResultsDiv}>
                <div className={styles.resultsHeader}>Equity</div>
                {equitySymbolList.length > 0 ? equitySymbolList.map((symbol, key) => {
                  return <div key={{ key }} className={styles.checkBoxContainer}>
                    <CheckBox label={symbol.symbol} onCheck={e => onSelect(e, CLASSIFICATION_ID.EQUITY)} isChecked={symbol.isCheck} />
                    <span><SymbolWithPopover xid={symbol.venueXid} symbol={symbol.symbol} symbolDescription={symbol.name} isFixedPos /></span>
                  </div>
                }) : <div className={styles.noResults}>{'No Results Found'}</div>}
                <div className={styles.resultsHeader}>ETF</div>
                {etfSymbolList.length > 0 ? etfSymbolList.map((symbol, key) => {
                  return <div key={{ key }} className={styles.checkBoxContainer}>
                    <CheckBox label={symbol.symbol} onCheck={e => onSelect(e, CLASSIFICATION_ID.ETF)} isChecked={symbol.isCheck} />
                    <span><SymbolWithPopover xid={symbol.venueXid} symbol={symbol.symbol} symbolDescription={symbol.name} isFixedPos /></span>
                  </div>
                }) : <div className={styles.noResults}>{'No Results Found'}</div>}
                <div className={styles.resultsHeader}>MF</div>
                {mfSymbolList.length > 0 ? mfSymbolList.map((symbol, key) => {
                  return <div key={{ key }} className={styles.checkBoxContainer}>
                    <CheckBox label={symbol.symbol} onCheck={e => onSelect(e, CLASSIFICATION_ID.MF)} isChecked={symbol.isCheck} />
                    <span><SymbolWithPopover xid={symbol.venueXid} symbol={symbol.symbol} symbolDescription={symbol.name} isFixedPos /></span>
                  </div>
                }) : <div className={styles.noResults}>{'No Results Found'}</div>}
              </div>
              <div className={`${styles['searchFooter']}`}>
                <Button tabIndex='0' onKeyDownEnter={addToWatchlist} clickHandler={addToWatchlist} className={styles.apply} labelText='Add to Watchlist' />
              </div>
            </div> : <div><Loader spinnerSize={'2x'} ready={false} /></div>}
          </div>
          }
        </div>
      </div>

      <div className={`${styles.item} ${styles.watchlistTable}`}>
        <WatchlistTable data={historyTableData} header={historyHeaderData} onTableSelect={onTableSelect} firstColFroze={isFirstColFrooze} />
      </div>

      <div className={styles.validationErrorMsg}>{inputValues.maxItemsError}</div>
      <div className={styles.bottomGroup}>
        <div className={styles.footerContainer}>
          <PrimaryButton dataTestId='Save_Watchlist' buttonText='Save' clickHandler={saveHandler} keyDownHandler={(e) => { enterKeyEvent(e, keyDownHandler) }} disabled={inputValues.name.trim().length === 0} />
        </div>
      </div>
    </div>
  )
}
WatchlistCreateBody.propTypes = {
  watchListHoldingData: PropTypes.array,
  device: PropTypes.string,
  onClose: PropTypes.func,
  watchlistData: PropTypes.object,
  symbol: PropTypes.string,
  exchange: PropTypes.string
}
export default WatchlistCreateBody
