'use strict'

import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'throttle-debounce'
import DataRequest from '../../../../services/DataRequest'
import { URLS, MARKETS_PERFORMANCE as INDEXES, COUNTRY_US } from '../../../../utils/appConstants'
import Button from '../../../Lib/Button/Button'
import CheckBox from '../../../Lib/CheckBox/CheckBox'
import SearchBox from '../../../Lib/SearchBox/SearchBox'
import NoData from '../../../Lib/NoData/NoData'
import styles from './SymbolSearch.module.scss'

const SymbolSearch = ({primarySymbol, primarySymbolId, prevsymbolList, peers, onApply, onCancel, isMobileSettings}) => {
  const [symbolList, setSymbolList] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [selectedSymbolList, setSelectedSymbolList] = useState([])
  const [count, setCount] = useState(0)
  const [defaultView, setDefaultView] = useState(true)
  const [prvSymbols, setPrvSymbols] = useState([])

  useEffect(() => {
    setPrvSymbols(prevsymbolList)
  }, [prevsymbolList])

  const handleSelection = (isChecked, selectedSymbol) => {
    if (isChecked) {
      setCount(count + 1)
      if (count + prvSymbols.length >= 9) {
        setErrorMsg('Max limit of 10 reached')
      }
      const alreadyExists = prvSymbols.find(item => item?.venueXid === selectedSymbol?.venueXid)
      if (selectedSymbol?.symbol === primarySymbol || alreadyExists) {
        setErrorMsg(`${selectedSymbol?.symbol} already selected`)
      } else {
        setSelectedSymbolList([...selectedSymbolList, selectedSymbol])
      }
    } else {
      setCount(count - 1)
      setErrorMsg('')
      if (prvSymbols.find(item => parseInt(item?.venueXid) === parseInt(selectedSymbol?.venueXid))) {
        setPrvSymbols(prvSymbols.filter(item => item?.venueXid !== parseInt(selectedSymbol?.venueXid)))
      } else {
        const removeUncheck = selectedSymbolList.filter(item => item?.symbol !== selectedSymbol?.symbol)
        setSelectedSymbolList([...removeUncheck])
      }
    }
  }

  const onChange = (e) => {
    const value = e.target.value
    const isChecked = e.target.checked
    const selectedSymbol = symbolList.find(symbol => symbol.venueXid === parseInt(value))
    if (selectedSymbol?.venueXid !== primarySymbolId) {
      handleSelection(isChecked, selectedSymbol)
    }
  }

  const onChangePeers = (e) => {
    const isChecked = e.target.checked
    const value = e.target.value
    const selectedSymbol = peers.find(peer => peer.venueXid === parseInt(value))
    if (selectedSymbol?.venueXid !== primarySymbolId) {
      handleSelection(isChecked, selectedSymbol)
    }
  }

  const onChangeIndex = (e) => {
    const name = e.target.name
    const isChecked = e.target.checked
    const value = e.target.value
    const selectedSymbol = {
      symbol: name,
      venueXid: parseInt(value)
    }
    handleSelection(isChecked, selectedSymbol)
  }
  const applySelectedSymbol = () => {
    if ((count + prvSymbols.length) <= 9) {
      onApply([...prvSymbols, ...selectedSymbolList])
    }
  }

  const searchSymbol = (query) => {
    let xrefCallback = (response) => {
      if (response.data) {
        const items = response.data && response.data.items
        if (items && items.length > 0) {
          const symbols = items.map(item => {
            return {
              symbol: item.symbol,
              name: item.name,
              venueXid: item.xids ? item.xids.venue : ''
            }
          })
          setSymbolList([...symbols])
        } else {
          setSymbolList([])
        }
        setDefaultView(false)
      }
    }
    DataRequest.execute(
      URLS.PLATFORM.XREFPREDICTIVESEARCH,
      {
        params: {
          term: query,
          classificationIds: 3, // Equity
          exchangeCountryIsos: COUNTRY_US
        }
      },
      xrefCallback,
      null
    )
  }
  const debouncedChangeHandler = debounce(500, (query) => {
    if (query) {
      searchSymbol(query)
    } else {
      setSymbolList([])
      setDefaultView(true)
    }
  })
  return (
    <div className={styles.symbolSearchContainer}>
      <div className={styles.sybmolSearchBodySection}>
        <SearchBox onQuery={debouncedChangeHandler} />
        {!defaultView
          ? <div className={styles.searchResults}>
            <div className={styles.resultsHeader}>Search Results</div>
            {symbolList.length === 0 && <NoData msg='No Results found' />}
            {symbolList.length > 0 && symbolList.map((symbol, key) => {
              return <div key={key} className={styles.checkBoxContainer}>
                <CheckBox
                  label={symbol.name}
                  onCheck={onChange}
                  value={symbol.venueXid}
                  isChecked={!![...prvSymbols, ...selectedSymbolList, {venueXid: primarySymbolId, symbol: primarySymbol}].find(item => item.venueXid === symbol.venueXid)}
                />
              </div>
            })
            }
          </div>
          : <div>
            <div className={`${styles.searchResults} ${styles.defaultList}`}>
              <div className={styles.resultsHeader}>Index</div>
              {Object.values(INDEXES.INDEXES).map((index) => {
                return <div key={index} className={styles.checkBoxContainer}>
                  <CheckBox
                    label={index.symbol}
                    value={index.wsodIssue}
                    onCheck={onChangeIndex}
                    isChecked={prvSymbols.find(item => item?.venueXid === index.wsodIssue)}
                  />
                </div>
              })
              }
            </div>
            <div className={`${styles.searchResults} ${styles.defaultList}`}>
              <div className={styles.resultsHeader}>Peers</div>
              {peers.map((peer, key) => {
                return <div key={key} className={styles.checkBoxContainer}>
                  <CheckBox
                    label={peer.symbol}
                    onCheck={onChangePeers}
                    value={peer.venueXid}
                    isChecked={!![...prvSymbols, ...selectedSymbolList].find(item => item?.venueXid === peer.venueXid)}
                  />
                </div>
              })
              }
            </div>
          </div>
        }
        {errorMsg &&
          <div className={styles.errorBox}>
            <span>{errorMsg}</span>
          </div>
        }
      </div>
      <div className={`${styles['searchFooter']} ${isMobileSettings && styles['mobileSettings']}`}>
        <Button buttonDataTestId='symbolSearchApplyButton' tabIndex='0' onKeyDownEnter={onApply} clickHandler={applySelectedSymbol} className={styles.apply} labelText='Apply' />
        <Button buttonDataTestId='symbolSearchResetButton' tabIndex='0' onKeyDownEnter={onApply} clickHandler={onCancel} className={styles.cancel} labelText='Cancel' />
      </div>
    </div>
  )
}

SymbolSearch.propTypes = {
  primarySymbol: PropTypes.string,
  primarySymbolId: PropTypes.number,
  prevsymbolList: PropTypes.array,
  peers: PropTypes.array,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  isMobileSettings: PropTypes.bool
}

export default SymbolSearch
