import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import styles from './ChartComparison.module.scss'
import SymbolSearch from '../SymbolComparison/SymbolSearch/SymbolSearch'
import ModalPopUp from '../../Lib/ModalPopUp/ModalPopUp'
import ChartsMobileHeader from '../../Lib/ChartsMobileHeader/ChartsMobileHeader'
import {getPeers} from '../../../shared/dataMethods'
import {makeXrefCall} from '../../../services/XrefAPICall'
export default function ChartComparison ({ closeChartComparisonHandler, symbol, venueXid, symbolsList }) {
  const [symbolList, setSymbolList] = useState([])
  const [peers, setPeers] = useState([])

  const onApply = (selectedSymbols) => {
    const newSymbolList = [...symbolList, ...selectedSymbols]
    setSymbolList(newSymbolList)
    closeChartComparisonHandler(newSymbolList)
  }

  const mapPeersList = (list) => {
    const xrefResponse = list.data && list.data.items
    const mappedList = xrefResponse.map(item => {
      return {
        symbol: item.symbol,
        venueXid: item.xids.venue
      }
    })
    setPeers([...mappedList])
  }

  useEffect(() => {
    getPeers(venueXid, (data) => {
      const venueXidList = data && data.data && data.data.map(e => e.venueXid)
      makeXrefCall(null, mapPeersList, true, venueXidList)
    })
  }, [venueXid])

  return (<ModalPopUp customHeader customBody>
    <ChartsMobileHeader headerText='Comparison' closeHandler={() => { closeChartComparisonHandler('') }} headerDataTestId='chartComparisonloseTestId' />
    <div className={styles.chartComparisonContainer}>
      <div className={styles.divider} />
      <div className={styles.mainContent}>
        <SymbolSearch primarySymbol={symbol} prevsymbolList={symbolsList} peers={peers} isMobileSettings onApply={onApply} onCancel={() => { closeChartComparisonHandler('') }} />
      </div>
    </div>
  </ModalPopUp>)
}

ChartComparison.propTypes = {
  closeChartComparisonHandler: PropTypes.func,
  symbol: PropTypes.string,
  venueXid: PropTypes.number,
  symbolsList: PropTypes.array
}
