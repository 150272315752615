/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { URLS, DEFAULT_NEWS_INPUTS } from '../../../utils/appConstants'
import { addArgument, closeModalPopup, setSavedNewsPreferencesData } from '../../../utils/utilities'
import DataRequest from '../../../services/DataRequest'
import NewsForYouSection from './NewsForYouSection'
import Loader from '../../Lib/common/Loader'
import { GetFieldNameFromCategory } from '../../Lib/common/CommonMethods'
import NewsPreferencesModal from './NewsPreferencesModal/NewsPreferencesModal'
import { registerAccessTokenUpdatedF2OnEvent } from '../../../utils/f2Methods'
import NewsForYouEmpty from './NewsForYouEmpty'
import { saveNewsPreference } from '../../../shared/dataMethods'

function NewsForYou () {
  const [showNewsPreferenceModal, setShowNewsPreferenceModal] = useState(false)
  const [newsListData, setNewsListData] = useState([])
  const [savedPreferences, setSavedPreferences] = useState([])
  const [isReady, setIsReady] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)
  const [hideNewsList, setHideNewsList] = useState(false)
  const [firstTimeView, setFirstTimeView] = useState(false)

  useEffect(() => {
    getNewsPreferences()
    registerAccessTokenUpdatedF2OnEvent(() => {
      getNewsPreferences()
    })
  }, [])

  const setInitialState = () => {
    setIsEditModal(false)
    setIsReady(true)
    setHideNewsList(false)
    setFirstTimeView(false)
    setNewsListData([])
  }

  const setFirstTimeModalState = () => {
    setIsReady(true)
    setFirstTimeView(true)
    setHideNewsList(true)
    setIsEditModal(false)
  }

  const applyPreferences = (selectedPreferences) => {
    setIsReady(false)
    setShowNewsPreferenceModal(false)
    saveNewsPreference(selectedPreferences, getNewsPreferences, isEditModal)
  }

  const getNewsPreferences = (isEdit) => {
    const callback = (response) => {
      if (!response.isDataRequestComplete) {
        return
      }
      if (!response.isError && response.data) {
        const isFirstTimeModal = response.data.length === 0
        setSavedNewsPreferencesData(response.data, setSavedPreferences)
        if (isEdit) {
          setShowNewsPreferenceModal(true)
          return
        }
        if (response.data.length) {
          getNewsList(response.data.filter(x => x.isSelected))
        } else if (isFirstTimeModal) {
          setFirstTimeModalState()
        } else {
          setInitialState()
        }
      } else {
        setInitialState()
      }
    }

    DataRequest.execute(
      `${URLS.CUSTOM.GET_VIEWED_NEWS_CATEGORY}`,
      {
        method: 'GET'
      },
      callback,
      null
    )
  }

  const getNews = (viewedNewsListArr) => {
    return new Promise((resolve, reject) => {
      let callback = (response) => {
        if (!response.isError && response.data) {
          let responseData = response && response.data && response.data.news
          return resolve(responseData)
        }
        return reject(response.error)
      }
      DataRequest.execute(URLS.CUSTOM.NEWS, buildPostNewsInputs(viewedNewsListArr), callback, null)
    })
  }

  const getNewsList = (newData) => {
    const viewedNewsListArr = []
    for (const element of newData) {
      const category = element.category
      viewedNewsListArr.push(addArgument(GetFieldNameFromCategory(category), [category]))
    }
    getNews(viewedNewsListArr).then((data) => {
      let newsData = []
      if (data) {
        data.forEach((item, idx) => {
          if (item != null) {
            newsData.push(item)
          }
        })
        setNewsListData(newsData)
        setIsReady(true)
        setHideNewsList(false)
        setFirstTimeView(false)
      }
    }).catch(() => {
      setInitialState()
    })
  }

  const buildPostNewsInputs = (viewedNewsListArr) => {
    let newsInputs = {...DEFAULT_NEWS_INPUTS}
    newsInputs.argsOperator = 'OR'
    newsInputs.arguments = [...viewedNewsListArr]
    newsInputs.limit = 3
    return {
      method: 'POST',
      postData: newsInputs
    }
  }

  const openEditPreferencesModal = () => {
    getNewsPreferences(true)
    setIsEditModal(true)
  }

  const onClosePopup = (e) => {
    if (closeModalPopup(e)) {
      setShowNewsPreferenceModal(false)
    }
  }

  return (<Loader ready={isReady} spinnerSize='2x' >
    {showNewsPreferenceModal && <NewsPreferencesModal
      applyPreferences={applyPreferences}
      savedPreferences={savedPreferences}
      isEdit={isEditModal}
      onClose={onClosePopup} />}
    {firstTimeView ? <NewsForYouEmpty openSelectPreferencesModal={openEditPreferencesModal} />: !hideNewsList && (<NewsForYouSection data={newsListData} openEditPreferencesModal={openEditPreferencesModal} />)}
  </Loader>
  )
}

export default NewsForYou
