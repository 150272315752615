import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./NonDollarBaseballCard.module.scss";
import {
  URLS,
  ChartTimeFrames,
  INTERNATIONAL_CURRENCY_TEXT
} from "../../../utils/appConstants";
import DataRequest from "../../../services/DataRequest";
import { getQuoteData } from "../../../shared/dataMethods";
import Loader from "../../Lib/common/Loader";
import PerformanceChart from "../../News/QuoteCard/PerformanceChart/PerformanceChart";
import TableRow from "./TableRow";
import { formatNumber } from "../../../utils/formatter";

function NonDollarBaseballCard ({ venueXid, spinnerStyle, isSearch = false }) {
  const [isReady, setIsReady] = useState(true);
  const [xrefData, setXrefData] = useState({});
  const [quoteData, setQuoteData] = useState({});
  const [flag, setFlag] = useState();

  let keyStyling = {
    color: isSearch ? '#000000' : '#666666'
  }

  let spinnerStyleVal = spinnerStyle || {}

  useEffect(() => {
    getXrefResponse(venueXid);
  }, [venueXid]);

  function quoteCallback (response) {
    if (!response.isError) {
      const responseData = response?.data?.quotes?.[0]?.data || {};
      setQuoteData({
        isoCode: responseData?.currency?.isoCode || "--",
        last: formatNumber(responseData?.lastTrade?.last),
        change: formatNumber(responseData?.lastTrade?.change),
        changePercent: formatNumber(responseData?.changePercent?.today, {postFix: true}),
        exchangeName: responseData?.exchange?.name || "--",
        volume: formatNumber(responseData?.volume?.last),
        open: formatNumber(responseData?.lastTrade?.open),
        close: formatNumber(responseData?.lastTrade?.close),
        bidSize: `${formatNumber(responseData?.bid?.price)} (${
          formatNumber(responseData?.bid?.size, {precision: 0}) || "--"
        })`,
        askSize: `${formatNumber(responseData?.ask?.price)} (${
          formatNumber(responseData?.ask?.size, {precision: 0}) || "--"
        })`,
        high: formatNumber(responseData?.lastTrade?.high),
        low: formatNumber(responseData?.lastTrade?.low),
        currency: responseData?.currency?.name || "--"
      });
      setIsReady(false);
    } else {
      setQuoteData({
        isoCode: "--",
        last: "--",
        change: "--",
        changePercent: "--",
        exchangeName: "--",
        volume: "--",
        open: "--",
        close: "--",
        bidSize: `-- (--)`,
        askSize: `-- (--)`,
        high: "--",
        low: "--",
        currency: "--"
      });
      setIsReady(false);
    }
  }

  function getXrefResponse (venueXid) {
    let callback = (response) => {
      if (!response.isError) {
        if (response?.data) {
          getQuoteData([venueXid], quoteCallback);
          getCountryFlag(response?.data?.exchange?.countryIso);
          setXrefData({
            symbol: response.data?.symbol || "--",
            name: response.data?.name || "--",
            exchange: response.data?.exchange?.code || "--",
            venueXid: response.data?.xids?.venue || "--"
          });
        }
      } else {
        setXrefData({
          symbol: "--",
          name: "--",
          exchange: "--",
          venueXid: "--"
        });
      }
    };
    setIsReady(true);
    DataRequest.execute(`${URLS.PLATFORM.XREF}/${venueXid}`, null, callback);
  }

  const getCountryFlag = (isoCode) => {
    let callback = (response) => {
      setFlag(response?.data);
    };

    DataRequest.execute(
      `${URLS.PLATFORM.COUNTRY_FLAG}`,
      {
        params: {
          isoCode
        }
      },
      callback,
      null
    );
  };

  function getColorCssClass (conditionVal) {
    if (isNaN(conditionVal)) {
      conditionVal = parseInt(conditionVal?.replace(/[,]/g, ""));
    }
    if (conditionVal > 0 || (conditionVal && conditionVal[0] === "+")) {
      return styles.green;
    } else if (conditionVal < 0 || (conditionVal && conditionVal[0] === "-")) {
      return styles.red;
    } else {
      return styles.black;
    }
  }

  return (
    <Loader ready={!isReady} spinnerSize="2x" spinnerStyle={spinnerStyleVal}>
      <div className={` ${styles.nonDollarCardContainer} ${isSearch ? styles.searchCardWidth : ''}`}>
        <div className={` ${styles.nonDollarCard} ${isSearch ? styles.cardSearchShadow : ''}`}>
          {isSearch
          ? (<div className={styles.title}>Highlights</div>)
          : (<div className={styles.quoteContainer}>
            <div className={styles.quoteSymbol}>{xrefData.symbol}</div>
            <div className={styles.currencyUpdate}>
              <div className={styles.nameCurrency}>
                <div className={styles.symbolName} title={xrefData.name}>{xrefData.name}</div>
                <div className={styles.divider}>|</div>
                <img
                  className={styles.flag}
                  src={flag?.data}
                  alt={`${quoteData.isoCode} Country flag`}
                />
                <div className={styles.symbolCurrency}>{quoteData.isoCode}</div>
              </div>
              <div className={styles.marketExchange}>
                <div className={styles.label}>Market:</div>
                <div className={styles.symbolExchange}>{xrefData.exchange}</div>
              </div>
            </div>
            <div className={styles.nonDollarChart}>
              <PerformanceChart
                symbol={xrefData.venueXid}
                days={ChartTimeFrames.Week}
              />
            </div>
            <div className={styles.priceChange}>
              <div className={styles.symbolLast}>{quoteData.last}</div>
              <div className={getColorCssClass(quoteData.change)}>
                {`${quoteData.change} (${quoteData.changePercent})`}
              </div>
            </div>
          </div>)
          }
          <div className={`${isSearch ? styles.searchHighlightsTable : styles.highlightsTable}`}>
            <TableRow label={"Last Sale Exchange"} value={quoteData.exchangeName} keyStyling={keyStyling}/>
            <TableRow label={"Last Volume"} value={quoteData.volume} keyStyling={keyStyling}/>
            <TableRow label={"Open"} value={quoteData.open} keyStyling={keyStyling}/>
            <TableRow label={"Close"} value={quoteData.close} keyStyling={keyStyling}/>
            <TableRow label={"Bid & Size"} value={quoteData.bidSize} keyStyling={keyStyling}/>
            <TableRow label={"Ask & Size"} value={quoteData.askSize} keyStyling={keyStyling}/>
            <TableRow label={isSearch ? "Day High": "High"} value={quoteData.high} keyStyling={keyStyling}/>
            <TableRow label={isSearch ? "Day Low": "Low"} value={quoteData.low} keyStyling={keyStyling}/>
            </div>
        </div>
        <div className={` ${styles.footerLabel} ${isSearch ? styles.footerLabelSearch : ''}`}>
          Quotes displayed are 15 mins delayed.
          <br />
          Full quote not available for international securities.
        </div>
        <div
          className={` ${styles.footerLabel} ${isSearch ? styles.footerLabelSearch : ''}`}
        >{`${INTERNATIONAL_CURRENCY_TEXT}${quoteData.currency}(${quoteData.isoCode})`}</div>
      </div>
    </Loader>
  );
}

export default NonDollarBaseballCard;
NonDollarBaseballCard.propTypes = {
  venueXid: PropTypes.number,
  isSearch: PropTypes.bool,
  spinnerStyle: PropTypes.object
};
