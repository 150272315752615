'use strict'

import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import SymbolCard from './SymbolCard/SymbolCard'
import LinkButton from '../../Lib/LinkButton/LinkButton'
import FilterTag from '../../Lib/FilterTag/FilterTag'
import SymbolSearch from './SymbolSearch/SymbolSearch'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {CHARTCOLORS} from '../../../utils/chartConstants'
import {getPeers} from '../../../shared/dataMethods'
import {makeXrefCall} from '../../../services/XrefAPICall'
import styles from './SymbolComparison.module.scss'

export default function SymbolComparison ({symbol, venueXid, indicators, removeUpperIndicator, addSymbolComparison, xrefSymbolList, showAddComparison}) {
  const [toggle, setToggle] = useState(false)
  const [symbolList, setSymbolList] = useState([])
  const [peers, setPeers] = useState([])
  const searchContainerRef = useRef(null)

  const INDEXMAP = {
    '566677': 'NYSE',
    '579435': 'NASDAQ',
    '583358': 'AMEX'
  }

  const mapPeersList = (list) => {
    const xrefResponse = list.data && list.data.items
    const mappedList = xrefResponse.map(item => {
      return {
        symbol: item.symbol,
        venueXid: item.xids.venue
      }
    })
    setPeers([...mappedList])
  }

  useEffect(() => {
    getPeers(venueXid, (data) => {
      const venueXidList = data && data.data && data.data.map(e => e.venueXid)
      makeXrefCall(null, mapPeersList, true, venueXidList)
    })
  }, [venueXid])

  useEffect(() => {
    const priceIndicator = indicators.filter(i => i.params.id === 'price')
    const tempSymbol = []
    priceIndicator.forEach(element => {
      tempSymbol.push({
        venueXid: parseInt(element.params.symbol),
        symbol: xrefSymbolList[element.params.symbol] || ''
      })
    })
    setSymbolList([...tempSymbol])
  }, [indicators, xrefSymbolList])

  useEffect(() => {
    function handleClickOutside (event) {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
        setToggle(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [searchContainerRef])

  const onApply = (selectedSymbols) => {
    let prvSymbolList = symbolList
    let newSymbols = [...new Set([...selectedSymbols])]
    let removeSymbols = []
    let commonSymbols = []

    setToggle(!toggle)
    symbolList.forEach(function (symbol, index) {
      if (!newSymbols.includes(symbol)) {
        removeSymbols.push(symbol)
      } else {
        newSymbols = newSymbols.filter(x => x.venueXid !== symbol.venueXid)
        commonSymbols.push(symbol)
      }
    })
    // Remove each symbol from symbol comparison
    if (removeSymbols.length > 0) {
      removeSymbols.forEach((symbol, index) => {
        prvSymbolList = prvSymbolList.filter(item => item.venueXid !== symbol.venueXid)
        addSymbolComparison(prvSymbolList)
      })
    }
    // Add symbols to symbol comparision
    if (newSymbols.length > 0) {
      addSymbolComparison([...commonSymbols, ...newSymbols])
    }
  }

  function getSymbolName (xid) {
    // get Index name first
    if (INDEXMAP[xid]) {
      return INDEXMAP[xid]
    }
    let filterSymbol = symbolList.find(item => parseInt(item.venueXid) === parseInt(xid))
    if (filterSymbol) {
      return filterSymbol.symbol
    }
    return ''
  }

  const removeSymbol = (symbolRemove) => {
    addSymbolComparison(symbolList.filter(x => x.venueXid !== symbolRemove.venueXid))
  }

  function buildLegendLabel (params) {
    let config = ''
    params.inputs.forEach(input => {
      config += `${input.value},`
    })
    config = config.substring(0, config.length - 1)
    config = config ? ` (${config})` : ''
    return `${params.name}${config}`
  }

  return (
    <div className={styles.symbolComparisonConatiner}>
      <SymbolCard symbol={symbol} venueXid={venueXid} color={CHARTCOLORS[0]} />
      {indicators.map(({params}) => {
        if (params.id === 'price') {
          if (parseInt(params.symbol) !== venueXid) {
            return <SymbolCard key={params.uid} symbol={getSymbolName(params.symbol)} venueXid={parseInt(params.symbol)} color={params.style.color} onClose={() => removeSymbol({symbol: getSymbolName(params.symbol), venueXid: parseInt(params.symbol)})} />
          }
        } else {
          return <FilterTag key={params.uid} label={buildLegendLabel(params)} onClose={() => removeUpperIndicator(params.uid)} />
        }
      })}
      { !toggle && showAddComparison && <LinkButton dataTestId='test' label={<span><FontAwesomeIcon icon={['far', 'plus']} /> Comparison</span>} onClick={() => { setToggle(!toggle) }} />}
      { toggle &&
      <div ref={searchContainerRef} className={styles.symbolComparisonpopoverContainer}>
        <div className={styles.symbolComparisonpopoverContent}>
          <SymbolSearch primarySymbol={symbol} primarySymbolId={venueXid} prevsymbolList={symbolList} peers={peers} onApply={onApply} onCancel={() => { setToggle(!toggle) }} />
        </div>
      </div>
      }
    </div>
  )
}

SymbolComparison.propTypes = {
  symbol: PropTypes.string,
  venueXid: PropTypes.number,
  indicators: PropTypes.array,
  removeUpperIndicator: PropTypes.func,
  addSymbolComparison: PropTypes.func,
  xrefSymbolList: PropTypes.object,
  showAddComparison: PropTypes.bool
}
