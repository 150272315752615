import React, { useState, useEffect } from 'react'
import PerformanceChart from './PerformanceChart'
import styles from './Performance.module.scss'
import { STOCKS_PERFORMANCE, URLS, CHART_STYLES } from '../../../utils/appConstants'
import DataRequest from '../../../services/DataRequest'
import { handleWindowResize, getDeviceType, deviceType, getSymbolToDisplay } from '../../../utils/utilities'
import { registerF2OnEvent, registerAccessTokenUpdatedF2OnEvent } from '../../../utils/f2Methods'
import {EVENT_NAMES} from '../../../utils/f2Constants'
import BasicDropDown from '../../../components/Lib/Dropdown/BasicDropDown'
import ChartType from '../../Charts/ChartType/ChartType'
import PropTypes from 'prop-types'
import Loader from '../../Lib/common/Loader'
import DataUnavailable from '../../Lib/DataUnavailable/DataUnavailable'

const Performance = (props) => {
  const [device, setDevice] = useState(getDeviceType())
  const [intervalMapping] = useState(STOCKS_PERFORMANCE.intervalMapping)
  const [periodMapping] = useState(STOCKS_PERFORMANCE.periodMapping)
  const [selectedChartType, setSelectedChartType] = useState(STOCKS_PERFORMANCE.chartTypes.Fill)
  const [dropDownDataDays] = useState(Object.keys(STOCKS_PERFORMANCE.dropDownDaysValues))
  const [selectedDays, setSelectedDays] = useState(STOCKS_PERFORMANCE.dropDownDaysValues['1 Day'])
  const [symbol, setSymbol] = useState(props.symbol)
  const [wsodIssue, setWsodIssue] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isData, setIsData] = useState(false)
  useEffect(() => {
    handleWindowResize((response) => {
      setDevice(response)
    })
    registerAccessTokenUpdatedF2OnEvent(() => {
      getVenueXidFromXref()
    })
    registerF2OnEvent(EVENT_NAMES.SYMBOL_CHANGED_EVENT, (symbolUpdatedObject) => {
      setSymbol(symbolUpdatedObject.symbol)
    })
  }, [])
  useEffect(() => {
    getVenueXidFromXref()
  }, [symbol])

  const getVenueXidFromXref = () => {
    if (symbol) {
      setIsData(true)
      setIsLoading(true)
      let callback = (response) => {
        if (!response.isDataRequestComplete) return
        if (!response.isError) {
          const responseData = response.data?.xids
          if (responseData.venue) {
            setWsodIssue(responseData.venue)
            setSelectedDays(STOCKS_PERFORMANCE.dropDownDaysValues['1 Day'])
          } else {
            setWsodIssue(null)
          }
        } else {
          setIsData(false)
          // error
        }
        setIsLoading(false)
        setIsData(true)
      }

      DataRequest.execute(
        `${URLS.PLATFORM.XREFGETSYMBOL}/` + symbol,
        {
          params: {
            isCaseSensitive: false
          }
        },
        callback,
        null
      )
    }
  }
  const callChart = e => {
    if (!e) return
    e.preventDefault()
    setSelectedDays(STOCKS_PERFORMANCE.TIME_PERIOD[e.target.innerText])
  }
  const dropDownChangeHandler = (e) => {
    const value = e.getAttribute('data-value')
    const item = CHART_STYLES.filter(item => {
      if (item.Text === value) {
        return item
      }
    })
    setSelectedChartType((item?.[0]?.Icon) || 'lineWithShapes')
  }
  const dropDownDaysChangeHandler = (e) => {
    setSelectedDays(STOCKS_PERFORMANCE.dropDownDaysValues[e.getAttribute('data-value')])
  }
  const getDesktopOrIpadSelection = () => (
    <div className={styles.itemsContainer} key={device}>
      {getSelectionButtons()}
      <ChartType chartTypeSelValue={selectedChartType} onSelect={dropDownChangeHandler} />
    </div>
  );

  const getMobileSelection = () => (
    <div className={styles.itemsContainer} key={device}>
      <div className={styles['row1FirstDD']}>
        <BasicDropDown selectedValue={'1 Day'} data={dropDownDataDays} changeHandler={dropDownDaysChangeHandler} />
      </div>
      <div className={styles['row1SecondDD']}>
        <ChartType chartTypeSelValue={selectedChartType} onSelect={dropDownChangeHandler} />
      </div>
    </div>
  );

  const getSelectionButtons = () => {
    const timePeriods = [
      { days: 1, label: '1D' },
      { days: 5, label: '5D' },
      { days: 30, label: '1M' },
      { days: 90, label: '3M' },
      { days: 365, label: '1Y' },
      { days: 1825, label: '5Y' },
      { days: 3650, label: '10Y' },
      { days: 10000, label: 'Max' }
    ];

    return timePeriods.map(({ days, label }) => (
      <div key={days} className={`${selectedDays === days ? styles['selectedItem'] : ''} ${styles['item']}`}>
        <button className={styles.button} onClick={callChart} onKeyDown={callChart}>
          {label}
        </button>
      </div>
    ));
  };

  function getChartSelection () {
    let bodyHtml = device === deviceType.Desktop || device === deviceType.Ipad
      ? getDesktopOrIpadSelection()
      : getMobileSelection();
    return [bodyHtml];
  }

  const getChartInputs = () => {
    const dataInterval = intervalMapping[selectedDays]
    const dataPeriod = periodMapping[selectedDays]
    return {
      'timePeriod': selectedDays,
      dataInterval,
      dataPeriod,
      wsodIssue,
      'chartType': selectedChartType
    }
  }
  const chartInputs = getChartInputs()
  return (
    <div className={styles.performanceContainer}>
      <span className={styles.TopText}>{getSymbolToDisplay(symbol)}Performance
      </span>
      <div className={styles.chartSelections}>
        {getChartSelection()}
      </div>
      {
        !isData &&
        <div>
          <DataUnavailable />
        </div>
      }
      {isLoading && isData &&
      <div className={styles.loaderContainer}>
        <Loader ready={!isLoading}
          spinnerSize={'2x'}
          msg={'Loading'} />
      </div>
      }
      <div className={styles.chart}>
        {!isLoading && wsodIssue && <PerformanceChart chartInputs={chartInputs} />}
      </div>
    </div>
  )
}
Performance.propTypes = {
  symbol: PropTypes.string
}
export default Performance
