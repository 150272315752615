import React from 'react'
import PropTypes from 'prop-types'
import styles from './Options.module.scss'

export default function Options ({handler}) {
  return (
    <ul className={styles.saveChartOptionsContainer}>
      <li className={styles.saveChartOptionItem}><button data-testid={'test'} onClick={() => { handler('Delete') }} onKeyDown={() => { handler('Delete') }}>Delete</button></li>
      <li className={styles.saveChartOptionItem}><button onClick={() => { handler('Duplicate') }} onKeyDown={() => { handler('Duplicate') }}>Duplicate</button></li>
      <li className={styles.saveChartOptionItem}><button onClick={() => { handler('Rename') }} onKeyDown={() => { handler('Rename') }}>Rename</button></li>
      <li className={styles.saveChartOptionItem}><button onClick={() => { handler('Default') }} onKeyDown={() => { handler('Default') }}>Set as Default</button></li>
    </ul>)
}
Options.propTypes = {
  handler: PropTypes.func
}
