import React from 'react'
import PropTypes from 'prop-types'
import styles from './SplitEventInfo.module.scss'

export default function SplitEventInfo ({splitEventInfoData}) {
  return (
    <div className={styles.chartEventContainer}>
      <div className={styles.eventHeader}>
        <span className={styles.eventName}>Split Announcement</span>
        <span className={styles.eventDate}>{splitEventInfoData.announcementDate}</span>
      </div>
      <div className={styles.eventDescription}>
        <div className={styles.eventCol1}>Ratio</div>
        <div className={styles.eventCol2}>{splitEventInfoData.ratio}</div>
      </div>
      <div className={styles.eventDescription}>
        <div className={styles.eventCol1}>Split Date</div>
        <div className={styles.eventCol2}>{splitEventInfoData.date}</div>
      </div>
    </div>
  )
}

SplitEventInfo.propTypes = {
  splitEventInfoData: PropTypes.object
}
