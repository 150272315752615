import React, {useState, useEffect} from 'react'
import { useXref } from '../../hooks/useXref'
import PropTypes from 'prop-types'
import styles from './Charts.module.scss'
import Loader from '../Lib/common/Loader'
import NoData from '../Lib/NoData/NoData'
import Charts from './Charts'
import { registerF2OnEvent, registerAccessTokenUpdatedF2OnEvent } from '../../utils/f2Methods'
import { EVENT_NAMES } from '../../utils/f2Constants'
import { getDeviceType, deviceType, handleWindowResize } from '../../utils/utilities'

export default function ChartsContainer (props) {
  const [device, setDevice] = useState(getDeviceType())
  const [symbol, setSymbol] = useState(props.symbol)
  let xref = useXref(symbol)

  function resizeHandler (newdevice) {
    setDevice(newdevice)
  }

  useEffect(() => {
    handleWindowResize(resizeHandler)
  }, [device])

  useEffect(() => {
    registerF2OnEvent(EVENT_NAMES.SYMBOL_CHANGED_EVENT, (symbolChangedObject) => {
      setSymbol(symbolChangedObject.symbol)
      xref.updateSymbol(symbolChangedObject.symbol)
    })
    registerAccessTokenUpdatedF2OnEvent(() => {
      xref.callData(symbol)
    })
  }, [])

  const renderChart = () => {
    if (xref.isLoading) {
      return <Loader spinnerSize={'2x'} msg={'Loading'} />
    }
    if (xref.isError) {
      return <NoData msg={`${symbol ? 'Chart not available for' : 'Chart not available'} ${symbol}`} />
    } else if (xref.venueXid) {
      return <Charts
        xid={xref.venueXid}
        symbol={symbol}
        showTabs={deviceType.Mobile !== device}
        showAddComparison={deviceType.Mobile !== device}
        showChartSettingIcon={deviceType.Mobile === device}
        showVolumeChart
        chartUpperPanelHeight={350}
        topBorder
        enableChartPeriodChangeEvent
        timeFrameInDays={1}
      />
    }
  }
  return (
    <div className={styles.chartsContainer}>
      {
        renderChart()
      }
    </div>
  )
}

ChartsContainer.propTypes = {
  symbol: PropTypes.string.isRequired
}
